import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box, Typography, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { collection, getDocs, getDoc, doc, query } from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';
import UpdateTeamModal from './UpdateTeamModal';

interface GuildMember {
    uid: string;
    username: string;
    inGameName: string;
    dkp: number;
    role: string;
    gearScore: number;
    primaryWeapon: string;
    secondaryWeapon: string;
    classTypes: ('Healer' | 'Tank' | 'DPS')[];
}

interface Team {
    name: string;
    members: string[];
}

interface MemberSearchProps {
    guildMembers: GuildMember[];
    teams: Team[];
    onAddMember: (teamName: string, member: GuildMember) => void;
}

const MemberSearch: React.FC<MemberSearchProps> = ({ guildMembers, teams, onAddMember }) => {
    const [selectedMember, setSelectedMember] = useState<GuildMember | null>(null);
    const [filterClass, setFilterClass] = useState<'All' | 'Healer' | 'Tank' | 'DPS'>('All');
    const [filterPrimaryWeapon, setFilterPrimaryWeapon] = useState<string>('All');
    const [filterSecondaryWeapon, setFilterSecondaryWeapon] = useState<string>('All');
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
    const { selectedGuild } = useUser();

    const weaponOptions = Array.from(new Set(guildMembers.flatMap(member => [member.primaryWeapon, member.secondaryWeapon])));

    const filteredMembers = guildMembers.filter(member => {
        const classMatch = filterClass === 'All' || member.classTypes.includes(filterClass);
        const primaryWeaponMatch = filterPrimaryWeapon === 'All' || member.primaryWeapon === filterPrimaryWeapon;
        const secondaryWeaponMatch = filterSecondaryWeapon === 'All' || member.secondaryWeapon === filterSecondaryWeapon;
        return classMatch && primaryWeaponMatch && secondaryWeaponMatch;
    });

    const handleAddMember = (teamName: string, member: GuildMember) => {
        setSelectedMember(member);
        setSelectedTeam(teamName);
        setShowUpdateModal(true);
    };

    const handleModalProceed = () => {
        setShowUpdateModal(false);
        if (selectedTeam && selectedMember) {
            onAddMember(selectedTeam, selectedMember);
            setSelectedMember(null);
            setSelectedTeam(null);
        }
    };

    return (
        <>
            <Box>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <InputLabel>Filter by Class</InputLabel>
                    <Select
                        value={filterClass}
                        onChange={(e) => setFilterClass(e.target.value as 'All' | 'Healer' | 'Tank' | 'DPS')}
                    >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Healer">Healer</MenuItem>
                        <MenuItem value="Tank">Tank</MenuItem>
                        <MenuItem value="DPS">DPS</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <InputLabel>Filter by Primary Weapon</InputLabel>
                    <Select
                        value={filterPrimaryWeapon}
                        onChange={(e) => setFilterPrimaryWeapon(e.target.value)}
                    >
                        <MenuItem value="All">All</MenuItem>
                        {weaponOptions.map((weapon, index) => (
                            <MenuItem key={index} value={weapon}>{weapon}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <InputLabel>Filter by Secondary Weapon</InputLabel>
                    <Select
                        value={filterSecondaryWeapon}
                        onChange={(e) => setFilterSecondaryWeapon(e.target.value)}
                    >
                        <MenuItem value="All">All</MenuItem>
                        {weaponOptions.map((weapon, index) => (
                            <MenuItem key={index} value={weapon}>{weapon}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Autocomplete
                    options={filteredMembers}
                    getOptionLabel={(option) => option.inGameName}
                    onChange={(event, newValue) => setSelectedMember(newValue)}
                    renderInput={(params) => <TextField {...params} label="Search Members" />}
                    renderOption={(props, option) => (
                        <Box
                            component="li"
                            {...props}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                backgroundColor: 'background.paper',
                                borderRadius: 1,
                                padding: 1,
                                boxShadow: 1,
                                '&:hover': {
                                    backgroundColor: 'action.hover',
                                },
                            }}
                        >
                            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                                {option.inGameName}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                DKP: {option.dkp}, Role: {option.role}, Gear Score: {option.gearScore}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Weapons: {option.primaryWeapon} / {option.secondaryWeapon}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                {teams.map((team) => (
                                    <Button
                                        key={team.name}
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleAddMember(team.name, option)}
                                    >
                                        Add to {team.name}
                                    </Button>
                                ))}
                            </Box>
                        </Box>
                    )}
                    sx={{
                        marginBottom: 2,
                        '& .MuiAutocomplete-inputRoot': {
                            borderRadius: 2,
                            backgroundColor: 'background.paper',
                            boxShadow: 1,
                        },
                        '& .MuiAutocomplete-popupIndicator': {
                            color: 'primary.main',
                        },
                    }}
                />
            </Box>
            <UpdateTeamModal
                open={showUpdateModal}
                onClose={() => setShowUpdateModal(false)}
                onProceed={handleModalProceed}
                selectedGuild={selectedGuild || ''}
            />
        </>
    );
};

export default MemberSearch;
