// src/components/Wishlist/WishListServices.tsx

import {
  getDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
  writeBatch,
  increment,
  serverTimestamp,
  updateDoc,
  runTransaction,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Timestamp } from "firebase/firestore";
import { ItemType, WishlistItem } from "../../types/Wishlist";

export const WEEKLY_WISHLIST_TOKENS = 1;
const ONE_WEEK_MS = 7 * 24 * 60 * 60 * 1000;

export const checkWishlistTokens = async (
  userId: string,
  guildId: string
): Promise<number> => {
  const userGuildRef = doc(db, "userGuilds", `${userId}_${guildId}`);
  const userGuildDoc = await getDoc(userGuildRef);

  if (!userGuildDoc.exists()) {
    return 0;
  }

  return userGuildDoc.data()?.wishlistTokens || 0;
};

export const checkTokenReset = async (
  userId: string,
  guildId: string
): Promise<boolean> => {
  const userGuildRef = doc(db, "userGuilds", `${userId}_${guildId}`);
  const userGuildDoc = await getDoc(userGuildRef);

  if (!userGuildDoc.exists()) return false;

  const lastReset = userGuildDoc.data()?.lastTokenReset?.toDate();
  if (!lastReset) return true;

  return Date.now() - lastReset.getTime() >= ONE_WEEK_MS;
};

export const initializeWishlistTokens = async (
  userId: string,
  guildId: string,
  tokenAmount: number = WEEKLY_WISHLIST_TOKENS
) => {
  const userGuildRef = doc(db, "userGuilds", `${userId}_${guildId}`);
  await updateDoc(userGuildRef, {
    wishlistTokens: tokenAmount,
    lastTokenReset: serverTimestamp(),
  });
};

export const consumeWishlistToken = async (
  userId: string,
  guildId: string
) => {
  const userGuildRef = doc(db, "userGuilds", `${userId}_${guildId}`);
  const tokens = await checkWishlistTokens(userId, guildId);

  if (tokens <= 0) {
    throw new Error("No wishlist tokens remaining");
  }

  await updateDoc(userGuildRef, {
    wishlistTokens: increment(-1),
  });
};

export const verifyWishlistStatus = async (
  userId: string,
  itemName: string,
  session: any,
  wishlistVerification?: {
    method: "none" | "session" | "custom";
    hours?: number;
  },
  checkTokens: boolean = false
): Promise<{ isEligible: boolean; message?: string }> => {
  const guildId = session?.guild;

  if (!guildId) {
    return {
      isEligible: false,
      message: "Guild ID is missing from session, unable to verify wishlist.",
    };
  }

  const userGuildRef = doc(db, "userGuilds", `${userId}_${guildId}`);
  const userGuildDoc = await getDoc(userGuildRef);

  if (!userGuildDoc.exists()) {
    return {
      isEligible: false,
      message: "You do not have a wishlist for this guild.",
    };
  }

  const userData = userGuildDoc.data();

  // Only check tokens if checkTokens is true
  if (checkTokens && userData.wishlistTokens <= 0) {
    return {
      isEligible: false,
      message: "No wishlist tokens remaining this week.",
    };
  }

  // Only consume token if checkTokens is true and we have tokens
  if (checkTokens && userData.wishlistTokens > 0) {
    await consumeWishlistToken(userId, guildId);
  }

  const wishlist = userData.wishlist || [];

  const wishlistItem = wishlist.find(
    (item: WishlistItem) => item.itemName === itemName
  );

  if (!wishlistItem) {
    console.log("Item not found in wishlist");
    return {
      isEligible: false,
      message: "This item is not in your wishlist.",
    };
  }

  const wishlistTimestamp = wishlistItem.addedAt?.toDate();

  if (!wishlistTimestamp) {
    console.log("No timestamp found for wishlist item");
    if (wishlistVerification?.method === "none") {
      return { isEligible: true };
    } else {
      return {
        isEligible: false,
        message: "Timestamp for wishlist item is missing, unable to verify eligibility.",
      };
    }
  }

  let isValid = false;
  let message = "Eligibility verified based on wishlist settings.";

  switch (wishlistVerification?.method) {
    case "session":
      const sessionStartTime = session?.createdAt?.toDate();
      if (sessionStartTime) {
        isValid = wishlistTimestamp < sessionStartTime;
        if (!isValid) {
          message = "Only wishlisted items added before the session start are eligible.";
        }
      } else {
        message = "Session start time is missing, unable to verify wishlist eligibility.";
      }
      break;
    case "custom":
      const cutoffTime = new Date();
      cutoffTime.setHours(
        cutoffTime.getHours() - (wishlistVerification.hours || 24)
      );
      isValid = wishlistTimestamp < cutoffTime;
      if (!isValid) {
        message = `Only wishlisted items added before ${
          wishlistVerification.hours || 24
        } hours are eligible.`;
      }
      break;
    default:
      isValid = true;
  }

  console.log("Verification result:", {
    method: wishlistVerification?.method,
    isValid,
    timestamp: wishlistTimestamp,
  });

  return {
    isEligible: isValid,
    message: isValid ? "You are eligible for this item." : message,
  };
};


export const lockAllWishlists = async (guildId: string) => {
  const userGuildsRef = collection(db, "userGuilds");
  const guildUsersQuery = query(
    userGuildsRef,
    where("guildId", "==", guildId)
  );
  const querySnapshot = await getDocs(guildUsersQuery);

  const batch = writeBatch(db);
  querySnapshot.forEach((doc) => {
    batch.update(doc.ref, { wishlistLocked: true });
  });

  await batch.commit();
};

export const unlockAllWishlists = async (guildId: string) => {
  const userGuildsRef = collection(db, "userGuilds");
  const guildUsersQuery = query(
    userGuildsRef,
    where("guildId", "==", guildId)
  );
  const querySnapshot = await getDocs(guildUsersQuery);

  const batch = writeBatch(db);
  querySnapshot.forEach((doc) => {
    batch.update(doc.ref, { wishlistLocked: false });
  });

  await batch.commit();
};

export const resetWishlistTokens = async (
  guildId: string,
  tokenAmount: number = WEEKLY_WISHLIST_TOKENS
) => {
  const userGuildsRef = collection(db, "userGuilds");
  const guildUsersQuery = query(
    userGuildsRef,
    where("guildId", "==", guildId)
  );
  const querySnapshot = await getDocs(guildUsersQuery);

  const batch = writeBatch(db);
  querySnapshot.forEach((doc) => {
    batch.update(doc.ref, {
      wishlistTokens: tokenAmount,
      lastTokenReset: serverTimestamp(),
    });
  });

  await batch.commit();
};

export const fetchTopWishlistedItems = async (guildId: string) => {
  const userGuildsRef = collection(db, "userGuilds");
  const guildUsersQuery = query(
    userGuildsRef,
    where("guildId", "==", guildId)
  );
  const querySnapshot = await getDocs(guildUsersQuery);

  const itemCounts: { [key: string]: number } = {};

  querySnapshot.forEach((doc) => {
    const wishlist = doc.data().wishlist || [];
    wishlist.forEach((item: { itemName: string }) => {
      itemCounts[item.itemName] = (itemCounts[item.itemName] || 0) + 1;
    });
  });

  const sortedItems = Object.entries(itemCounts)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 10)
    .map(([itemName, count]) => ({ itemName, count }));

  return sortedItems;
};

export const addItemToWishlist = async (
  userId: string,
  guildId: string,
  item: ItemType
) => {
  const userGuildRef = doc(db, "userGuilds", `${userId}_${guildId}`);

  await runTransaction(db, async (transaction) => {
    const userGuildDoc = await transaction.get(userGuildRef);
    if (!userGuildDoc.exists()) {
      throw new Error("User guild document does not exist");
    }
    const userData = userGuildDoc.data();
    const currentTokens = userData?.wishlistTokens || 0;
    const isLocked = userData?.wishlistLocked || false;
    const wishlist = userData?.wishlist || [];

    if (isLocked) {
      throw new Error("Your wishlist is locked.");
    }

    if (currentTokens < item.tokenCost) {
      throw new Error(
        "Insufficient tokens to add this item to your wishlist"
      );
    }

    const isDuplicate = wishlist.some(
      (wishlistItem: WishlistItem) =>
        wishlistItem.itemName === item.name
    );
    if (isDuplicate) {
      throw new Error("Item is already in your wishlist");
    }

    const wishlistItem: WishlistItem = {
      itemName: item.name,
      imageUrl: `/images/new/${item.imageFilename}`,
      url: item.url || "",
      tokenCost: item.tokenCost,
      addedAt: Timestamp.now(), // Use client-side timestamp
    };

    transaction.update(userGuildRef, {
      wishlist: [...wishlist, wishlistItem],
      wishlistTokens: currentTokens - item.tokenCost,
    });
  });
};

export const removeItemFromWishlist = async (
  userId: string,
  guildId: string,
  item: WishlistItem
) => {
  const userGuildRef = doc(db, "userGuilds", `${userId}_${guildId}`);

  await runTransaction(db, async (transaction) => {
    const userGuildDoc = await transaction.get(userGuildRef);
    if (!userGuildDoc.exists()) {
      throw new Error("User guild document does not exist");
    }
    const userData = userGuildDoc.data();
    const isLocked = userData?.wishlistLocked || false;
    const wishlist = userData?.wishlist || [];

    if (isLocked) {
      throw new Error("Your wishlist is locked.");
    }

    const updatedWishlist = wishlist.filter(
      (wishlistItem: WishlistItem) =>
        wishlistItem.itemName !== item.itemName
    );
    if (updatedWishlist.length === wishlist.length) {
      throw new Error("Item not found in your wishlist");
    }

    transaction.update(userGuildRef, {
      wishlist: updatedWishlist
    });
  });
};

