import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { 
  AttachMoney, 
  Event, 
  Group, 
  Insights, 
  Settings, 
  Support 
} from '@mui/icons-material';

const items = [
  {
    icon: <AttachMoney />,
    title: 'DKP Management',
    description: 'Manage your guild DKP with preset rewards, decay system, and detailed point tracking. Split or distribute DKP across raid members with ease.',
  },
  {
    icon: <Event />,
    title: 'Event Planning',
    description: 'Organize raids efficiently with an integrated calendar system, roster management, and attendance tracking.',
  },
  {
    icon: <Group />,
    title: 'Team Management',
    description: 'Manage your guild members, and maintain fair loot distribution across your roster.',
  },
  {
    icon: <Settings />,
    title: 'Advanced Bidding System',
    description: 'Feature-rich bidding system with wishlist verification, private bidding options, anti-snipe protection, and real-time bid tracking across multiple shards for optimal performance.',
  },
  {
    icon: <Support />,
    title: 'Discord Integration',
    description: 'Keep your guild informed with automatic Discord notifications for bids, wins, and important announcements.',
  },
  {
    icon: <Insights />,
    title: 'Analytics & Logging',
    description: 'Comprehensive admin logs tracking all DKP changes, detailed user profiles with gear scores and roles, and full CSV export capabilities for data analysis.',
  }
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Key Features
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Discover the features that make LootManager the premier choice for GDKP raid management. From automated gold tracking to Discord integration, we provide everything you need to run successful raids.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
