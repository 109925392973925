import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

const Unsubscribe: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<'processing' | 'success' | 'error'>('processing');

  useEffect(() => {
    const handleUnsubscribe = async () => {
      const uid = searchParams.get('uid');
      
      if (!uid) {
        setStatus('error');
        return;
      }

      try {
        const userRef = doc(db, 'users', uid);
        
        await setDoc(userRef, {
          emailNotifications: false,
        }, { merge: true });
        
        setStatus('success');
      } catch (error) {
        console.error('Unsubscribe error:', error);
        setStatus('error');
      }
    };

    handleUnsubscribe();
  }, [searchParams]);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '80vh'
    }}>
      <Paper sx={{
        p: 4,
        maxWidth: 400,
        background: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)'
      }}>
        {status === 'processing' && (
          <Typography>Processing your unsubscribe request...</Typography>
        )}
        {status === 'success' && (
          <Typography>You have been successfully unsubscribed from email notifications.</Typography>
        )}
        {status === 'error' && (
          <Typography color="error">
            Unable to process your unsubscribe request. Please try again or contact support.
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default Unsubscribe;