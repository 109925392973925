import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useEffect, useState } from 'react';

interface EmailOptInPopupProps {
  uid: string;
  onClose: () => void;
}

const EmailOptInPopup: React.FC<{ uid: string }> = ({ uid }) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkEmailPreferences = async () => {
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (!userDoc.exists() || !userDoc.data()?.seenEmailPopup) {
        setShowPopup(true);
      }
    };

    checkEmailPreferences();
  }, [uid]);

  if (!showPopup) return null;

  const handleOptIn = async () => {
    try {
      await setDoc(doc(db, 'users', uid), {
        emailNotifications: true,
        seenEmailPopup: true
      }, { merge: true });
      setShowPopup(false);
    } catch (error) {
      console.error('Error saving email preferences:', error);
    }
  };

  const handleOptOut = async () => {
    try {
      await setDoc(doc(db, 'users', uid), {
        emailNotifications: false,
        seenEmailPopup: true
      }, { merge: true });
      setShowPopup(false);
    } catch (error) {
      console.error('Error saving email preferences:', error);
    }
  };

  return (
    <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
      <DialogTitle>Email Notifications</DialogTitle>
      <DialogContent>
        <Typography>
          Would you like to receive email notifications about important updates and events?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOptOut}>No, thanks</Button>
        <Button onClick={handleOptIn} variant="contained" color="primary">
          Yes, I'm in
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailOptInPopup;