export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'rgba(0, 0, 0, 0.9)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(225, 225, 230, 0.1)',
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    outline: 'none'
  };
  
  export const smallModalStyle = {
    ...modalStyle,
    width: 400
  };
  
  export const mediumModalStyle = {
    ...modalStyle,
    width: 600
  };
  
  export const largeModalStyle = {
    ...modalStyle,
    width: 800
  };
  