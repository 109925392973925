// interfaces.ts
import { Timestamp } from "firebase/firestore";

export interface UserGuild {
  gearScore: number | undefined;
  secondaryWeapon: string;
  primaryWeapon: string;
  id: string;
  uid: string;
  guildId: string;
  role: 'admin' | 'member' | 'officer';
  status: 'approved' | 'pending' | 'rejected';
  username: string;
  inGameName?: string;
  dkp: number;
  timestamp: Date;
  inGameRole?: ('DPS' | 'Healer' | 'Tank')[];
}

export interface UserProfile {
  guild: string | null;
  id: string;
  uid: string;
  username: string;
  dkp: number;
  role: 'admin' | 'member' | 'officer';
  status: 'approved' | 'pending' | 'rejected';
  memberships: UserGuild[];
  inGameName?: string;
  displayName?: string;
  primaryWeapon?: string;
  secondaryWeapon?: string;
  gearScore?: number;
  inGameRole?: ('DPS' | 'Healer' | 'Tank')[];
}

export enum BidType {
  WISHLIST = 'wishlist',
  TRAIT = 'trait',
  AUCTIONHOUSE = 'auctionhouse'
}

export const BID_PRIORITIES: Record<BidType, number> = {
  [BidType.WISHLIST]: 3,
  [BidType.TRAIT]: 2,
  [BidType.AUCTIONHOUSE]: 1
};

export interface Item {
  name: string;  
  itemName?: string;
  type?: string;
  stats?: Record<string, any>;
  traits?: string[];
  droppedBy?: string;
  pngFileName?: string;
}

export interface DKPPreset {
  id: string;
  name: string;
  amount: number;
  reason: string;
  guild: string;
}

export interface DKPLog {
  id: string;
  userId: string;
  username: string;
  inGameName: string;
  changeAmount: number;
  reason: string;
  timestamp: Date;
  adminId: string;
  adminName: string;
  guild: string;
}

export interface LootDrop {
  id: string;
  itemName: string;
  description: string;
  addedBy: string;
  createdAt: Date | Timestamp;
  currentBid: number;
  currentBidder: string | null;
  currentBidderName?: string;
  currentHighestRoller?: string;
  status: "open" | "distributed" | "removed" | "rolled";
  lootMethod: "dkp" | "roll";
  rolledNumber?: number;
  currentBidderUsername?: string;
  stats?: { [key: string]: any } | string[];
  traits?: string[];
  selectedTraits?: Record<string, boolean>;
  droppedBy?: string;
  inGameName?: string;
  username?: string;
  pngFileName?: string;
  itemStats?: string[];
  itemTraits?: string[];
  isPrivateBidding: boolean;
  minIncrement?: number;
  imageUrl?: string;
  selectedTrait?: string;
  url?: string;
  winnerName?: string;
  highestRoller: string;
  wishlistOnly: boolean;
  wishlistVerification: string;
  startingBid?: number;
  currentBidType?: BidType;
  allowedBidTypes?: BidType[];
}

export interface LootMethodModalProps {
  itemName: string;
  itemImage: string;
  isCustom: boolean;
  onConfirm: (
    method: "dkp" | "roll",
    isPrivateBidding: boolean,
    minIncrement: number,
    selectedTrait: string,
    itemName?: string,
    wishlistOnly?: boolean,
    wishlistVerification?: {
      method: "none" | "session" | "custom",
      hours?: number
    },
    requireBidReason?: boolean,
    startingBid?: number,
    allowedBidTypes?: BidType[]
  ) => void;
  onCancel: () => void;
}

export interface Roll {
  id: string;
  rollerId: string;
  rolledNumber: number;
  createdAt: {
    toDate: () => Date;
  };
  time?: Date;
}

export interface Session {
  id: string;
  name: string;
  createdBy: string;
  guild: string;
  createdAt: Date;
  lootmaster: string;
  totalParticipants: number;
  totalItems: number;
  remainingItems: number;
  itemNames: string;
  items: LootDrop[];
  eventId?: string;
  linkedEventTitle: string | null;
  antiSnipeEnabled?: boolean;
  antiSnipeTime?: number;
  expiresAt: Timestamp | Date;
}

export interface PendingRegistration {
  id: string;
  username: string;
  guild: string;
  timestamp: Date;
  userId?: string;
}

export interface Bid {
  id: string;
  bidderId: string;
  bidAmount: number;
  createdAt: {
    toDate: () => Date;
  };
  bidderName: string;
  bidType: BidType;
}
