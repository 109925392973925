import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
  increment,
  writeBatch
} from "firebase/firestore";
import { HandleRollLootProps } from "../interfaces/handlers";
import { logAction, LogActions } from "../services/logginServices";
import { verifyWishlistStatus } from "../components/Wishlist/WishListServices";
import { isSessionExpired } from "../components/sessions/checkExpire";
import { shardLogger } from "../services/shardLogger";

const incrementRollCounter = async (
  guildId: string,
  sessionId: string,
  lootId: string,
  amount: number
) => {
  const NUM_SHARDS = 10;
  const shardId = Math.floor(Math.random() * NUM_SHARDS);

  shardLogger.log(`Creating roll shard ${shardId} for loot ${lootId}`);
  shardLogger.log(`Path: sessions/${guildId}/sessions/${sessionId}/lootDrops/${lootId}/_rollCounters/shard_${shardId}`);

  const counterRef = doc(
    db,
    "sessions",
    guildId,
    "sessions",
    sessionId,
    "lootDrops",
    lootId,
    "_rollCounters",
    `shard_${shardId}`
  );

  shardLogger.log(`Incrementing roll shard by ${amount}`);

  return {
    ref: counterRef,
    update: { total: increment(amount) }
  };
};

export const handleRollLoot = async ({
  lootId,
  user,
  sessionId,
  reason,
  setConfirmationTitle,
  setConfirmationMessage,
  setIsError,
  setConfirmationModalVisible,
  setSuccess,
  setUserRolls,
  selectedGuild,
  session,
}: HandleRollLootProps & { reason?: string }) => {
  if (!user || !sessionId || !selectedGuild) return;

  shardLogger.log(`Starting roll process for item ${lootId}`, {
    sessionId,
    guildId: selectedGuild,
    userId: user.uid
  });

  // Pass both selectedGuild and sessionId to the isSessionExpired function
  const expired = await isSessionExpired(selectedGuild, sessionId);
  if (expired) {
    setConfirmationTitle("Session Expired");
    setConfirmationMessage("This session has expired. No more rolls can be made.");
    setIsError(true);
    setConfirmationModalVisible(true);
    return;
  }

  try {
    const lootRef = doc(db, "sessions", selectedGuild, "sessions", sessionId, "lootDrops", lootId);
    const lootDoc = await getDoc(lootRef);
    const lootData = lootDoc.data();
    const itemName = lootData?.itemName || "Unknown Item";

    // Check if the item is already distributed
    if (lootData?.status === "distributed") {
      setConfirmationTitle("Rolling Not Allowed");
      setConfirmationMessage("This item has already been distributed and cannot be rolled on.");
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
    }

    if (lootData?.wishlistOnly === true) {
      const isWishlisted = await verifyWishlistStatus(
        user.uid,
        itemName,
        session,
        lootData.wishlistVerification
      );

      if (!isWishlisted) {
        setConfirmationTitle("Wishlist Required");
        const hours = lootData.wishlistVerification?.hours || 0;
        const message = hours > 0
          ? `This item requires you to have it in your wishlist for at least ${hours} hours before rolling.`
          : "This item requires you to have it in your wishlist to roll.";

        setConfirmationMessage(message);
        setIsError(true);
        setConfirmationModalVisible(true);
        return;
      }
    }

    const rollsRef = collection(db, "sessions", selectedGuild, "sessions", sessionId, "lootDrops", lootId, "rolls");
    const q = query(rollsRef, where("rollerId", "==", user.uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      setConfirmationTitle("Roll Error");
      setConfirmationMessage("You have already rolled for this item.");
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
    }

    const rolledNumber = Math.floor(Math.random() * 100) + 1;

    // Begin batch operation
    const batch = writeBatch(db);

    // Add roll document
    const rollRef = doc(rollsRef);
    batch.set(rollRef, {
      rollerId: user.uid,
      rollerName: user.displayName || "Unknown",
      roll: rolledNumber,
      createdAt: serverTimestamp(),
      sessionId: sessionId,
      reason: reason || null,
    });

    // Update aggregate data with sharded counter
    const aggregateRef = doc(
      db,
      "sessions",
      selectedGuild,
      "sessions",
      sessionId,
      "aggregates",
      "lootData"
    );

    batch.set(aggregateRef, {
      rollCounts: {},
      highestRolls: {},
    }, { merge: true });

    // Implement sharded counter
    const shardedCounterUpdate = await incrementRollCounter(
      selectedGuild,
      sessionId,
      lootId,
      1
    );

    batch.set(shardedCounterUpdate.ref, shardedCounterUpdate.update, { merge: true });

    // Update highest roll if applicable
    const existingHighestRoll = lootData?.highestRoll || -1;
    if (rolledNumber > existingHighestRoll) {
      batch.update(lootRef, {
        highestRoll: rolledNumber,
        highestRollerName: user.displayName || "Unknown",
        highestRollerId: user.uid,
      });
      batch.update(aggregateRef, {
        [`highestRolls.${lootId}`]: rolledNumber
      });
    }

    // Commit all changes
    await batch.commit();

    shardLogger.log(`Roll operation completed`, {
      lootId,
      roll: rolledNumber,
      userId: user.uid
    });

    setSuccess(`You rolled a ${rolledNumber}!`);
    setConfirmationTitle("Roll Successful");
    setConfirmationMessage(`You rolled a ${rolledNumber}!`);

    await logAction({
      action: LogActions.ITEM_ROLLED,
      actor: user.uid,
      details: `Rolled ${rolledNumber} for item ${itemName}`,
      sessionId: sessionId,
      guild: selectedGuild
    });

    setIsError(false);
    setConfirmationModalVisible(true);

    setUserRolls((prev) => ({
      ...prev,
      [lootId]: true,
    }));
  } catch (error: any) {
    setConfirmationTitle("Error");
    setConfirmationMessage(error.message);
    setIsError(true);
    setConfirmationModalVisible(true);
  }
};
