// SessionDetail.tsx
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useSessionFirestore from "../hooks/useSessionFirestore";
import useUser from "../hooks/useUser";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  writeBatch,
  serverTimestamp,
} from "firebase/firestore";
import ConfirmationModal from "./ConfirmationModal";
import itemsDataNew from "../items.json";
import { LootDrop, BidType, BID_PRIORITIES } from "../interfaces";
import { handleBid } from "../handlers/handleBid";
import { handleRollLoot } from "../handlers/handleRollLoot";
import LootMethodModal from "./LootMethodModal";
import { logAction, LogActions } from "../services/logginServices";
import { Box, CircularProgress, Container, Grid2 } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BidModal from "./BidModal";
import RollModal from "./RollModal";
import { checkEventRegistration } from "../services/eventServices";
import {
  getAvailableEvents,
  linkEventToSession,
  getLinkedEvent,
} from "../services/eventServices";
import { SessionInfo } from "./sessions/detail/SessionInfo";
import { LootMasterPanel } from "./sessions/detail/LootMasterPanel";
import { LootTable } from "./sessions/detail/LootTable";
import { handleDistributeNew } from "../handlers/handleDistributeNew";
import { handleRemove } from "../handlers/handleRemove";
import { verifyWishlistStatus, consumeWishlistToken } from "../components/Wishlist/WishListServices";
import { updateDoc, Timestamp } from 'firebase/firestore';
import AppTheme from "../shared-theme/AppTheme";

interface ItemType {
  name: string;
  imageFilename: string;
  url?: string;
}

const adaptedItemsData: ItemType[] = (
  Array.isArray(itemsDataNew)
    ? itemsDataNew
    : (itemsDataNew as { items?: any[] }).items ?? []
).map((item: any) => ({
  name: item.name,
  imageFilename: item.imageFilename.replace(/'/g, "_").replace(/\s+/g, "_"),
  url: item.url,
}));

const SessionDetail: React.FC = () => {
  const [availableEvents, setAvailableEvents] = useState<any[]>([]);
  const [linkedEvent, setLinkedEvent] = useState<any>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { sessionId } = useParams<{ sessionId: string }>();
  const { user, loading: userLoading, selectedGuild } = useUser();
  const [enrichedLootDrops, setEnrichedLootDrops] = useState<LootDrop[]>([]);
  const [success, setSuccess] = useState<string | null>(null);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{
    itemName: string;
    itemImage: string;
    isCustom: boolean;
    url?: string;
  } | null>(null);
  const [selectedLootId, setSelectedLootId] = useState<string | null>(null);
  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [currentLootData, setCurrentLootData] = useState<any>(null);
  const [historyVisible, setHistoryVisible] = useState<Record<string, boolean>>({});
  const [bidHistoryVisible, setBidHistoryVisible] = useState<Record<string, boolean>>({});
  const [selectedGameMode, setSelectedGameMode] = useState<"Throne and Liberty" | "Custom">("Throne and Liberty");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState<ItemType[]>([]);
  const [currentLootId, setCurrentLootId] = useState<string | null>(null);
  const [rollModalOpen, setRollModalOpen] = useState<boolean>(false);
  const [requireRollReason, setRequireRollReason] = useState<boolean>(false);

  const [aggregateData, setAggregateData] = useState<{
    highestRolls: Record<string, number>;
    currentBids: Record<string, number>;
    rollCounts: Record<string, number>;
    bidCounts: Record<string, number>;
  }>({
    highestRolls: {},
    currentBids: {},
    rollCounts: {},
    bidCounts: {},
  });

  const {
    session,
    participants,
    lootDrops,
    loading,
    fetchMoreLootDrops,
    totalLootDropsCount,
  } = useSessionFirestore(sessionId, selectedGuild || undefined);

  const toggleHistory = (lootId: string) => {
    setHistoryVisible((prev) => ({ ...prev, [lootId]: !prev[lootId] }));
  };

  const toggleBidHistory = (lootId: string) => {
    setBidHistoryVisible((prev) => ({ ...prev, [lootId]: !prev[lootId] }));
  };

  const isSessionExpired = (expiresAt: Date | Timestamp | null) => {
    if (!expiresAt) return false;
    const expirationDate = expiresAt instanceof Timestamp ? expiresAt.toDate() : expiresAt;
    return new Date() > expirationDate;
  };

  const isLootmasterOrAdmin = useMemo(() => {
    return (user?.uid === session?.lootmaster || user?.role?.includes('admin')) ?? false;
  }, [user?.uid, session?.lootmaster, user?.role]);

  useEffect(() => {
    if (!sessionId || !selectedGuild) return;

    const aggregateRef = doc(
      db,
      "sessions",
      selectedGuild,
      "sessions",
      sessionId,
      "aggregates",
      "lootData"
    );

    const unsubscribe = onSnapshot(aggregateRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        setAggregateData({
          highestRolls: data.highestRolls || {},
          currentBids: data.currentBids || {},
          rollCounts: data.rollCounts || {},
          bidCounts: data.bidCounts || {},
        });
      }
    });

    return () => unsubscribe();
  }, [sessionId, selectedGuild]);

  useEffect(() => {
    const filtered = adaptedItemsData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [searchTerm]);

  useEffect(() => {
    const enrichLootDrops = () => {
      const newEnrichedDrops = lootDrops.map((loot) => ({
        ...loot,
        highestRoll: aggregateData.highestRolls[loot.id] || 0,
        currentBid: aggregateData.currentBids[loot.id] || 0,
        rollCount: aggregateData.rollCounts[loot.id] || 0,
        bidCount: aggregateData.bidCounts[loot.id] || 0,
      }));
      setEnrichedLootDrops(newEnrichedDrops);
    };

    if (lootDrops.length > 0) {
      enrichLootDrops();
    }
  }, [lootDrops, aggregateData]);

  useEffect(() => {
    const fetchAvailableEvents = async () => {
      if (!selectedGuild) return;
      try {
        const events = await getAvailableEvents(selectedGuild);
        setAvailableEvents(events);
      } catch (error) {
        console.error("Error fetching available events:", error);
        setAvailableEvents([]);
      }
    };
    fetchAvailableEvents();
  }, [selectedGuild]);

  useEffect(() => {
    const fetchLinkedEvent = async () => {
      if (!session || !session.eventId || !selectedGuild) {
        setLinkedEvent(null);
        return;
      }
      try {
        const event = await getLinkedEvent(selectedGuild, session.eventId);
        setLinkedEvent(event);
      } catch (error) {
        console.error("Error fetching linked event:", error);
        setLinkedEvent(null);
      }
    };
    fetchLinkedEvent();
  }, [session, selectedGuild]);

  const handleBidWrapper = async (bidAmount: number, bidType: BidType, reason?: string) => {
    if (!selectedLootId || !user || !selectedGuild || !sessionId || !session) return;

    // Anti-snipe check
    if (session.antiSnipeEnabled && session.expiresAt) {
      const currentTime = new Date();
      const expiryTime = session.expiresAt instanceof Timestamp ? session.expiresAt.toDate() : session.expiresAt;
      const timeLeftMinutes = (expiryTime.getTime() - currentTime.getTime()) / (1000 * 60);

      if (timeLeftMinutes < session.antiSnipeTime!) {
        const newExpiryTime = new Date(currentTime.getTime() + (session.antiSnipeTime! * 60 * 1000));
        const sessionRef = doc(db, "sessions", selectedGuild, "sessions", sessionId);
        await updateDoc(sessionRef, { expiresAt: Timestamp.fromDate(newExpiryTime) });

        await logAction({
          action: LogActions.SESSION_EXTENDED,
          actor: user.uid,
          details: `Session time extended by ${session.antiSnipeTime} minutes due to anti-snipe protection`,
          sessionId,
          guild: selectedGuild,
        });
      }
    }

    // Event registration check
    const canParticipate = await checkEventRegistration(sessionId, user.uid, selectedGuild);
    if (!canParticipate) {
      setConfirmationTitle("Error");
      setConfirmationMessage("You must be registered for the linked event to participate");
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
    }

    const lootRef = doc(db, "sessions", selectedGuild, "sessions", sessionId, "lootDrops", selectedLootId);
    const lootDoc = await getDoc(lootRef);
    const lootData = lootDoc.data();

    if (!lootData) {
      setConfirmationTitle("Error");
      setConfirmationMessage("Loot data not found");
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
    }

    // Priority and token checks for wishlist bids
    if (bidType === 'wishlist') {
      // Check if there's a higher priority bid
      if (lootData.currentBidType && BID_PRIORITIES[lootData.currentBidType as BidType] > BID_PRIORITIES[bidType]) {
        setConfirmationTitle("Bid Failed");
        setConfirmationMessage("Cannot outbid a higher priority bid type");
        setIsError(true);
        setConfirmationModalVisible(true);
        return;
      }

      // Verify wishlist eligibility and tokens
      const { isEligible, message } = await verifyWishlistStatus(
        user.uid,
        lootData.itemName,
        session,
        lootData.wishlistVerification
      );

      if (!isEligible) {
        setConfirmationTitle("Not Eligible");
        setConfirmationMessage(message || "You are not eligible to bid for this item.");
        setIsError(true);
        setConfirmationModalVisible(true);
        return;
      }
    } else {
      // For non-wishlist bids, check if there's any wishlist bid
      if (lootData.currentBidType === 'wishlist') {
        setConfirmationTitle("Bid Failed");
        setConfirmationMessage("Cannot outbid a wishlist bid with a lower priority bid type");
        setIsError(true);
        setConfirmationModalVisible(true);
        return;
      }
    }


    // Process the bid
    await handleBid({
      lootId: selectedLootId,
      bidAmount,
      bidType,
      reason,
      user,
      sessionId,
      selectedGuild,
      session,
      setConfirmationTitle,
      setConfirmationMessage,
      setIsError,
      setConfirmationModalVisible,
      setSuccess,
      uid: user.uid,
    });

    setBidModalOpen(false);
    setCurrentLootData(null);
  };


  const handleDistributeWrapper = async (lootId: string) => {
    if (!user || !isLootmasterOrAdmin) return;

    await handleDistributeNew({
      lootId,
      sessionId: sessionId!,
      selectedGuild: selectedGuild!,
      user,
      setConfirmationTitle,
      setConfirmationMessage,
      setIsError,
      setConfirmationModalVisible,
      setSuccess,
    });
  };

  const handleRemoveWrapper = async (lootId: string) => {
    if (!sessionId || !user || !selectedGuild) return;

    await handleRemove({
      lootId,
      sessionId,
      selectedGuild,
      setConfirmationTitle,
      setConfirmationMessage,
      setIsError,
      setConfirmationModalVisible,
      setSuccess,
      guildId: selectedGuild,
    });

    const aggregateRef = doc(
      db,
      "sessions",
      selectedGuild,
      "sessions",
      sessionId,
      "aggregates",
      "lootData"
    );
    const batch = writeBatch(db);

    batch.update(aggregateRef, {
      [`highestRolls.${lootId}`]: null,
      [`currentBids.${lootId}`]: null,
      [`rollCounts.${lootId}`]: null,
      [`bidCounts.${lootId}`]: null,
    });

    await batch.commit();
  };

  const filteredEnrichedLootDrops = useMemo(() => {
    if (!enrichedLootDrops) return [];
    return enrichedLootDrops;
  }, [enrichedLootDrops]);

  const handleRollConfirm = async (reason?: string) => {
    if (!currentLootId || !user || !sessionId || !selectedGuild || !session)
      return;

    await handleRollLoot({
      lootId: currentLootId,
      user,
      sessionId,
      reason,
      setConfirmationTitle,
      setConfirmationMessage,
      setIsError,
      setConfirmationModalVisible,
      setSuccess,
      setUserRolls: () => { },
      selectedGuild,
      session,
    });

    setRollModalOpen(false);
    setCurrentLootId(null);
    setRequireRollReason(false);
  };

  if (!sessionId || loading.session || userLoading || !user || !selectedGuild) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AppTheme>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          minHeight: '100vh',
          pt: 4,
        }}
      >
        <Grid2
          container
          spacing={3}
          sx={{
            backgroundColor: '#121212',
            color: '#E1E1E6',
            pt: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 3, md: 4 },
            mb: { xs: 2, sm: 4 },
            width: '100%',
            maxWidth: { xs: '100%', lg: 'lg' },
          }}
        >
          <Grid2 size={12}>
            <SessionInfo
              session={session}
              selectedGuild={selectedGuild}
              linkedEvent={linkedEvent}
              isLootmasterOrAdmin={isLootmasterOrAdmin}
              availableEvents={availableEvents}
              onLinkEvent={async (eventId: string) => {
                try {
                  if (eventId === '') {
                    await linkEventToSession(selectedGuild, sessionId, null);
                    setLinkedEvent(null);
                    setSuccess('Event unlinked successfully');
                  } else {
                    await linkEventToSession(selectedGuild, sessionId, eventId);
                    const event = await getLinkedEvent(selectedGuild, eventId);
                    setLinkedEvent(event);
                    setSuccess('Event linked successfully');
                  }
                } catch (error) {
                  console.error('Error linking/unlinking event:', error);
                  setConfirmationTitle('Error');
                  setConfirmationMessage('Failed to link/unlink the selected event.');
                  setIsError(true);
                  setConfirmationModalVisible(true);
                }
              }}
            />
          </Grid2>
  
          {isLootmasterOrAdmin && (
            <Grid2 size={12}>
              <LootMasterPanel
                selectedGameMode={selectedGameMode}
                searchTerm={searchTerm}
                filteredItems={filteredItems}
                onGameModeChange={setSelectedGameMode}
                onSearchChange={setSearchTerm}
                onAddItem={setSelectedItem}
                isLootmasterOrAdmin={isLootmasterOrAdmin}
              />
            </Grid2>
          )}
  
          <Grid2 size={12}>
            <LootTable
              loading={loading.loot}
              lootDrops={filteredEnrichedLootDrops}
              isLootmasterOrAdmin={isLootmasterOrAdmin}
              onRoll={async (props) => {
                if (!user || !sessionId || !selectedGuild || !session) return;
  
                // Prevent roll if session is expired
                if (isSessionExpired(session.expiresAt)) {
                  setConfirmationTitle('Error');
                  setConfirmationMessage('This session has expired. You cannot roll.');
                  setIsError(true);
                  setConfirmationModalVisible(true);
                  return;
                }
  
                const canParticipate = await checkEventRegistration(
                  sessionId,
                  user.uid,
                  selectedGuild
                );
  
                if (!canParticipate) {
                  setConfirmationTitle('Error');
                  setConfirmationMessage(
                    'You must be registered for the linked event to participate'
                  );
                  setIsError(true);
                  setConfirmationModalVisible(true);
                  return;
                }
  
                const lootId = props.lootId;
                const lootRef = doc(
                  db,
                  'sessions',
                  selectedGuild,
                  'sessions',
                  sessionId,
                  'lootDrops',
                  lootId
                );
                const lootDoc = await getDoc(lootRef);
                const lootData = lootDoc.data();
  
                if (lootData?.wishlistOnly === true) {
                  const { isEligible, message } = await verifyWishlistStatus(
                    user.uid,
                    lootData.itemName,
                    session,
                    lootData.wishlistVerification
                  );
  
                  if (!isEligible) {
                    setConfirmationTitle('Not Eligible');
                    setConfirmationMessage(
                      message || 'You are not eligible to roll for this item.'
                    );
                    setIsError(true);
                    setConfirmationModalVisible(true);
                    return;
                  }
                }
  
                if (lootData?.requireBidReason) {
                  setRequireRollReason(true);
                  setCurrentLootId(lootId);
                  setRollModalOpen(true);
                } else {
                  await handleRollLoot({
                    ...props,
                    user,
                    sessionId,
                    reason: undefined,
                    setConfirmationTitle,
                    setConfirmationMessage,
                    setIsError,
                    setConfirmationModalVisible,
                    setSuccess,
                    setUserRolls: () => {},
                    selectedGuild,
                    session,
                  });
                }
              }}
              onDistribute={handleDistributeWrapper}
              onRemove={handleRemoveWrapper}
              onToggleHistory={toggleHistory}
              onToggleBidHistory={toggleBidHistory}
              onBid={async (lootId) => {
                if (!sessionId || !selectedGuild) return;
  
                // Prevent bid if session is expired
                if (session && isSessionExpired(session.expiresAt)) {
                  setConfirmationTitle('Error');
                  setConfirmationMessage('This session has expired. You cannot bid.');
                  setIsError(true);
                  setConfirmationModalVisible(true);
                  return;
                }
  
                const lootRef = doc(
                  db,
                  'sessions',
                  selectedGuild,
                  'sessions',
                  sessionId,
                  'lootDrops',
                  lootId
                );
                const lootDoc = await getDoc(lootRef);
                const lootData = lootDoc.data();
  
                // Convert the allowedBidTypes to BidType enum values
                const allowedTypes = lootData?.allowedBidTypes || [BidType.AUCTIONHOUSE];
  
                setCurrentLootData({
                  ...lootData,
                  allowedBidTypes: allowedTypes,
                });
  
                if (lootData?.wishlistOnly === true) {
                  const { isEligible, message } = await verifyWishlistStatus(
                    user.uid,
                    lootData.itemName,
                    session,
                    lootData.wishlistVerification
                  );
  
                  if (!isEligible) {
                    setConfirmationTitle('Not Eligible');
                    setConfirmationMessage(
                      message || 'You are not eligible to bid for this item.'
                    );
                    setIsError(true);
                    setConfirmationModalVisible(true);
                    return;
                  }
                }
  
                setBidModalOpen(true);
                setSelectedLootId(lootId);
              }}
              historyVisible={historyVisible}
              bidHistoryVisible={bidHistoryVisible}
              sessionId={sessionId}
              user={user}
              hasRolls={{}}
              hasBids={{}}
              session={session}
              aggregateData={aggregateData}
              fetchMoreLootDrops={fetchMoreLootDrops}
              totalLootDropsCount={totalLootDropsCount}
            />
          </Grid2>
        </Grid2>
  
        {confirmationModalVisible && (
          <ConfirmationModal
            title={confirmationTitle}
            message={confirmationMessage}
            onConfirm={() => setConfirmationModalVisible(false)}
            onCancel={() => setConfirmationModalVisible(false)}
            isError={isError}
          />
        )}
  
        <BidModal
          open={bidModalOpen}
          onClose={() => {
            setBidModalOpen(false);
            setCurrentLootData(null);
          }}
          onConfirm={handleBidWrapper}
          requireReason={currentLootData?.requireBidReason}
          allowedBidTypes={currentLootData?.allowedBidTypes || [BidType.AUCTIONHOUSE]}
        />
  
        <RollModal
          open={rollModalOpen}
          onClose={() => {
            setRollModalOpen(false);
            setCurrentLootId(null);
            setRequireRollReason(false);
          }}
          onConfirm={handleRollConfirm}
          requireReason={requireRollReason}
        />
  
        {selectedItem && (
          <LootMethodModal
            itemName={selectedItem.itemName}
            itemImage={selectedItem.itemImage}
            isCustom={selectedItem.isCustom}
            onConfirm={async (
              method,
              isPrivateBidding,
              minIncrement,
              selectedTrait,
              itemNameInput,
              wishlistOnly,
              wishlistVerification,
              requireBidReason,
              startingBid,
              allowedBidTypes
            ) => {
              if (!sessionId || !user || !selectedGuild) return;
  
              const batch = writeBatch(db);
              const lootRef = doc(
                collection(
                  db,
                  'sessions',
                  selectedGuild,
                  'sessions',
                  sessionId,
                  'lootDrops'
                )
              );
  
              const processedData = {
                itemName: itemNameInput || selectedItem.itemName,
                description: selectedItem.isCustom
                  ? 'Custom Item'
                  : 'Throne and Liberty Item',
                traits: [selectedTrait],
                addedBy: user.uid,
                createdAt: serverTimestamp(),
                currentBid: 0,
                currentBidder: null,
                status: 'open',
                lootMethod: method,
                rolledNumber: null,
                minIncrement,
                isPrivateBidding,
                imageUrl: selectedItem.itemImage,
                wishlistOnly: wishlistOnly || false,
                ...(wishlistVerification &&
                  wishlistVerification.method !== 'none' && {
                    wishlistVerification: {
                      method: wishlistVerification.method,
                      ...(wishlistVerification.method === 'custom' && {
                        hours: wishlistVerification.hours || 24,
                      }),
                    },
                  }),
                requireBidReason: requireBidReason || false,
                highestRoll: -1,
                highestRollerName: '',
                highestRollerId: '',
                startingBid: startingBid || 1,
                allowedBidTypes: allowedBidTypes || [BidType.AUCTIONHOUSE],
              };
  
              batch.set(lootRef, processedData);
  
              const aggregateRef = doc(
                db,
                'sessions',
                selectedGuild,
                'sessions',
                sessionId,
                'aggregates',
                'lootData'
              );
              batch.update(aggregateRef, {
                [`items.${lootRef.id}`]: true,
              });
  
              await batch.commit();
  
              await logAction({
                action: LogActions.ITEM_ADDED,
                actor: user.uid,
                details: `Added item: ${processedData.itemName} (${method})`,
                sessionId,
                guild: selectedGuild,
              });
  
              setSelectedItem(null);
              setSuccess('Item added successfully');
            }}
            onCancel={() => setSelectedItem(null)}
          />
        )}
      </Container>
    </AppTheme>
  );
  
  
};

export default SessionDetail;
