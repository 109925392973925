// src/components/Dashboard.tsx

import React, { useState, useEffect } from "react";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  FieldValue,
} from "firebase/firestore";
import useUser from "../hooks/useUser";
import { Timestamp } from "firebase/firestore";
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  useTheme,
  Card,
  CardContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Modal,
} from "@mui/material";
import UserDKPList from "../DKPManagement/UserDKPList";
import { useSelector, useDispatch } from "react-redux";
import { enableTutorials } from "../store/tutorialSlice";
import { RootState } from "../store/store";
import AppTheme from "../shared-theme/AppTheme";
import {
  smallModalStyle,
  mediumModalStyle,
} from "../components/shared/ModalStyle";

interface UserGuild {
  inGameName?: string;
  primaryWeapon?: string;
  secondaryWeapon?: string;
  gearScore?: number;
  inGameRole?: string[];
  username?: string;
}

interface GuildTrial {
  activatedAt: Timestamp | FieldValue;
  activatedBy: string;
  expiresAt: Timestamp;
  hasUsedTrial: boolean;
}

interface Guild {
  isSubscribed: boolean;
  trial?: GuildTrial;
}

const Dashboard: React.FC = () => {
  const [trialStatus, setTrialStatus] = useState<GuildTrial | null>(null);
  const weapons = [
    "Longbow",
    "Dagger",
    "Crossbow",
    "Greatsword",
    "Staff",
    "Wand",
    "Sword and Shield",
    "Spear"
  ];

  const inGameRoles = ["DPS", "Healer", "Tank"];

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [newPrimaryWeapon, setNewPrimaryWeapon] = useState("");
  const [newSecondaryWeapon, setNewSecondaryWeapon] = useState("");
  const [newGearScore, setNewGearScore] = useState<number>(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading, selectedGuild } = useUser();
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [guildData, setGuildData] = useState<Guild | null>(null);

  // Modal State
  const [showInGameNameModal, setShowInGameNameModal] = useState<boolean>(false);
  const [newInGameName, setNewInGameName] = useState<string>("");
  const [savingInGameName, setSavingInGameName] = useState<boolean>(false);
  const [userGuildId, setUserGuildId] = useState<string>("");

  // Username State
  const [currentUsername, setCurrentUsername] = useState<string>("");

  // User Profile State
  const [currentUserProfile, setCurrentUserProfile] = useState<{
    inGameName: string;
    primaryWeapon: string;
    secondaryWeapon: string;
    gearScore: number;
    inGameRole: string[];
  }>({
    inGameName: "",
    primaryWeapon: "",
    secondaryWeapon: "",
    gearScore: 0,
    inGameRole: [],
  });

  const theme = useTheme();
  const dispatch = useDispatch();
  const isTutorialEnabled = useSelector(
    (state: RootState) => state.tutorial.isEnabled
  );


  // Token check effect
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token) {
      setAuthenticating(true);
      navigate("/dashboard", { replace: true });
    }
  }, [location, navigate]);

  // Logout handler
  const handleLogout = async () => {
    await signOut(auth);
    navigate("/login");
  };

  // Main data loading effect
  useEffect(() => {
    if (!user || !selectedGuild) return;

    const loadDashboardData = async () => {
      try {
        // Directly access the userGuild document using its ID
        const userGuildDocRef = doc(db, "userGuilds", `${user.uid}_${selectedGuild}`);
        const userGuildDoc = await getDoc(userGuildDocRef);

        if (userGuildDoc.exists()) {
          const userGuildData = userGuildDoc.data() as UserGuild;

          // Set user guild ID
          setUserGuildId(userGuildDoc.id);

          // Set username
          setCurrentUsername(userGuildData.username || "Unknown User");

          // Set profile data
          const profileData = {
            inGameName: userGuildData.inGameName || "",
            primaryWeapon: userGuildData.primaryWeapon || "",
            secondaryWeapon: userGuildData.secondaryWeapon || "",
            gearScore: userGuildData.gearScore || 0,
            inGameRole: userGuildData.inGameRole || [],
          };

          setCurrentUserProfile(profileData);
          setNewInGameName(profileData.inGameName);
          setNewPrimaryWeapon(profileData.primaryWeapon);
          setNewSecondaryWeapon(profileData.secondaryWeapon);
          setNewGearScore(profileData.gearScore);
          setSelectedRoles(profileData.inGameRole);

          // Check if profile needs completion
          if (
            !userGuildData.inGameName?.trim() ||
            !userGuildData.primaryWeapon?.trim() ||
            !userGuildData.gearScore ||
            !userGuildData.inGameRole?.length
          ) {
            setShowInGameNameModal(true);
          }
        } else {
          console.error("UserGuild document does not exist.");
          // Handle the case where the document doesn't exist
        }

        // Fetch trial status
        const guildDoc = await getDoc(doc(db, "guilds", selectedGuild));
        const trialData = guildDoc.data()?.trial as GuildTrial | undefined;
        const currentTime = Timestamp.now();

        if (trialData && trialData.expiresAt.toDate() > currentTime.toDate()) {
          setTrialStatus(trialData);
        } else {
          setTrialStatus(null);
        }
      } catch (error) {
        console.error("Error loading dashboard data:", error);
      }
    };

    loadDashboardData();
  }, [user, selectedGuild]);

  useEffect(() => {
    const fetchGuildData = async () => {
      if (!selectedGuild) return;

      try {
        const guildDoc = await getDoc(doc(db, "guilds", selectedGuild));
        const guildDocData = guildDoc.data() as Guild;
        setGuildData(guildDocData);
      } catch (error) {
        console.error("Error fetching guild data:", error);
      }
    };

    fetchGuildData();
  }, [selectedGuild]);

  // Profile modal effect
  useEffect(() => {
    if (showInGameNameModal) {
      const userGuildData = currentUserProfile;
      setNewInGameName(userGuildData.inGameName);
      setNewPrimaryWeapon(userGuildData.primaryWeapon);
      setNewSecondaryWeapon(userGuildData.secondaryWeapon);
      setNewGearScore(userGuildData.gearScore);
      setSelectedRoles(userGuildData.inGameRole);
    }
  }, [showInGameNameModal, currentUserProfile]);

  const activateTrial = async () => {
    if (!user || !selectedGuild) return;

    try {
      const guildRef = doc(db, "guilds", selectedGuild);
      const guildDoc = await getDoc(guildRef);
      const guildData = guildDoc.data();

      // Check if guild is already subscribed
      if (guildData?.isSubscribed) {
        alert("This guild already has an active subscription. Trial activation is not needed.");
        return;
      }

      // Check existing trial
      if (guildData?.trial) {
        await updateDoc(guildRef, {
          trial: {
            ...guildData.trial,
            hasUsedTrial: true,
          },
          isSubscribed: false,
        });

        alert("This guild has already used its trial period.");
        return;
      }

      const expiresAt = Timestamp.fromMillis(Date.now() + 48 * 60 * 60 * 1000);

      const trialData: GuildTrial = {
        activatedAt: serverTimestamp(),
        activatedBy: user.uid,
        expiresAt,
        hasUsedTrial: true,
      };

      await updateDoc(guildRef, {
        trial: trialData,
        isSubscribed: false,
      });

      setTrialStatus(trialData);
      alert("Trial period activated successfully! Bot features are now enabled for 48 hours.");
    } catch (error) {
      console.error("Error activating trial:", error);
      alert("Failed to activate trial. Please try again.");
    }
  };

  // Function to fetch the user's profile
  const fetchUserProfile = async () => {
    if (!user || !selectedGuild) return;

    try {
      // Directly access the userGuild document using its ID
      const userGuildDocRef = doc(db, "userGuilds", `${user.uid}_${selectedGuild}`);
      const userGuildDoc = await getDoc(userGuildDocRef);

      if (userGuildDoc.exists()) {
        const userGuildData = userGuildDoc.data() as UserGuild;

        setUserGuildId(userGuildDoc.id);

        const profileData = {
          inGameName: userGuildData.inGameName || "",
          primaryWeapon: userGuildData.primaryWeapon || "",
          secondaryWeapon: userGuildData.secondaryWeapon || "",
          gearScore: userGuildData.gearScore || 0,
          inGameRole: userGuildData.inGameRole || [],
        };

        setCurrentUserProfile(profileData);
        setNewInGameName(profileData.inGameName);
        setNewPrimaryWeapon(profileData.primaryWeapon);
        setNewSecondaryWeapon(profileData.secondaryWeapon);
        setNewGearScore(profileData.gearScore);
        setSelectedRoles(profileData.inGameRole);
      } else {
        console.error("UserGuild document does not exist.");
        // Handle the case where the document doesn't exist
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    if (user && selectedGuild) {
      fetchUserProfile();
    }
  }, [user, selectedGuild]);

  const [showTrialModal, setShowTrialModal] = useState<boolean>(false);

  const handleTrialButtonClick = () => {
    setShowTrialModal(true);
  };

  const handleSaveInGameName = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newInGameName.trim()) {
      alert("In-Game Name cannot be empty.");
      return;
    }

    if (selectedRoles.length === 0) {
      alert("Please select at least one in-game role.");
      return;
    }

    setSavingInGameName(true);
    try {
      const userGuildRef = doc(db, "userGuilds", userGuildId);
      const updateData = {
        inGameName: newInGameName.trim(),
        primaryWeapon: newPrimaryWeapon,
        secondaryWeapon: newSecondaryWeapon,
        gearScore: newGearScore,
        inGameRole: selectedRoles,
        timestamp: serverTimestamp(),
      };

      await updateDoc(userGuildRef, updateData);

      setCurrentUserProfile((prev) => ({
        ...prev,
        inGameName: newInGameName.trim(),
        primaryWeapon: newPrimaryWeapon,
        secondaryWeapon: newSecondaryWeapon,
        gearScore: newGearScore,
        inGameRole: selectedRoles,
      }));

      await fetchUserProfile();
      setShowInGameNameModal(false);
      alert("Profile updated successfully.");
    } catch (error) {
      console.error("Dashboard - Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    } finally {
      setSavingInGameName(false);
    }
  };

  if (loading || authenticating) {
    return <div className="loading">Loading...</div>;
  }

  const isApproved = user?.status === "approved";

  if (!isApproved) {
    navigate("/pending-approval");
    return null;
  }

  return (
    <AppTheme>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          minHeight: '100vh',
          pt: 4
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            backgroundColor: "#121212",
            color: "#E1E1E6",
            pt: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 3, md: 4 },
            mb: { xs: 2, sm: 4 },
            width: "100%",
            maxWidth: { xs: "100%", lg: "lg" },
          }}
        >
          {/* Profile Card */}
          <Grid item xs={12} sm={12} md={4}>
            <Card
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.05)",
                backdropFilter: "blur(10px)",
                border: "1px solid rgba(255, 255, 255, 0.08)",
                borderRadius: 0,
              }}
            >
              <CardContent>
                {/* Content of the Profile Card */}
                <Box sx={{ textAlign: "center", mb: 3 }}>
                  <Typography
                    variant="h5"
                    sx={{ color: "white", fontWeight: 600, mb: 1 }}
                  >
                    {currentUsername}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: 700,
                      color: "#FFA500",
                    }}
                  >
                    {Math.ceil(user?.dkp)} DKP
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    p: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    borderRadius: 0,
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "white",
                    }}
                  >
                    <span>Role</span>
                    <strong>{user?.role || "Member"}</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "white",
                    }}
                  >
                    <span>Gear Score</span>
                    <strong>{currentUserProfile.gearScore}</strong>
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => dispatch(enableTutorials())}
                    disabled={isTutorialEnabled}
                    sx={{
                      color: "#FFA500",
                      borderColor: "#FFA500",
                      "&:hover": {
                        borderColor: "#FFA500",
                        backgroundColor: "rgba(255, 165, 0, 0.1)",
                      },
                    }}
                  >
                    {isTutorialEnabled
                      ? "Tutorial Icons Enabled"
                      : "Tutorial Help Icons"}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Character Details */}
          <Grid item xs={12} sm={12} md={8}>
            <Card
              sx={{
                mb: 3,
                backgroundColor: "rgba(255, 255, 255, 0.05)",
                backdropFilter: "blur(10px)",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                borderRadius: 0,
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ mb: 3, color: "white" }}>
                  Character Details
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                      >
                        Primary Weapon
                      </Typography>
                      <Typography variant="body1" sx={{ color: "white" }}>
                        {currentUserProfile.primaryWeapon || "Not Set"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                      >
                        Secondary Weapon
                      </Typography>
                      <Typography variant="body1" sx={{ color: "white" }}>
                        {currentUserProfile.secondaryWeapon || "Not Set"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                      >
                        In-Game Roles
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                          mt: 1,
                        }}
                      >
                        {currentUserProfile.inGameRole.map((role) => (
                          <Box
                            key={role}
                            sx={{
                              px: 2,
                              py: 0.5,
                              borderRadius: 0,
                              backgroundColor: "rgba(255, 255, 255, 0.1)",
                              border: "1px solid rgba(255, 255, 255, 0.2)",
                              color: "white",
                              fontSize: "0.875rem",
                            }}
                          >
                            {role}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Action Buttons */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
                mt: { xs: 2, sm: 0 },
              }}
            >
              <Button
                variant="contained"
                onClick={() => setShowInGameNameModal(true)}
                fullWidth
                sx={{
                  py: { xs: 1, sm: 1.5 },
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                }}
              >
                Edit Profile
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/sessions")}
                fullWidth
                sx={{
                  py: { xs: 1, sm: 1.5 },
                  borderColor: "rgba(255, 255, 255, 0.3)",
                  color: "white",
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                  "&:hover": {
                    borderColor: "white",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                }}
              >
                View Sessions
              </Button>
            </Box>
          </Grid>

          {/* Admin Trial Section */}
          {user?.role === "admin" &&
            !guildData?.isSubscribed &&
            (!trialStatus?.hasUsedTrial ||
              (trialStatus?.expiresAt?.toDate().getTime() > Timestamp.now().toDate().getTime())) && (
              <Grid item xs={12}>
                <Card
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    backdropFilter: "blur(10px)",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    borderRadius: 0,
                    mb: 3,
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: 3,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ mb: 1, color: "white", fontWeight: 600 }}
                      >
                        Discord Bot Trial
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "rgba(255, 255, 255, 0.8)",
                          fontWeight: 400,
                        }}
                      >
                        {trialStatus
                          ? "Trial period active"
                          : "Activate your 48-hour trial period"}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      onClick={handleTrialButtonClick}
                      disabled={!!trialStatus?.hasUsedTrial}
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                        },
                        "&:disabled": {
                          backgroundColor: "rgba(255, 255, 255, 0.05)",
                          color: "rgba(255, 255, 255, 0.3)",
                        },
                      }}
                    >
                      {trialStatus
                        ? `${Math.ceil(
                          (trialStatus.expiresAt.toDate().getTime() -
                            Timestamp.now().toDate().getTime()) /
                          (1000 * 60 * 60)
                        )}h remaining`
                        : "Activate Trial"}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            )}

          {/* DKP List */}
          <Grid item xs={12}>
            <UserDKPList />
          </Grid>
        </Grid>

        {/* Trial Activation Modal */}
        <Modal
          keepMounted
          open={showTrialModal}
          onClose={() => setShowTrialModal(false)}
        >
          <Box sx={{ ...smallModalStyle, borderRadius: 0 }}>
            <Typography variant="h6" sx={{ mb: 2, color: "#E1E1E6" }}>
              Activate Trial Period
            </Typography>
            <Typography sx={{ mb: 3, color: "#B2B2B8" }}>
              Are you sure you want to activate the 48-hour trial period? This
              can only be done once.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
              <Button
                onClick={() => setShowTrialModal(false)}
                sx={{ color: "#8A8A94" }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  activateTrial();
                  setShowTrialModal(false);
                }}
                variant="contained"
                sx={{
                  backgroundColor: "rgba(225, 225, 230, 0.08)",
                  color: "#E1E1E6",
                  "&:hover": {
                    backgroundColor: "rgba(225, 225, 230, 0.15)",
                  },
                }}
              >
                Activate Trial
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Edit Profile Modal */}
        <Modal
          open={showInGameNameModal}
          onClose={() => setShowInGameNameModal(false)}
        >
          <Box sx={{ ...mediumModalStyle, borderRadius: 0 }}>
            <Typography variant="h6" sx={{ mb: 3, color: "#E1E1E6" }}>
              Edit Profile
            </Typography>
            <form onSubmit={handleSaveInGameName}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="In-Game Name"
                    value={newInGameName}
                    onChange={(e) => setNewInGameName(e.target.value)}
                    required
                    sx={{
                      input: { color: "#E1E1E6" },
                      label: { color: "#B2B2B8" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(225, 225, 230, 0.3)",
                        },
                        "&:hover fieldset": {
                          borderColor: "rgba(225, 225, 230, 0.5)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#E1E1E6",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel sx={{ color: "#B2B2B8" }}>
                      Primary Weapon
                    </InputLabel>
                    <Select
                      value={newPrimaryWeapon}
                      onChange={(e) => setNewPrimaryWeapon(e.target.value)}
                      label="Primary Weapon"
                      required
                      sx={{
                        color: "#E1E1E6",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(225, 225, 230, 0.3)",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(225, 225, 230, 0.5)",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E1E1E6",
                        },
                        ".MuiSvgIcon-root": { color: "#E1E1E6" },
                      }}
                    >
                      {weapons.map((weapon) => (
                        <MenuItem key={weapon} value={weapon}>
                          {weapon}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel sx={{ color: "#B2B2B8" }}>
                      Secondary Weapon
                    </InputLabel>
                    <Select
                      value={newSecondaryWeapon}
                      onChange={(e) => setNewSecondaryWeapon(e.target.value)}
                      label="Secondary Weapon"
                      sx={{
                        color: "#E1E1E6",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(225, 225, 230, 0.3)",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(225, 225, 230, 0.5)",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E1E1E6",
                        },
                        ".MuiSvgIcon-root": { color: "#E1E1E6" },
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {weapons.map((weapon) => (
                        <MenuItem key={weapon} value={weapon}>
                          {weapon}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Gear Score"
                    value={newGearScore}
                    onChange={(e) => setNewGearScore(Number(e.target.value))}
                    required
                    sx={{
                      input: { color: "#E1E1E6" },
                      label: { color: "#B2B2B8" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(225, 225, 230, 0.3)",
                        },
                        "&:hover fieldset": {
                          borderColor: "rgba(225, 225, 230, 0.5)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#E1E1E6",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, color: "#E1E1E6" }}
                  >
                    In-Game Roles
                  </Typography>
                  <FormGroup row>
                    {inGameRoles.map((role) => (
                      <FormControlLabel
                        key={role}
                        control={
                          <Checkbox
                            checked={selectedRoles.includes(role)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedRoles([...selectedRoles, role]);
                              } else {
                                setSelectedRoles(
                                  selectedRoles.filter((r) => r !== role)
                                );
                              }
                            }}
                            sx={{
                              color: "#B2B2B8",
                              "&.Mui-checked": {
                                color: "#E1E1E6",
                              },
                            }}
                          />
                        }
                        label={role}
                        sx={{ color: "#E1E1E6" }}
                      />
                    ))}
                  </FormGroup>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  mt: 3,
                }}
              >
                <Button
                  onClick={() => setShowInGameNameModal(false)}
                  sx={{ color: "#8A8A94" }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={savingInGameName}
                  sx={{
                    backgroundColor: "rgba(225, 225, 230, 0.08)",
                    color: "#E1E1E6",
                    "&:hover": {
                      backgroundColor: "rgba(225, 225, 230, 0.15)",
                    },
                    "&:disabled": {
                      backgroundColor: "rgba(225, 225, 230, 0.05)",
                      color: "#8A8A94",
                    },
                  }}
                >
                  {savingInGameName ? "Saving..." : "Save Changes"}
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </Container>
    </AppTheme>
  );
};

export default Dashboard;
