// TeamForm.tsx
import React, { useState } from 'react';
import { Paper, Typography, TextField, Button, Autocomplete } from '@mui/material';
import CreateTeamModal from './CreateTeamModal';
import useUser from '../../hooks/useUser';

interface TeamFormProps {
    onCreateTeam: (teamName: string, leader: string, secondLeader?: string) => void;
    guildMembers: { uid: string; inGameName: string }[];
}

const TeamForm: React.FC<TeamFormProps> = ({ onCreateTeam, guildMembers }) => {
    const [newTeamName, setNewTeamName] = useState('');
    const [leader, setLeader] = useState<{ uid: string; inGameName: string } | null>(null);
    const [secondLeader, setSecondLeader] = useState<{ uid: string; inGameName: string } | null>(null);
    const [error, setError] = useState('');
    const [showCreateModal, setShowCreateModal] = useState(false);
    const { selectedGuild } = useUser();

    const handleTeamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const isValid = /^[a-zA-Z0-9]+$/.test(value);

        if (isValid || value === '') {
            setNewTeamName(value);
            setError('');
        } else {
            setError('Team name can only contain letters and numbers.');
        }
    };

    const handleCreateTeam = () => {
        if (leader && newTeamName) {
            onCreateTeam(newTeamName, leader.inGameName, secondLeader?.inGameName);
            setNewTeamName('');
            setLeader(null);
            setSecondLeader(null);
        }
    };

    const validateForm = () => {
        if (!newTeamName) {
            setError('Team name cannot be empty.');
            return false;
        }
        if (!leader) {
            setError('Team leader must be selected.');
            return false;
        }
        return true;
    };

    const handleCreateButtonClick = () => {
        if (validateForm()) {
            setShowCreateModal(true);
        }
    };

    const handleModalProceed = () => {
        setShowCreateModal(false);
        handleCreateTeam();
    };

    return (
        <>
            <Paper sx={{
                height: '100%',
                background: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
                padding: 3,
            }}>
                <Typography variant="h6" gutterBottom>
                    Create a New Team
                </Typography>
                <TextField
                    label="New Team Name"
                    value={newTeamName}
                    onChange={handleTeamNameChange}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    error={!!error}
                    helperText={error}
                />
                <Autocomplete
                    options={guildMembers}
                    getOptionLabel={(option) => option.inGameName}
                    value={leader}
                    onChange={(event, newValue) => setLeader(newValue)}
                    renderInput={(params) => <TextField {...params} label="Select Team Leader" />}
                    sx={{ marginBottom: 2 }}
                />
                <Autocomplete
                    options={guildMembers}
                    getOptionLabel={(option) => option.inGameName}
                    value={secondLeader}
                    onChange={(event, newValue) => setSecondLeader(newValue)}
                    renderInput={(params) => <TextField {...params} label="Select Second Leader" />}
                    sx={{ marginBottom: 2 }}
                />
                <Button
                    variant="contained"
                    onClick={handleCreateButtonClick}
                    fullWidth
                    disabled={!!error}
                >
                    Create Team
                </Button>
            </Paper>
            <CreateTeamModal
                open={showCreateModal}
                onClose={() => setShowCreateModal(false)}
                onProceed={handleModalProceed}
                selectedGuild={selectedGuild || ''}
            />
        </>
    );
};

export default TeamForm;
