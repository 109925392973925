// Event.tsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  Grid2
} from '@mui/material';
import { collection, query, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';
import CreateEvent from './CreateEvent';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import AppTheme from '../../shared-theme/AppTheme';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './FullCalendarStyles.css'; // Import your custom CSS

export interface EventData {
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  description: string;
  createdBy: string;
  maxParticipants: number;
  registeredUsers: string[];
  dkpDistributed: boolean;
  dkpValue: number;
  assignedTeam?: string;
}

interface GuildMembership {
  guildId: string;
  role: string;
}

interface UserType {
  memberships?: GuildMembership[];
  uid?: string;
}

const Event: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [events, setEvents] = useState<EventData[]>([]);
  const { user, selectedGuild } = useUser();
  const [openCreateEvent, setOpenCreateEvent] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<EventData | null>(null);

  const canCreateEvents = (user: UserType | null) => {
    if (!user?.memberships) return false;
    const userGuild = user.memberships.find((m) => m.guildId === selectedGuild);
    return userGuild?.role === 'admin' || userGuild?.role === 'officer';
  };

  const handleDeleteClick = (eventId: string) => {
    setEventToDelete(eventId);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteEvent = async (eventId: string) => {
    if (!selectedGuild) return;
    const eventDocRef = doc(db, 'events', selectedGuild, 'guildEvents', eventId);
    await deleteDoc(eventDocRef);
  };

  const handleConfirmDelete = async () => {
    if (eventToDelete) {
      await handleDeleteEvent(eventToDelete);
      setDeleteConfirmOpen(false);
      setEventToDelete(null);
    }
  };

  useEffect(() => {
    if (!selectedGuild) return;

    const eventsCollectionRef = collection(db, 'events', selectedGuild, 'guildEvents');
    const q = query(eventsCollectionRef);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const eventsList: EventData[] = [];

      querySnapshot.forEach((doc) => {
        eventsList.push({ id: doc.id, ...doc.data() } as EventData);
      });

      // Sort events by startDate in ascending order (upcoming first)
      eventsList.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

      setEvents(eventsList);
    });

    return () => unsubscribe();
  }, [selectedGuild]);

  // Map events to FullCalendar format
  const calendarEvents = events.map((event) => ({
    id: event.id,
    title: event.title,
    start: event.startDate,
    end: event.endDate,
    extendedProps: {
      description: event.description,
      maxParticipants: event.maxParticipants,
      registeredUsers: event.registeredUsers,
      dkpDistributed: event.dkpDistributed,
      dkpValue: event.dkpValue,
      assignedTeam: event.assignedTeam,
    },
  }));

  const handleEventClick = (clickInfo: any) => {
    const eventId = clickInfo.event.id;
    const event = events.find((e) => e.id === eventId);
    if (event) {
      setSelectedEvent(event);
    }
  };

  return (
    <AppTheme>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          minHeight: '100vh',
          pt: 4,
        }}
      >
        <Grid2
          container
          spacing={3}
          sx={{
            backgroundColor: '#121212',
            color: '#E1E1E6',
            pt: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 3, md: 4 },
            mb: { xs: 2, sm: 4 },
            width: '100%',
            maxWidth: { xs: '100%', lg: 'lg' },
          }}
        >
          {/* Create Event Button */}
          {canCreateEvents(user) && (
            <Grid2 size={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant="contained"
                onClick={() => setOpenCreateEvent(true)}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  color: '#E1E1E6',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                }}
              >
                Create Event
              </Button>
            </Grid2>
          )}

          {/* Calendar */}
          <Grid2 size={12}>
            <Card
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                border: '1px solid rgba(255, 255, 255, 0.08)',
                borderRadius: 0,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    color: '#E1E1E6',
                  }}
                >
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={calendarEvents}
                    eventClick={handleEventClick}
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'dayGridMonth,dayGridWeek,dayGridDay',
                    }}
                    height="auto"
                    themeSystem="standard"
                    dayMaxEventRows={true}
                    eventTextColor="#E1E1E6"
                    eventBackgroundColor="rgba(255, 255, 255, 0.1)"
                    eventBorderColor="rgba(255, 255, 255, 0.2)"
                    datesSet={(dateInfo) => {
                      // Optional: Do something when date range changes
                    }}
                    slotLabelFormat={{
                      hour: 'numeric',
                      minute: '2-digit',
                      hour12: true,
                    }}
                    contentHeight="auto"
                    dayCellClassNames={() => 'custom-day-cell'}
                    // Customize event content
                    eventContent={(arg) => (
                      <Typography noWrap variant="body2">
                        {arg.event.title}
                      </Typography>
                    )}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>

        {/* Create Event Dialog */}
        <CreateEvent open={openCreateEvent} onClose={() => setOpenCreateEvent(false)} />

        {/* Event Details Dialog */}
        {selectedEvent && (
          <Dialog open={true} onClose={() => setSelectedEvent(null)} maxWidth="sm" fullWidth>
            <DialogTitle>{selectedEvent.title}</DialogTitle>
            <DialogContent>
              <Typography variant="body1" gutterBottom>
                {selectedEvent.description}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Start:{' '}
                {DateTime.fromISO(selectedEvent.startDate).toLocaleString(DateTime.DATETIME_FULL)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                End:{' '}
                {DateTime.fromISO(selectedEvent.endDate).toLocaleString(DateTime.DATETIME_FULL)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Spots: {selectedEvent.registeredUsers.length}/{selectedEvent.maxParticipants}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                DKP Value: {selectedEvent.dkpValue}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Status: {selectedEvent.dkpDistributed ? 'Completed' : 'Open'}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => navigate(`/event/${selectedEvent.id}`)}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  color: '#E1E1E6',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                }}
              >
                View Details
              </Button>
              {canCreateEvents(user) && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setSelectedEvent(null);
                    handleDeleteClick(selectedEvent.id);
                  }}
                >
                  Delete
                </Button>
              )}
              <Button onClick={() => setSelectedEvent(null)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Delete Confirmation Dialog */}
        <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this event? This action cannot be undone.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </AppTheme>
  );
};

export default Event;
