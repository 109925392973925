import React from 'react';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, useTheme, Grid, Card, CardContent, Tabs, Tab, Divider, Container
} from '@mui/material';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import useUser from '../hooks/useUser';

interface UserDKPData {
    uid: string;
    inGameName: string;
    dkp: number;
    username: string;
    primaryWeapon: string;
    gearScore: number;
    secondaryWeapon: string;
}

interface WeaponStats {
    [key: string]: number;
}

interface WeaponCombo {
    primary: string;
    secondary: string;
    count: number;
    percentage: number;
    averageGearScore: number;
}

const UserDKPList: React.FC = () => {
    const theme = useTheme();
    const [userDKPList, setUserDKPList] = React.useState<UserDKPData[]>([]);
    const { selectedGuild } = useUser();
    const [tabValue, setTabValue] = React.useState(0);
    const [weaponStats, setWeaponStats] = React.useState<WeaponStats>({});
    const [averageGearScore, setAverageGearScore] = React.useState<number>(0);
    const [topWeaponCombos, setTopWeaponCombos] = React.useState<WeaponCombo[]>([]);
    const comboGearScores: Record<string, number[]> = {};

    React.useEffect(() => {
        const fetchUserDKP = async () => {
            if (!selectedGuild) return;

            const userGuildsRef = collection(db, 'userGuilds');
            const q = query(userGuildsRef, where('guildId', '==', selectedGuild));
            const querySnapshot = await getDocs(q);

            const dkpData: UserDKPData[] = [];
            const weaponCounts: WeaponStats = {};
            const comboCounts: Record<string, number> = {};

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                dkpData.push({
                    uid: data.uid,
                    inGameName: data.inGameName || 'N/A',
                    dkp: data.dkp || 0,
                    username: data.username || 'N/A',
                    primaryWeapon: data.primaryWeapon || 'N/A',
                    secondaryWeapon: data.secondaryWeapon || 'N/A',
                    gearScore: data.gearScore || 0
                });

                if (data.primaryWeapon) {
                    weaponCounts[data.primaryWeapon] = (weaponCounts[data.primaryWeapon] || 0) + 1;
                }
                if (data.secondaryWeapon) {
                    weaponCounts[data.secondaryWeapon] = (weaponCounts[data.secondaryWeapon] || 0) + 1;
                }

                if (data.primaryWeapon && data.secondaryWeapon) {
                    const comboKey = `${data.primaryWeapon} + ${data.secondaryWeapon}`;
                    comboCounts[comboKey] = (comboCounts[comboKey] || 0) + 1;

                    if (!comboGearScores[comboKey]) {
                        comboGearScores[comboKey] = [];
                    }
                    comboGearScores[comboKey].push(data.gearScore || 0);
                }
            });

            const totalWeapons = Object.values(weaponCounts).reduce((a, b) => a + b, 0);
            Object.keys(weaponCounts).forEach(weapon => {
                weaponCounts[weapon] = Number(((weaponCounts[weapon] / totalWeapons) * 100).toFixed(1));
            });

            const sortedCombos = Object.entries(comboCounts)
                .map(([combo, count]) => {
                    const gearScores = comboGearScores[combo] || [];
                    const validGearScores = gearScores.filter(score => score > 0);
                    const averageGearScore = validGearScores.length > 0
                        ? Math.round(validGearScores.reduce((a, b) => a + b, 0) / validGearScores.length)
                        : 0;

                    return {
                        primary: combo.split(' + ')[0],
                        secondary: combo.split(' + ')[1],
                        count,
                        percentage: Number(((count / dkpData.length) * 100).toFixed(1)),
                        averageGearScore
                    };
                })
                .sort((a, b) => b.count - a.count)
                .slice(0, 5);

            const validGearScores = dkpData.filter(user => user.gearScore > 0);
            const totalGearScore = validGearScores.reduce((sum, user) => sum + user.gearScore, 0);
            const averageGS = validGearScores.length > 0
                ? Math.round(totalGearScore / validGearScores.length)
                : 0;

            dkpData.sort((a, b) => b.dkp - a.dkp);
            setUserDKPList(dkpData);
            setWeaponStats(weaponCounts);
            setAverageGearScore(averageGS);
            setTopWeaponCombos(sortedCombos);
        };

        fetchUserDKP();
    }, [selectedGuild]);
    const renderGuildStats = () => (
        <Box sx={{ p: 2, color: 'white' }}>
            <Typography variant="h6" sx={{ mb: 2, color: 'white' }}>Guild Statistics</Typography>

            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, color: 'white' }}>Weapon Type Distribution</Typography>
                {Object.entries(weaponStats)
                    .sort(([, a], [, b]) => b - a)
                    .map(([weapon, percentage]) => (
                        <Box key={weapon} sx={{ mb: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" sx={{ color: 'white' }}>{weapon}</Typography>
                                <Typography variant="body2" sx={{ color: 'white' }}>{percentage}%</Typography>
                            </Box>
                            <Box sx={{
                                width: '100%',
                                height: '4px',
                                bgcolor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: '2px',
                            }}>
                                <Box sx={{
                                    width: `${percentage}%`,
                                    height: '100%',
                                    bgcolor: 'rgba(255, 255, 255, 0.5)',
                                    borderRadius: '2px',
                                }} />
                            </Box>
                        </Box>
                    ))}
            </Box>

            <Divider sx={{ my: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />

            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 2, color: 'white' }}>Popular Weapon Combinations</Typography>
                {topWeaponCombos.map((combo, index) => (
                    <Box key={index} sx={{
                        mb: 2,
                        p: 2,
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        borderRadius: 1,
                        border: '1px solid rgba(255, 255, 255, 0.1)'
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
                                    #{index + 1} {combo.primary} + {combo.secondary}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                    {combo.count} members ({combo.percentage}%) • Average GS: {combo.averageGearScore}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>

            <Divider sx={{ my: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />

            <Box>
                <Typography variant="subtitle1" sx={{ mb: 1, color: 'white' }}>Combat Statistics</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography sx={{ color: 'white' }}>Average Gear Score:</Typography>
                    <Typography sx={{ color: 'white', fontWeight: 'bold' }}>{averageGearScore}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography sx={{ color: 'white' }}>Total Members:</Typography>
                    <Typography sx={{ color: 'white', fontWeight: 'bold' }}>{userDKPList.length}</Typography>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Card sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        }}>
            <CardContent>
                <Box sx={{ borderBottom: 1, borderColor: 'rgba(255, 255, 255, 0.1)', mb: 2 }}>
                    <Tabs
                        value={tabValue}
                        onChange={(_, newValue) => setTabValue(newValue)}
                        textColor="inherit"
                        sx={{
                            '& .MuiTab-root': { color: 'rgba(255, 255, 255, 0.7)' },
                            '& .Mui-selected': { color: 'white' },
                            '& .MuiTabs-indicator': { backgroundColor: 'white' }
                        }}
                    >
                        <Tab label="DKP Rankings" />
                        <Tab label="Guild Statistics" />
                    </Tabs>
                </Box>
    
                {tabValue === 0 ? (
                    <TableContainer
                        component={Paper}
                        sx={{
                            backgroundColor: 'transparent',
                            maxHeight: '400px',
                            overflow: 'auto',
                            '& .MuiTableCell-root': {
                                color: 'white',
                                borderColor: 'rgba(255, 255, 255, 0.1)'
                            },
                            '& .MuiTableRow-root:nth-of-type(odd)': {
                                backgroundColor: 'rgba(255, 255, 255, 0.05)'
                            },
                            '&::-webkit-scrollbar': {
                                width: '8px',
                                height: '8px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            }
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Rank</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>In-Game Name</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Weapons</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="right">Gear Score</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="right">DKP</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userDKPList.map((user, index) => (
                                    <TableRow
                                        key={user.uid}
                                        sx={{
                                            '&:nth-of-type(odd)': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.05)'
                                            },
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.1)'
                                            }
                                        }}
                                    >
                                        <TableCell sx={{ color: 'white' }}>{index + 1}</TableCell>
                                        <TableCell sx={{ color: 'white' }}>{user.inGameName}</TableCell>
                                        <TableCell sx={{ color: 'white' }}>
                                            {user.primaryWeapon} {user.secondaryWeapon && `+ ${user.secondaryWeapon}`}
                                        </TableCell>
                                        <TableCell sx={{ color: 'white' }} align="right">{user.gearScore}</TableCell>
                                        <TableCell sx={{ color: 'white' }} align="right">{Math.ceil(user.dkp)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : renderGuildStats()}
            </CardContent>
        </Card>
    );
    
};

export default UserDKPList;
