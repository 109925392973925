import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TutorialPopover from '../../common/TutorialPopover';

interface LootMasterPanelProps {
  selectedGameMode: "Throne and Liberty" | "Custom";
  searchTerm: string;
  filteredItems: any[];
  onGameModeChange: (mode: "Throne and Liberty" | "Custom") => void;
  onSearchChange: (term: string) => void;
  onAddItem: (item: any) => void;
  isLootmasterOrAdmin: boolean;
}

export const LootMasterPanel: React.FC<LootMasterPanelProps> = ({
  selectedGameMode,
  searchTerm,
  filteredItems,
  onGameModeChange,
  onSearchChange,
  onAddItem,
  isLootmasterOrAdmin
}) => {
  const theme = useTheme();

  const handleCustomItemClick = () => {
    if (isLootmasterOrAdmin) {
      onGameModeChange("Custom");
      onAddItem({
        name: "",
        itemName: "",
        itemImage: "/images/new/legendarysword.png",
        isCustom: true
      });
    }
  };

  const handleItemClick = (item: any) => {
    if (isLootmasterOrAdmin) {
      onAddItem({
        name: item.name,
        itemName: item.name,
        itemImage: `/images/new/${item.imageFilename}`,
        url: item.url,
        isCustom: false
      });
    }
  };

  const sortedFilteredItems = [...filteredItems].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box sx={{ bgcolor: theme.palette.background.paper, borderRadius: 2, p: 4, boxShadow: 3, mb: 3, minHeight: '250px' }}>
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            variant="contained"
            sx={{ fontWeight: 'bold', px: 3 }}
            onClick={() => onGameModeChange("Throne and Liberty")}
          >
            Throne and Liberty
          </Button>
          <TutorialPopover
            title="Throne and Liberty Items"
            steps={[{
              title: "Access the official game item database. These items include pre-configured properties and links to the official database"
            }]}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            variant="contained"
            sx={{ fontWeight: 'bold', px: 3 }}
            onClick={handleCustomItemClick}
          >
            Custom
          </Button>
          <TutorialPopover
            title="Custom Items"
            steps={[{
              title: "Create your own custom items when the item you need isn't in the official database. You can set custom names and properties"
            }]}
          />
        </Box>
      </Box>

      <Box sx={{
        maxHeight: '300px',
        overflowY: 'scroll',
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: 1,
        p: 2,
        bgcolor: theme.palette.background.default,
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <input
            type="text"
            placeholder="Search for items..."
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
            style={{
              width: '100%',
              padding: '8px',
              marginBottom: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
          />
        </Box>

        {sortedFilteredItems.map((item, index) => (
          <Box
            key={`${item.name}-${index}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 1,
              mb: 2,
              bgcolor: theme.palette.background.paper,
              borderRadius: 1,
              boxShadow: 1,
            }}
          >
            <Box
              component="img"
              src={`/images/new/${item.imageFilename}`}
              alt={item.name}
              sx={{ width: 50, height: 50, borderRadius: 1, mr: 2 }}
            />
            <Box sx={{ flex: 1, mr: 2 }}>
              <Typography
                component="a"
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
                sx={{
                  fontWeight: 'bold',
                  color: 'primary.main',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' }
                }}
              >
                {item.name}
              </Typography>
            </Box>
            {isLootmasterOrAdmin && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={() => handleItemClick(item)}
                  sx={{
                    whiteSpace: 'nowrap',
                    padding: '4px 8px',
                    fontSize: '0.875rem',
                    color: theme.palette.primary.main,
                  }}
                >
                  Add to Drop Table
                </Button>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
