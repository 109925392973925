import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { Timestamp } from 'firebase/firestore';

interface SessionTimerProps {
  expiresAt: Date | Timestamp | null;
  onExpire?: () => void;
}

const SessionTimer: React.FC<SessionTimerProps> = ({ expiresAt, onExpire }) => {
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [isActive, setIsActive] = useState(true);

  const formatAbsoluteTime = (date: Date) => {
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    });
  };

  const getExpirationDate = () => {
    if (!expiresAt) return null;
    return expiresAt instanceof Timestamp ? expiresAt.toDate() : expiresAt;
  };

  useEffect(() => {
    if (!expiresAt || (expiresAt instanceof Timestamp && expiresAt.seconds === 0)) {
      setTimeLeft('N/A');
      return;
    }

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const expireTime = expiresAt instanceof Timestamp ?
        expiresAt.toDate().getTime() :
        expiresAt.getTime();
      const distance = expireTime - now;

      if (distance <= 0) {
        clearInterval(timer);
        setTimeLeft('Expired');
        setIsActive(false);
        onExpire?.();
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }, 1000);

    return () => clearInterval(timer);
  }, [expiresAt, onExpire]);

  if (!expiresAt || (expiresAt instanceof Timestamp && expiresAt.seconds === 0)) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="body2">N/A</Typography>
      </Box>
    );
  }

  const expirationDate = getExpirationDate();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="body2" color={isActive ? 'primary' : 'error'}>
          {timeLeft}
        </Typography>
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: isActive ? 'success.main' : 'error.main'
          }}
        />
      </Box>
      {expirationDate && (
        <Typography variant="caption" color="textSecondary">
          {isActive ? 'Ends' : 'Ended'} on {formatAbsoluteTime(expirationDate)}
        </Typography>
      )}
    </Box>
  );
};

export default SessionTimer;
