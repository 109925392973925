import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';
import { Box, TextField, useTheme, Typography, Divider } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { checkInactivity, fetchLastDKPDates } from '../../utils/inactivityCheck';
import { Button } from '@mui/material';
import { doc, updateDoc, arrayRemove } from 'firebase/firestore';


interface GuildMember {
    id: string;
    inGameName: string;
    role: string;
    dkp: number;
    lastDKPDate: Date | null;
    teamName?: string;
    isInactive: boolean;
    primaryWeapon?: string;
    secondaryWeapon?: string;
    gearScore?: number;
    uid: string;
}

interface WeaponStats {
    [key: string]: number;
}

interface WeaponCombo {
    primary: string;
    secondary: string;
    count: number;
    percentage: number;
    averageGearScore: number;
}

const UserManagementTab: React.FC = () => {
    const { selectedGuild } = useUser();
    const theme = useTheme();
    const [guildMembers, setGuildMembers] = useState<GuildMember[]>([]);
    const [inactivityThreshold, setInactivityThreshold] = useState(() => {
        const saved = localStorage.getItem('inactivityThreshold');
        return saved ? parseInt(saved) : 7;
    });
    const [sortField, setSortField] = useState<keyof GuildMember | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [weaponStats, setWeaponStats] = useState<WeaponStats>({});
    const [averageGearScore, setAverageGearScore] = useState<number>(0);
    const [topWeaponCombos, setTopWeaponCombos] = useState<WeaponCombo[]>([]);

    const handleRemoveFromTeam = async (member: GuildMember) => {
        if (!selectedGuild || !member.teamName) return;
    
        try {
            const teamRef = doc(db, 'teams', selectedGuild, 'guildTeams', member.teamName);
            await updateDoc(teamRef, {
                members: arrayRemove(member.inGameName)
            });
    
            // Update local state
            const updatedMembers = guildMembers.filter(m => m.id !== member.id);
            setGuildMembers(updatedMembers);
        } catch (error) {
            console.error('Error removing member from team:', error);
        }
    };
    

    useEffect(() => {
        localStorage.setItem('inactivityThreshold', inactivityThreshold.toString());
    }, [inactivityThreshold]);

    const handleSort = (field: keyof GuildMember) => {
        const isAsc = sortField === field && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortField(field);

        const sortedMembers = [...guildMembers].sort((a, b) => {
            const aValue = a[field];
            const bValue = b[field];
            return isAsc
                ? String(bValue).localeCompare(String(aValue))
                : String(aValue).localeCompare(String(bValue));
        });

        setGuildMembers(sortedMembers);
    };

    useEffect(() => {
        const fetchGuildMembers = async () => {
            if (!selectedGuild) return;
    
            const membersQuery = query(
                collection(db, 'userGuilds'),
                where('guildId', '==', selectedGuild),
                where('status', '==', 'approved')
            );
            const querySnapshot = await getDocs(membersQuery);
    
            const usernameToInGameNameMap: { [key: string]: string } = {};
            querySnapshot.forEach((docSnap) => {
                const data = docSnap.data();
                const username = data.username;
                const inGameName = data.inGameName;
                if (username && inGameName) {
                    usernameToInGameNameMap[username.toLowerCase()] = inGameName;
                }
            });
    
            const dkpMap = await fetchLastDKPDates(db, selectedGuild, usernameToInGameNameMap);
    
            const teamsCollection = collection(db, 'teams', selectedGuild, 'guildTeams');
            const teamsSnapshot = await getDocs(teamsCollection);
            const teamMap: { [key: string]: string } = {};
    
            // First pass to collect all team leaders and members
            teamsSnapshot.forEach((teamDoc) => {
                const teamData = teamDoc.data();
                const teamName = teamDoc.id;
                const members = teamData.members || [];
                const leader = teamData.leader;
                const secondLeader = teamData.secondLeader;
    
                // Assign teamName to each member in the team
                members.forEach((memberName: string) => {
                    teamMap[memberName] = teamName;
                });
            });
    
            const weaponCounts: WeaponStats = {};
            const comboCounts: Record<string, number> = {};
            const comboGearScores: Record<string, number[]> = {};
    
            const membersData = querySnapshot.docs.map((docSnap) => {
                const data = docSnap.data();
                const memberId = docSnap.id;
                const inGameName = data.inGameName || 'Unknown';
                const lastDKPDate = dkpMap[inGameName] || null;
                const primaryWeapon = data.primaryWeapon;
                const secondaryWeapon = data.secondaryWeapon;
                const gearScore = data.gearScore || 0;
    
                return {
                    id: memberId,
                    uid: data.uid || memberId,
                    inGameName,
                    role: data.role || 'Member',
                    dkp: data.dkp || 0,
                    lastDKPDate,
                    teamName: teamMap[inGameName] || '',
                    isInactive: checkInactivity(lastDKPDate, inactivityThreshold),
                    primaryWeapon,
                    secondaryWeapon,
                    gearScore
                };
            });
    
            // Filter team members first
            const teamMembers = membersData.filter(member => member.teamName);
    
            // Calculate weapon statistics for team members only
            teamMembers.forEach(member => {
                if (member.primaryWeapon) {
                    weaponCounts[member.primaryWeapon] = (weaponCounts[member.primaryWeapon] || 0) + 1;
                }
                if (member.secondaryWeapon) {
                    weaponCounts[member.secondaryWeapon] = (weaponCounts[member.secondaryWeapon] || 0) + 1;
                }
    
                if (member.primaryWeapon && member.secondaryWeapon) {
                    const comboKey = `${member.primaryWeapon} + ${member.secondaryWeapon}`;
                    comboCounts[comboKey] = (comboCounts[comboKey] || 0) + 1;
                    if (!comboGearScores[comboKey]) {
                        comboGearScores[comboKey] = [];
                    }
                    comboGearScores[comboKey].push(member.gearScore || 0);
                }
            });
    
            // Calculate percentages based on team members
            const totalWeapons = Object.values(weaponCounts).reduce((a, b) => a + b, 0);
            Object.keys(weaponCounts).forEach(weapon => {
                weaponCounts[weapon] = Number(((weaponCounts[weapon] / totalWeapons) * 100).toFixed(1));
            });
    
            const sortedCombos = Object.entries(comboCounts)
                .map(([combo, count]) => {
                    const gearScores = comboGearScores[combo] || [];
                    const validGearScores = gearScores.filter(score => score > 0);
                    const averageGearScore = validGearScores.length > 0
                        ? Math.round(validGearScores.reduce((a, b) => a + b, 0) / validGearScores.length)
                        : 0;
    
                    return {
                        primary: combo.split(' + ')[0],
                        secondary: combo.split(' + ')[1],
                        count,
                        percentage: Number(((count / teamMembers.length) * 100).toFixed(1)),
                        averageGearScore
                    };
                })
                .sort((a, b) => b.count - a.count)
                .slice(0, 5);
    
            const validGearScores = teamMembers.filter(member => member.gearScore && member.gearScore > 0);
            const averageGS = validGearScores.length > 0
                ? Math.round(validGearScores.reduce((sum, member) => sum + (member.gearScore || 0), 0) / validGearScores.length)
                : 0;
    
            setWeaponStats(weaponCounts);
            setAverageGearScore(averageGS);
            setTopWeaponCombos(sortedCombos);
            setGuildMembers(teamMembers);
        };
    
        fetchGuildMembers();
    }, [selectedGuild, inactivityThreshold]);
    

    const renderTeamStats = () => (
        <Box sx={{ p: 2, backgroundColor: '#404040', borderRadius: 1, mt: 2 }}>
            <Typography variant="h6" sx={{ mb: 2, color: 'white' }}>Team Statistics</Typography>

            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, color: 'white' }}>Weapon Type Distribution</Typography>
                {Object.entries(weaponStats)
                    .sort(([, a], [, b]) => b - a)
                    .map(([weapon, percentage]) => (
                        <Box key={weapon} sx={{ mb: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" sx={{ color: 'white' }}>{weapon}</Typography>
                                <Typography variant="body2" sx={{ color: 'white' }}>{percentage}%</Typography>
                            </Box>
                            <Box sx={{
                                width: '100%',
                                height: '4px',
                                bgcolor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: '2px',
                            }}>
                                <Box sx={{
                                    width: `${percentage}%`,
                                    height: '100%',
                                    bgcolor: theme.palette.primary.main,
                                    borderRadius: '2px',
                                }} />
                            </Box>
                        </Box>
                    ))}
            </Box>

            <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.1)' }} />

            <Box sx={{ mb: 4 }}>
                <Typography variant="subtitle1" sx={{ mb: 2, color: 'white' }}>Popular Weapon Combinations</Typography>
                {topWeaponCombos.map((combo, index) => (
                    <Box key={index} sx={{
                        mb: 2,
                        p: 2,
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        borderRadius: 1,
                        border: '1px solid rgba(255, 255, 255, 0.1)'
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
                                    #{index + 1} {combo.primary} + {combo.secondary}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                    {combo.count} members ({combo.percentage}%) • Average GS: {combo.averageGearScore}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>

            <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.1)' }} />

            <Box>
                <Typography variant="subtitle1" sx={{ mb: 1, color: 'white' }}>Combat Statistics</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography sx={{ color: 'white' }}>Average Gear Score:</Typography>
                    <Typography sx={{ color: 'white', fontWeight: 'bold' }}>{averageGearScore}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography sx={{ color: 'white' }}>Total Members:</Typography>
                    <Typography sx={{ color: 'white', fontWeight: 'bold' }}>{guildMembers.length}</Typography>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box sx={{ backgroundColor: '#303030', padding: 2, borderRadius: 1 }}>
            <TextField
                label="Inactivity Threshold (Days)"
                type="number"
                value={inactivityThreshold}
                onChange={(e) => setInactivityThreshold(Number(e.target.value))}
                sx={{
                    marginBottom: 2,
                    '& .MuiOutlinedInput-root': {
                        color: 'white',
                    },
                    '& .MuiInputLabel-root': {
                        color: 'white',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                }}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell onClick={() => handleSort('inGameName')} sx={{ cursor: 'pointer' }}>
                                In-Game Name {sortField === 'inGameName' && (
                                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                                )}
                            </TableCell>
                            <TableCell onClick={() => handleSort('primaryWeapon')} sx={{ cursor: 'pointer' }}>
                                Primary Weapon {sortField === 'primaryWeapon' && (
                                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                                )}
                            </TableCell>
                            <TableCell onClick={() => handleSort('secondaryWeapon')} sx={{ cursor: 'pointer' }}>
                                Secondary Weapon {sortField === 'secondaryWeapon' && (
                                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                                )}
                            </TableCell>
                            <TableCell onClick={() => handleSort('dkp')} sx={{ cursor: 'pointer' }}>
                                DKP {sortField === 'dkp' && (
                                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                                )}
                            </TableCell>
                            <TableCell onClick={() => handleSort('lastDKPDate')} sx={{ cursor: 'pointer' }}>
                                Last DKP Awarded {sortField === 'lastDKPDate' && (
                                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                                )}
                            </TableCell>
                            <TableCell onClick={() => handleSort('isInactive')} sx={{ cursor: 'pointer' }}>
                                Status {sortField === 'isInactive' && (
                                    <span>{sortDirection === 'asc' ? '↑' : '↓'}</span>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {guildMembers.map((member) => (
                            <TableRow key={member.id}>
                                <TableCell>{member.inGameName}</TableCell>
                                <TableCell>{member.primaryWeapon || 'None'}</TableCell>
                                <TableCell>{member.secondaryWeapon || 'None'}</TableCell>
                                <TableCell>{member.dkp}</TableCell>
                                <TableCell>
                                    {member.lastDKPDate ? new Date(member.lastDKPDate).toLocaleDateString() : 'Never'}
                                </TableCell>
                                <TableCell>
                                    {member.isInactive ? 'Inactive' : 'Active'}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        size="small"
                                        onClick={() => handleRemoveFromTeam(member)}
                                    >
                                        Remove from Team
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            {renderTeamStats()}
        </Box>
    );
};

export default UserManagementTab;
