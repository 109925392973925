import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/system';

const testimonials = [
  {
    avatar: <Avatar alt="Mr Isp" src="/images/newtheme/testimonial/mr_isp_pfp.jpg" />,
    name: 'Mr Isp',
    occupation: 'Vice and Virtue',
    preview: "My guild 'Vice and Virtue' has been using Loot Manager and it has completely transformed our loot distribution process. Very easy to use, quick setup, and excellent Discord integration.",
    fullTestimonial: [
      "My guild 'Vice and Virtue' has been using Loot Manager at this stage for a few weeks now and it has completely transformed our loot distribution process.",
      "Very easy to use. Set up takes no time at all (even for those who aren't tech-savvy.)",
      "Discord app / webhook helps the guild track point allocation.",
      "The app not only streamlines loot allocation but also makes sure our most active members are rewarded fairly.",
      "The developer's dedication is second to none and really sets the app apart from others of its kind.",
      "Quick response to any questions and swift in bug fixes, which is rare compared to other apps.",
      "The attention to detail and development aswell as the ongoing support truly goes above and beyond.",
      "If you're looking for a reliable DKP system that makes guild management a breeze, this is the one!"
    ],
    banner: '/images/newtheme/testimonial/mr_isp_banner.jpg'
  },
  {
    avatar: <Avatar alt="Marshadowo" src="/images/newtheme/testimonial/marsh_pfp.jpg" />,
    name: 'Marshadowo',
    occupation: 'The House Stark',
    preview: "Our guild, The House Stark, was searching for a reliable tool to manage loot distribution during raids, and that's where Loot Manager came in. It has exceeded our expectations, becoming an invaluable resource for raids, PvP, and more.",
    fullTestimonial: [
      "Our guild, The House Stark, was searching for a reliable tool to manage loot distribution during raids, and that's where Loot Manager came in. It has exceeded our expectations, becoming an invaluable resource not just for our raids but also for organizing other events like PvP , Riftstone/Boonstone activities, Siege coordination as well as tracking member contributions.",
      "The inclusion of a DKP system ensures that both casual and hardcore guilds can seamlessly adapt to the experience, all for free. Setting up Loot Manager was effortless, and any adjustments or new feature integrations have only enhanced its value.",
      "The tool receives frequent updates, and the developers provide outstanding support, ensuring it remains accessible and in line with the needs of its users.",
      "Previously, new recruits added to the workload of our ranking members, who had to juggle gear wishlists, loot tracking, and activity monitoring. With Loot Manager, those challenges are a thing of the past.",
      "The app simplifies these tasks with just a few clicks, improving the experience for everyone involved. The Discord integration has been especially helpful, creating a seamless, user-friendly environment for loot management and instant notifications.",
      "As someone who has used various online gaming tools over the years, I can confidently say that Loot Manager stands out as one of the best. Just a few minutes of setup can save countless hours of organizing, making it a must-have for any growing or established guild looking to operate efficiently and boost member satisfaction."
    ],
    banner: 'images/newtheme/testimonial/marsh_banner.jpg'
  }
];

export default function Testimonials() {
  const theme = useTheme();
  const [expandedIndex, setExpandedIndex] = React.useState<number | null>(null);

  return (
    <Container id="testimonials" sx={{ py: { xs: 4, sm: 12 } }}>
      <Typography component="h2" variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        What Guild Leaders Say
      </Typography>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {testimonials.map((testimonial, index) => (
          <Card key={index} sx={{ maxWidth: 800, mx: 'auto' }}>
            <Box sx={{ position: 'relative', height: 200 }}>
              <Box
                component="img"
                src={testimonial.banner}
                alt="Guild Banner"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </Box>
            
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {testimonial.avatar}
                <Box sx={{ ml: 2 }}>
                  <Typography variant="h6">{testimonial.name}</Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {testimonial.occupation}
                  </Typography>
                </Box>
              </Box>

              {expandedIndex === index ? (
                <Box>
                  {testimonial.fullTestimonial.map((paragraph, pIndex) => (
                    <Typography key={pIndex} paragraph color="text.secondary">
                      {paragraph}
                    </Typography>
                  ))}
                  <Button onClick={() => setExpandedIndex(null)} sx={{ mt: 1 }}>
                    Show Less
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Typography color="text.secondary">
                    {testimonial.preview}
                  </Typography>
                  <Button onClick={() => setExpandedIndex(index)} sx={{ mt: 1 }}>
                    Read More
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
  );
}
