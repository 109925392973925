import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Button,
  Card,
  CircularProgress,
  Alert,
  Stack,
  useTheme,
} from "@mui/material";
import AppTheme from "../shared-theme/AppTheme";

const SelectGuild: React.FC = () => {
  const { user, setSelectedGuild } = useUser();
  const navigate = useNavigate();
  const [guilds, setGuilds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const theme = useTheme();

  useEffect(() => {
    const fetchGuilds = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      const approvedGuilds = user.memberships
        .filter((m) => m.status === "approved")
        .map((m) => m.guildId);

      setGuilds(approvedGuilds);
      setLoading(false);
    };

    fetchGuilds();
  }, [user, navigate]);

  const handleSelectGuild = (guildId: string) => {
    setSelectedGuild(guildId);
    navigate('/dashboard');
  };

  if (loading) {
    return (
      <AppTheme>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          sx={{
            backgroundColor: "#121212",
            color: "#E1E1E6",
          }}
        >
          <CircularProgress />
        </Box>
      </AppTheme>
    );
  }

  if (guilds.length === 0) {
    return (
      <AppTheme>
        <Container
          maxWidth="sm"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            pt: 4,
          }}
        >
          <Card
            sx={{
              p: 4,
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              borderRadius: 0,
              width: '100%',
              maxWidth: '500px',
            }}
          >
            <Stack spacing={2}>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                align="center"
                sx={{ fontWeight: 'bold', color: "#E1E1E6" }}
              >
                No Approved Guilds Found
              </Typography>
              <Alert
                severity="info"
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  color: "#E1E1E6",
                  borderRadius: 0,
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                }}
              >
                Your request to join a guild is pending approval by an administrator of this guild.
              </Alert>
              <Typography variant="body1" sx={{ color: "#E1E1E6" }}>
                If you wish to create a new guild, please logout and follow the instructions on the login page to create a guild.
              </Typography>
              <Typography variant="body2" sx={{ color: "rgba(225,225,230,0.8)" }}>
                For further assistance, please contact an admin.
              </Typography>
            </Stack>
          </Card>
        </Container>
      </AppTheme>
    );
  }

  return (
    <AppTheme>
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          pt: 4,
        }}
      >
        <Card
          sx={{
            p: 4,
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            backdropFilter: "blur(10px)",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            borderRadius: 0,
            width: '100%',
            maxWidth: '500px',
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            align="center"
            sx={{ fontWeight: 'bold', color: "#E1E1E6" }}
          >
            Select Your Guild
          </Typography>
          <List>
            {guilds.map((guildId) => (
              <ListItem key={guildId} sx={{ justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={() => handleSelectGuild(guildId)}
                  fullWidth
                  sx={{
                    py: 1.5,
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    color: "#E1E1E6",
                    '&:hover': {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                    },
                  }}
                >
                  {guildId}
                </Button>
              </ListItem>
            ))}
          </List>
        </Card>
      </Container>
    </AppTheme>
  );
};

export default SelectGuild;
