// CreateEvent.tsx
import React, { useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from '@mui/material';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  getDocs,
  getDoc,
  updateDoc,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TutorialPopover from '../common/TutorialPopover';
import CreateEventModal from './CreateEventModal';

interface CreateEventProps {
  open: boolean;
  onClose: () => void;
}

const recurrenceOptions = [
  { value: 'none', label: 'None' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

const CreateEvent: React.FC<CreateEventProps> = ({ open, onClose }) => {
  const { user, selectedGuild } = useUser();
  const [availableTeams, setAvailableTeams] = React.useState<{ name: string }[]>([]);
  const [eventData, setEventData] = React.useState({
    title: '',
    startDate: DateTime.now(),
    endDate: DateTime.now(),
    description: '',
    maxParticipants: 70,
    dkpValue: 0,
    allowLateRegistration: false,
    assignedTeams: [] as string[],
    recurrence: 'none',
    recurrenceCount: 1,
  });
  const [showCreateModal, setShowCreateModal] = React.useState(false);

  useEffect(() => {
    if (!selectedGuild) return;
    const fetchTeams = async () => {
      const teamsRef = collection(db, 'teams', selectedGuild, 'guildTeams');
      const snapshot = await getDocs(teamsRef);
      setAvailableTeams(snapshot.docs.map((doc) => ({ name: doc.id })));
    };
    fetchTeams();
  }, [selectedGuild]);

  const handleModalProceed = () => {
    setShowCreateModal(false);
    handleSubmit();
  };

  const handleSubmit = async () => {
    if (!selectedGuild) return;

    // Limit the recurrence count to a maximum of 10
    if (eventData.recurrenceCount > 10) {
      alert('The number of occurrences cannot exceed 10.');
      return;
    }

    const eventsCollectionRef = collection(db, 'events');
    const guildDocRef = doc(eventsCollectionRef, selectedGuild);
    const guildEventsRef = collection(guildDocRef, 'guildEvents');

    let eventsToCreate = [eventData];

    // Handle recurrence
    if (eventData.recurrence !== 'none' && eventData.recurrenceCount > 1) {
      eventsToCreate = [];
      let currentStartDate = eventData.startDate;
      let currentEndDate = eventData.endDate;
      for (let i = 0; i < eventData.recurrenceCount; i++) {
        eventsToCreate.push({
          ...eventData,
          startDate: currentStartDate,
          endDate: currentEndDate,
        });
        switch (eventData.recurrence) {
          case 'daily':
            currentStartDate = currentStartDate.plus({ days: 1 });
            currentEndDate = currentEndDate.plus({ days: 1 });
            break;
          case 'weekly':
            currentStartDate = currentStartDate.plus({ weeks: 1 });
            currentEndDate = currentEndDate.plus({ weeks: 1 });
            break;
          case 'monthly':
            currentStartDate = currentStartDate.plus({ months: 1 });
            currentEndDate = currentEndDate.plus({ months: 1 });
            break;
        }
      }
    }

    for (const event of eventsToCreate) {
      const newEventDocRef = await addDoc(guildEventsRef, {
        ...event,
        createdBy: user?.uid,
        guildId: selectedGuild,
        registeredUsers: [],
        createdAt: serverTimestamp(),
        startDate: event.startDate.toISO(),
        endDate: event.endDate.toISO(),
        isCompleted: false,
        dkpDistributed: false,
      });

      // If assignedTeams is set, fetch team members and add them to registeredUsers
      if (event.assignedTeams && event.assignedTeams.length > 0) {
        const teamMembersSet = new Set<string>();

        for (const teamName of event.assignedTeams) {
          const teamRef = doc(db, 'teams', selectedGuild, 'guildTeams', teamName);
          const teamDoc = await getDoc(teamRef);
          const teamData = teamDoc.data();

          if (teamData) {
            const members = teamData.members || [];
            if (teamData.leader && !members.includes(teamData.leader)) {
              members.push(teamData.leader);
            }

            if (members.length > 0) {
              const userGuildsRef = collection(db, 'userGuilds');
              const q = query(
                userGuildsRef,
                where('guildId', '==', selectedGuild),
                where('inGameName', 'in', members),
              );

              const querySnapshot = await getDocs(q);
              const userIds = querySnapshot.docs.map((doc) => doc.data().uid);

              userIds.forEach((uid) => teamMembersSet.add(uid));
            }
          }
        }

        await updateDoc(newEventDocRef, {
          registeredUsers: Array.from(teamMembersSet),
        });
      }
    }

    onClose();
  };

  const validateForm = () => {
    if (eventData.title.trim() === '') {
      alert('Event title cannot be empty.');
      return false;
    }
    // Additional validations if needed
    return true;
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Create New Event</DialogTitle>
        <form>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {/* Event Title */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TextField
                  label="Event Title"
                  required
                  value={eventData.title}
                  onChange={(e) => setEventData({ ...eventData, title: e.target.value })}
                  fullWidth
                />
                <TutorialPopover
                  title="Event Title"
                  steps={[{
                    title: "Enter a descriptive name for your event that participants can easily recognize, like 'Thursday Night Raid' or 'Weekend Dungeon Run'"
                  }]}
                />
              </Box>

              {/* Event Timing */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DateTimePicker
                    label="Start Date"
                    value={eventData.startDate}
                    onChange={(newValue) => {
                      setEventData({ ...eventData, startDate: newValue || DateTime.now() });
                    }}
                  />
                  <DateTimePicker
                    label="End Date"
                    value={eventData.endDate}
                    onChange={(newValue) => {
                      setEventData({ ...eventData, endDate: newValue || DateTime.now() });
                    }}
                  />
                </LocalizationProvider>
                <TutorialPopover
                  title="Event Timing"
                  steps={[{
                    title: "Set when your event starts and ends. This helps participants plan their attendance and determines when DKP will be distributed"
                  }]}
                />
              </Box>

              {/* Recurrence */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FormControl fullWidth>
                  <InputLabel>Recurrence</InputLabel>
                  <Select
                    value={eventData.recurrence}
                    onChange={(e) => setEventData({ ...eventData, recurrence: e.target.value })}
                    label="Recurrence"
                  >
                    {recurrenceOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TutorialPopover
                  title="Event Recurrence"
                  steps={[{
                    title: "Schedule repeating events daily, weekly, or monthly. Perfect for regular raid schedules or recurring guild activities"
                  }]}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FormControl fullWidth>
                  <InputLabel>Recurrence</InputLabel>
                  <Select
                    value={eventData.recurrence}
                    onChange={(e) => setEventData({ ...eventData, recurrence: e.target.value })}
                    label="Recurrence"
                  >
                    {recurrenceOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Recurrence Count"
                  type="number"
                  value={eventData.recurrenceCount}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (value <= 10) {
                      setEventData({ ...eventData, recurrenceCount: value });
                    }
                  }}
                  helperText="Number of times the event should recur (max 10)"
                  fullWidth
                />
                <TutorialPopover
                  title="Event Recurrence"
                  steps={[{
                    title: "Schedule repeating events daily, weekly, or monthly. Perfect for regular raid schedules or recurring guild activities"
                  }]}
                />
              </Box>

              {/* Assign Teams */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FormControl fullWidth>
                  <InputLabel>Assign Teams</InputLabel>
                  <Select
                    multiple
                    value={eventData.assignedTeams}
                    onChange={(e) => setEventData({ ...eventData, assignedTeams: e.target.value as string[] })}
                    label="Assign Teams"
                    renderValue={(selected) => (selected as string[]).join(', ')}
                  >
                    {availableTeams.map((team) => (
                      <MenuItem key={team.name} value={team.name}>
                        {team.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TutorialPopover
                  title="Team Assignment"
                  steps={[{
                    title: "Select teams to auto-register their members for this event. Team members will be automatically added to the participant list"
                  }]}
                />
              </Box>

              {/* Description */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TextField
                  label="Description"
                  value={eventData.description}
                  onChange={(e) => setEventData({ ...eventData, description: e.target.value })}
                  fullWidth
                  variant="outlined" // Ensure the variant is set
                  InputLabelProps={{
                    shrink: true, // Ensure the label shrinks when there is text
                  }}
                />
                <TutorialPopover
                  title="Event Description"
                  steps={[{
                    title: "Provide details about the event including requirements, objectives, and any special instructions for participants"
                  }]}
                />
              </Box>


              {/* DKP Value */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TextField
                  label="DKP Value"
                  type="number"
                  required
                  value={eventData.dkpValue}
                  onChange={(e) => setEventData({ ...eventData, dkpValue: parseInt(e.target.value) })}
                  helperText="DKP to be awarded upon event completion"
                  fullWidth
                />
                <TutorialPopover
                  title="DKP Reward"
                  steps={[{
                    title: "Set how many DKP points participants will receive for completing this event. This value will be automatically distributed when the event is marked as completed"
                  }]}
                />
              </Box>

              {/* Max Participants */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TextField
                  label="Max Participants"
                  type="number"
                  required
                  value={eventData.maxParticipants}
                  onChange={(e) => setEventData({ ...eventData, maxParticipants: parseInt(e.target.value) })}
                  onFocus={(e) => e.target.select()}
                  fullWidth
                />

                <TutorialPopover
                  title="Maximum Participants"
                  steps={[{
                    title: "Set the maximum number of participants that can register for this event"
                  }]}
                />
              </Box>

              {/* Allow Late Registration */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={eventData.allowLateRegistration}
                      onChange={(e) => setEventData({
                        ...eventData,
                        allowLateRegistration: e.target.checked,
                      })}
                    />
                  }
                  label="Allow Late Registration with Verification"
                />
                <TutorialPopover
                  title="Late Registration"
                  steps={[{
                    title: "Enable this to allow players to register after the event starts. Late registrations will require approval before DKP is awarded"
                  }]}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                if (validateForm()) {
                  setShowCreateModal(true);
                }
              }}
            >
              Create Event
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <CreateEventModal
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onProceed={handleModalProceed}
        selectedGuild={selectedGuild || ''}
      />
    </>
  );
};

export default CreateEvent;
