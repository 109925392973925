// src/components/Wishlist/Wishlist.tsx

import React, { useState } from "react";
import { Box, Container, Typography, Button, Grid2, Paper } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import itemsDataNew from "../../items.json";
import { WishlistPanel } from "./WishlistPanel";
import {
  fetchTopWishlistedItems,
  lockAllWishlists,
  unlockAllWishlists,
  resetWishlistTokens,
} from "./WishListServices";
import useUser from "../../hooks/useUser";
import { ItemType } from "../../types/Wishlist";
import AppTheme from '../../shared-theme/AppTheme';

const adaptedItemsData: ItemType[] = (
  Array.isArray(itemsDataNew)
    ? itemsDataNew
    : (itemsDataNew as { items?: any[] }).items ?? []
).map((item: any) => ({
  name: item.name,
  imageFilename: item.imageFilename.replace(/'/g, "_").replace(/\s+/g, "_"),
  url: item.url,
  tokenCost: item.tokenCost || 1,
}));

const Wishlist = () => {
  const [selectedGameMode, setSelectedGameMode] =
    useState<"Throne and Liberty" | "Custom">("Throne and Liberty");
  const [searchTerm, setSearchTerm] = useState("");
  const [topItems, setTopItems] = useState<
    Array<{ itemName: string; count: number }>
  >([]);
  const [showTopItems, setShowTopItems] = useState(false);
  const { selectedGuild, user } = useUser();
  const userRole = user?.memberships.find(
    (m) => m.guildId === selectedGuild
  )?.role;
  const isAdmin = userRole === "admin";

  const handleFetchTopItems = async () => {
    if (!selectedGuild) return;
    const items = await fetchTopWishlistedItems(selectedGuild);
    setTopItems(items);
    setShowTopItems(true);
  };

  const handleLockAllWishlists = async () => {
    if (!selectedGuild) return;
    await lockAllWishlists(selectedGuild);
  };

  const handleUnlockAllWishlists = async () => {
    if (!selectedGuild) return;
    await unlockAllWishlists(selectedGuild);
  };

  const handleResetTokens = async () => {
    if (!selectedGuild) return;
    await resetWishlistTokens(selectedGuild);
    alert("Wishlist tokens have been reset for all users.");
  };

  const filteredAvailableItems = adaptedItemsData.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <AppTheme>
      <Container maxWidth="lg" sx={{ pt: 4, pb: 6 }}>
        <Grid2 container spacing={4} sx={{ minHeight: '100vh' }}>
          {/* Header Section */}
          <Grid2 size={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 4,
            }}
          >
            <Typography variant="h4">Wishlist</Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {isAdmin && (
                <>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={handleLockAllWishlists}
                    startIcon={<LockIcon />}
                  >
                    Lock All
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleUnlockAllWishlists}
                    startIcon={<LockOpenIcon />}
                  >
                    Unlock All
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleResetTokens}
                  >
                    Reset Tokens
                  </Button>
                </>
              )}
              <Button variant="contained" onClick={handleFetchTopItems}>
                Show Top 10 Popular Items
              </Button>
            </Box>
          </Grid2>

          {/* Top Items Section */}
          {showTopItems && (
            <Grid2 size={12}
              sx={{ mb: 4, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Top 10 Most Wanted Items
              </Typography>
              <Grid2 container spacing={2}>
                {topItems.map((item, index) => (
                  <Grid2
                    size={{ xs: 12, sm: 6, md: 4 }}
                    key={item.itemName}
                  >
                    <Paper sx={{ p: 2 }}>
                      <Typography variant="subtitle1">{`${index + 1}. ${item.itemName}`}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        Wishlisted by {item.count} members
                      </Typography>
                    </Paper>
                  </Grid2>
                ))}
              </Grid2>

            </Grid2>
          )}

          {/* Wishlist Panel */}
          <Grid2 size={12}>
            <WishlistPanel
              selectedGameMode={selectedGameMode}
              searchTerm={searchTerm}
              filteredItems={filteredAvailableItems}
              onGameModeChange={setSelectedGameMode}
              onSearchChange={setSearchTerm}
            />
          </Grid2>
        </Grid2>
      </Container>
    </AppTheme>
  );

};

export default Wishlist;
