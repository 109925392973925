import React from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        py: 4,
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography paragraph>Last Updated: [18 November 2024]</Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          1. Introduction
        </Typography>
        <Typography paragraph>
          Welcome to LootManager ("we," "us," or "our"). We respect your privacy
          and are committed to protecting your personal data. This Privacy
          Policy explains how we collect, use, disclose, and safeguard your
          information when you use our website (https://lootmanager.net) and
          services, including our Discord bot (collectively, the "Services").
        </Typography>
        <Typography paragraph>
          By using our Services, you agree to this Privacy Policy. If you do not
          agree, please discontinue use immediately.
        </Typography>
        <Typography paragraph>
          For users in the European Union (EU), California, or other
          jurisdictions with specific privacy laws, we comply with applicable
          regulations such as the General Data Protection Regulation (GDPR) and
          the California Consumer Privacy Act (CCPA).
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          2. Information We Collect
        </Typography>
        <Typography paragraph>
          <strong>2.1 Personal Data</strong>
        </Typography>
        <Typography paragraph>
          We collect personally identifiable information ("Personal Data") to
          provide our Services. This may include:
        </Typography>
        <Typography component="ul">
          <li>Email address</li>
          <li>Username</li>
          <li>Discord ID</li>
          <li>Guild information</li>
        </Typography>
        <Typography paragraph>
          Providing this information is necessary to access certain features of
          our Services.
        </Typography>
        <Typography paragraph>
          <strong>2.2 Usage Data</strong>
        </Typography>
        <Typography paragraph>
          We automatically collect information about how you access and interact
          with our Services ("Usage Data"), including:
        </Typography>
        <Typography component="ul">
          <li>Internet Protocol (IP) address</li>
          <li>Browser type and version</li>
          <li>Device type and unique identifiers</li>
          <li>Pages visited, time spent on pages, and clickstream data</li>
          <li>Log and diagnostic data</li>
        </Typography>
        <Typography paragraph>
          <strong>2.3 Cookies and Tracking Technologies</strong>
        </Typography>
        <Typography paragraph>
          We use cookies and similar technologies to enhance your experience.
          Cookies we use include:
        </Typography>
        <Typography component="ul">
          <li>Session Cookies: Maintain session continuity.</li>
          <li>Preference Cookies: Remember your settings and preferences.</li>
          <li>Security Cookies: Enhance the security of our Services.</li>
        </Typography>
        <Typography paragraph>
          For more details, refer to our Cookie Policy.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          3. How We Use Your Information
        </Typography>
        <Typography component="ul">
          <li>Provide, maintain, and improve our Services.</li>
          <li>Manage your account and preferences.</li>
          <li>Communicate with you, including sending updates and responding to inquiries.</li>
          <li>Analyze trends and optimize the performance of our Services.</li>
          <li>Ensure the security of our systems and comply with legal obligations.</li>
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          4. Legal Bases for Processing
        </Typography>
        <Typography component="ul">
          <li>Consent: When you explicitly agree to data processing (e.g., subscribing to newsletters).</li>
          <li>Contract: To provide the Services you requested.</li>
          <li>Legal Obligation: To comply with laws or regulations.</li>
          <li>Legitimate Interest: To improve, secure, and market our Services.</li>
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          5. Sharing Your Information
        </Typography>
        <Typography paragraph>
          <strong>5.1 With Service Providers</strong>
        </Typography>
        <Typography paragraph>
          We may share your information with trusted third-party providers to
          deliver our Services, including:
        </Typography>
        <Typography component="ul">
          <li>Hosting providers</li>
          <li>Payment processors</li>
          <li>Analytics services</li>
        </Typography>
        <Typography paragraph>
          These providers are bound by confidentiality agreements and only use
          your data for specified purposes.
        </Typography>
        <Typography paragraph>
          <strong>5.2 For Legal Compliance</strong>
        </Typography>
        <Typography paragraph>
          We may disclose your information to comply with legal obligations or
          protect our rights and users. Examples include:
        </Typography>
        <Typography component="ul">
          <li>Responding to subpoenas or legal requests.</li>
          <li>Investigating or preventing fraudulent or illegal activities.</li>
        </Typography>
        <Typography paragraph>
          Where permitted, we will notify you of such disclosures.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          6. Your Data Protection Rights
        </Typography>
        <Typography paragraph>
          Depending on your location, you have the following rights:
        </Typography>
        <Typography paragraph>
          <strong>6.1 For EU Users (GDPR):</strong>
        </Typography>
        <Typography component="ul">
          <li>Right of Access: Obtain a copy of your data.</li>
          <li>Right to Rectification: Request corrections to inaccurate or incomplete data.</li>
          <li>Right to Erasure: Request deletion of your data, subject to legal obligations.</li>
          <li>Right to Restriction: Limit how your data is processed.</li>
          <li>Right to Data Portability: Receive your data in a structured, machine-readable format.</li>
          <li>Right to Object: Opt out of certain types of processing.</li>
        </Typography>
        <Typography paragraph>
          <strong>6.2 For California Users (CCPA):</strong>
        </Typography>
        <Typography component="ul">
          <li>Right to Know: Learn what personal information we collect, use, and share.</li>
          <li>Right to Delete: Request deletion of your personal information.</li>
          <li>Right to Opt-Out: Prevent the sale of your personal data.</li>
        </Typography>
        <Typography paragraph>
          To exercise these rights, contact us at [Insert Contact Email Address].
          We will respond within applicable timeframes.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          7. Data Retention
        </Typography>
        <Typography paragraph>
          We retain your data only as long as necessary for the purposes described
          in this policy or as required by law. When data is no longer needed, it
          will be securely deleted or anonymized.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          8. Data Security
        </Typography>
        <Typography paragraph>
          We implement robust security measures to protect your data, including:
        </Typography>
        <Typography component="ul">
          <li>Data encryption (in transit and at rest).</li>
          <li>Secure access controls.</li>
          <li>Regular audits and monitoring.</li>
        </Typography>
        <Typography paragraph>
          No system is entirely secure. Use our Services at your own risk.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          9. Cookies Management
        </Typography>
        <Typography paragraph>
          You can manage cookies through your browser settings. Refer to our
          Cookie Policy for detailed instructions.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          10. Third-Party Links
        </Typography>
        <Typography paragraph>
          Our Services may link to third-party websites. We are not responsible
          for their privacy practices or content. Review their privacy policies
          before sharing your information.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          11. Children's Privacy
        </Typography>
        <Typography paragraph>
          Our Services are not intended for children under the age of 13. If we
          become aware of data collected from children, we will delete it
          promptly. Parents may contact us to request data removal.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          12. International Data Transfers
        </Typography>
        <Typography paragraph>
          If your data is transferred outside your jurisdiction, we ensure it is
          protected by:
        </Typography>
        <Typography component="ul">
          <li>Standard Contractual Clauses approved by regulatory authorities.</li>
          <li>Adequate safeguards for international transfers.</li>
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          13. Data Breaches
        </Typography>
        <Typography paragraph>
          In the event of a data breach, we will notify affected users and
          authorities within 72 hours, as required by law.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          14. Updates to This Privacy Policy
        </Typography>
        <Typography paragraph>
          We may update this Privacy Policy to reflect changes in our practices
          or legal requirements. Significant updates will be communicated via
          email or on our website. Continued use of our Services constitutes
          acceptance of the revised policy.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          15. Contact Us
        </Typography>
        <Typography paragraph>
          If you have questions about this Privacy Policy, contact us at:
        </Typography>
        <Typography component="ul">
          <li>Email: [info@lootmanager.net]</li>
          <li>Address: [533 rue St. Anne, Saguenay, Québec, Canada, G7J 2N6]</li>
        </Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
