import React, { useState } from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Button,
    Autocomplete,
    TextField,
    Box
} from '@mui/material';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../firebase';

interface AddParticipantModalProps {
    open: boolean;
    onClose: () => void;
    guildMembers: {
        uid: string;
        inGameName: string;
        gearScore: number;
        dkp: number;
    }[];
    eventId: string;
    guildId: string;
    currentParticipants: string[];
}

const AddParticipantModal: React.FC<AddParticipantModalProps> = ({
    open,
    onClose,
    guildMembers,
    eventId,
    guildId,
    currentParticipants
}) => {
    const [selectedMember, setSelectedMember] = useState<{
        uid: string;
        inGameName: string;
        gearScore: number;
        dkp: number;
    } | null>(null);

    const availableMembers = guildMembers.filter(
        member => !currentParticipants.includes(member.uid)
    );

    const handleAddParticipant = async () => {
        if (!selectedMember) return;

        const eventRef = doc(db, 'events', guildId, 'guildEvents', eventId);
        
        try {
            await updateDoc(eventRef, {
                registeredUsers: arrayUnion(selectedMember.uid)
            });
            
            setSelectedMember(null);
            onClose();
        } catch (error) {
            console.error('Error adding participant:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Add Participant</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 2 }}>
                    <Autocomplete
                        options={availableMembers}
                        getOptionLabel={(option) => option.inGameName}
                        value={selectedMember}
                        onChange={(_, newValue) => setSelectedMember(newValue)}
                        renderInput={(params) => (
                            <TextField {...params} label="Search Members" fullWidth />
                        )}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <span>{option.inGameName}</span>
                                    <span>
                                        GS: {option.gearScore} | DKP: {option.dkp}
                                    </span>
                                </Box>
                            </Box>
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button 
                    onClick={handleAddParticipant} 
                    variant="contained" 
                    disabled={!selectedMember}
                >
                    Add Participant
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddParticipantModal;
