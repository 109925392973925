import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';

import MenuButton from './MenuButton';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';

interface UserProfile {
  guild: string | null;
  id: string;
  uid: string;
  username: string;
  dkp: number;
  role: 'admin' | 'member' | 'officer';
  status: 'approved' | 'pending' | 'rejected';
  inGameName?: string;
  displayName?: string;
  primaryWeapon?: string;
  secondaryWeapon?: string;
  gearScore?: number;
  inGameRole?: ('DPS' | 'Healer' | 'Tank')[];
}

interface SideMenuMobileProps {
  open: boolean | undefined;
  toggleDrawer: (newOpen: boolean) => () => void;
  navigationGroups: {
    [key: string]: {
      text: string;
      icon: React.ReactNode;
      path: string;
    }[];
  };
  user: UserProfile | null;
  onLogout: () => Promise<void>;
}

export default function SideMenuMobile({
  open,
  toggleDrawer,
  navigationGroups,
  user,
  onLogout,
}: SideMenuMobileProps) {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: 'none',
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: '70dvw',
          height: '100%',
        }}
      >
        <Divider />
        <Stack sx={{ flexGrow: 1 }}>
          <MenuContent navigationGroups={navigationGroups} />
          <Divider />
        </Stack>
        <CardAlert />
        <Stack sx={{ p: 2 }}>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<LogoutRoundedIcon />}
            onClick={onLogout}
          >
            Logout
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
}
