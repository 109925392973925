import * as React from 'react';
import { useLocation, Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

interface MenuContentProps {
  navigationGroups: {
    [key: string]: {
      text: string;
      icon: React.ReactNode;
      path: string;
    }[];
  };
}

export default function MenuContent({ navigationGroups }: MenuContentProps) {
  const location = useLocation();
 
  return (
    <Stack sx={{ flexGrow: 1, p: 1 }}>
      {Object.entries(navigationGroups).map(([groupKey, items], index) => (
        items.length > 0 && (
          <React.Fragment key={groupKey}>
            {index > 0 && <Divider sx={{ my: 0.5 }} />}
            <List dense sx={{ py: 0.5 }}>
              {items.map((item) => (
                <ListItem key={item.text} disablePadding sx={{ mb: 0.5 }}>
                  <ListItemButton
                    component={Link}
                    to={item.path}
                    selected={location.pathname === item.path}
                    sx={{ borderRadius: 1 }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        )
      ))}
    </Stack>
  );
}
