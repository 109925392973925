import * as React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';
import OptionsMenu from './OptionsMenu';
import useUser from '../hooks/useUser';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

interface UserProfile {
  id: string;
  uid: string;
  username: string;
  inGameName?: string;
  dkp: number;
  role: string;
  status: string;
  guild: string | null;
  memberships: any[];
  primaryWeapon?: string; // Make optional
  secondaryWeapon?: string; // Make optional
  gearScore?: number; // Make optional
}

interface SideMenuProps {
  navigationGroups: {
    [key: string]: {
      text: string;
      icon: React.ReactNode;
      path: string;
    }[];
  };
  user: UserProfile | null;
  selectedGuild: string | null;
  onLogout: () => Promise<void>;
}

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

export default function SideMenu({
  navigationGroups,
  user,
  selectedGuild,
  onLogout,
}: SideMenuProps) {
  const { user: currentUser } = useUser();
  const [isSubscribed, setIsSubscribed] = React.useState<boolean>(true);

  React.useEffect(() => {
    const fetchGuildSubscription = async () => {
      if (selectedGuild) {
        const guildDoc = await getDoc(doc(db, "guilds", selectedGuild));
        if (guildDoc.exists()) {
          setIsSubscribed(guildDoc.data()?.isSubscribed || false);
        }
      }
    };

    fetchGuildSubscription();
  }, [selectedGuild]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', md: 'block' },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mt: 'calc(var(--template-frame-height, 0px) + 4px)',
          p: 1.5,
        }}
      >
      </Box>
      <Divider />
      <MenuContent navigationGroups={navigationGroups} />
      {!isSubscribed && <CardAlert />}
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Avatar
          sizes="small"
          alt={currentUser?.username || user?.username || 'User'}
          sx={{ width: 36, height: 36 }}
        />
        <Box sx={{ mr: 'auto' }}>
          <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
            {currentUser?.inGameName || currentUser?.username || user?.username || 'User Name'}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {currentUser?.role || user?.role || 'Member'} • {currentUser?.dkp || user?.dkp || 0} DKP
          </Typography>
        </Box>
        <OptionsMenu onLogout={onLogout} />
      </Stack>
    </Drawer>
  );
}