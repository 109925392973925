import React, { useState, useEffect, Suspense } from 'react';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    Stack,
    Card,
    Avatar,
    Modal,
    Pagination,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    getDoc,
    doc,
    Timestamp,
    collection,
    query,
    orderBy,
    getDocs,
    limit,
    onSnapshot,
} from 'firebase/firestore';
import { db } from '../../../firebase';
import { LootDrop } from '../../../interfaces';
import { HandleRollLootProps } from '../../../interfaces/handlers';
import itemsDataNew from '../../../items.json';

interface ReasonEntry {
    inGameName: string;
    value: number | string;
    reason?: string;
    isWinner: boolean;
}

interface LootTableProps {
    loading: boolean;
    lootDrops: LootDrop[];
    isLootmasterOrAdmin: boolean;
    historyVisible: Record<string, boolean>;
    bidHistoryVisible: Record<string, boolean>;
    sessionId: string;
    user: any;
    onRoll: (props: HandleRollLootProps) => Promise<void>;
    onDistribute: (lootId: string) => Promise<void>;
    onRemove: (lootId: string) => void;
    onToggleHistory: (lootId: string) => void;
    onToggleBidHistory: (lootId: string) => void;
    onBid: (lootId: string) => void;
    hasRolls: Record<string, boolean>;
    hasBids: Record<string, boolean>;
    session: any;
    aggregateData: {
        highestRolls: Record<string, number>;
        currentBids: Record<string, number>;
        bidCounts: Record<string, number>;
        rollCounts: Record<string, number>;
    };
    fetchMoreLootDrops: (page: number) => void;
    totalLootDropsCount: number;
}

const LazyHistory = React.lazy(() => import('../../RollingHistory'));
const LazyBiddingHistory = React.lazy(() => import('../../BiddingHistory'));

export const LootTable: React.FC<LootTableProps> = ({
    loading,
    lootDrops,
    isLootmasterOrAdmin,
    historyVisible,
    bidHistoryVisible,
    sessionId,
    user,
    onRoll,
    onDistribute,
    onRemove,
    onToggleHistory,
    onToggleBidHistory,
    onBid,
    hasRolls,
    hasBids,
    session,
    aggregateData,
    fetchMoreLootDrops,
    totalLootDropsCount,
}) => {
    const theme = useTheme();
    const [currentWinners, setCurrentWinners] = useState<
        Record<string, ReasonEntry>
    >({});
    const [guildId, setGuildId] = useState<string>('');
    const [reasonModalOpen, setReasonModalOpen] = useState(false);
    const [selectedReasons, setSelectedReasons] = useState<ReasonEntry[]>([]);
    const [selectedLootMethod, setSelectedLootMethod] = useState<string>('');
    const [disabledRollButtons, setDisabledRollButtons] = useState<
        Record<string, boolean>
    >({});
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const reasonsPerPage = 10;

    useEffect(() => {
        const fetchSessionData = async () => {
            if (!session || !sessionId) return;

            const sessionDocRef = doc(db, 'sessions', session.guild, 'sessions', sessionId);
            const sessionDocSnap = await getDoc(sessionDocRef);
            if (sessionDocSnap.exists()) {
                const sessionData = sessionDocSnap.data();
                setGuildId(sessionData.guild);
            }
        };

        fetchSessionData();
    }, [sessionId, session]);

    useEffect(() => {
        if (!lootDrops.length || !guildId || !sessionId) return;

        const batchSize = 10;
        const subscriptions: (() => void)[] = [];

        for (let i = 0; i < lootDrops.length; i += batchSize) {
            const batch = lootDrops.slice(i, i + batchSize);

            batch.forEach((loot) => {
                if (loot.lootMethod === 'roll') {
                    const rollsRef = collection(
                        db,
                        "sessions",
                        guildId,
                        "sessions",
                        sessionId,
                        "lootDrops",
                        loot.id,
                        "rolls"
                    );
                    const q = query(rollsRef, orderBy("roll", "desc"), limit(1));

                    const unsubscribe = onSnapshot(q, async (snapshot) => {
                        if (!snapshot.empty) {
                            const rollDoc = snapshot.docs[0];
                            const rollData = rollDoc.data();
                            const userId = rollData.rollerId;

                            try {
                                const userGuildDocRef = doc(db, 'userGuilds', `${userId}_${guildId}`);
                                const userGuildDocSnap = await getDoc(userGuildDocRef);
                                const userData = userGuildDocSnap.exists() ? userGuildDocSnap.data() : null;

                                setCurrentWinners(prev => ({
                                    ...prev,
                                    [loot.id]: {
                                        inGameName: userData?.inGameName || rollData.rollerName || 'Unknown',
                                        value: rollData.roll,
                                        reason: rollData.reason || 'No reason provided',
                                        isWinner: true
                                    }
                                }));
                            } catch (error) {
                                console.error(`Error fetching user data for roll winner: ${error}`);
                            }
                        }
                    }, (error) => {
                        console.error(`Error in roll subscription: ${error}`);
                    });

                    subscriptions.push(unsubscribe);
                } else if (loot.lootMethod === 'dkp') {
                    const lootRef = doc(db, "sessions", guildId, "sessions", sessionId, "lootDrops", loot.id);

                    const unsubscribe = onSnapshot(lootRef, async (snapshot) => {
                        if (snapshot.exists()) {
                            const lootData = snapshot.data();
                            const currentBid = lootData.currentBid;
                            const bidderId = lootData.currentBidder;

                            if (currentBid && bidderId) {
                                try {
                                    const userGuildDocRef = doc(db, 'userGuilds', `${bidderId}_${guildId}`);
                                    const userGuildDocSnap = await getDoc(userGuildDocRef);
                                    const userData = userGuildDocSnap.exists() ? userGuildDocSnap.data() : null;

                                    setCurrentWinners(prev => ({
                                        ...prev,
                                        [loot.id]: {
                                            inGameName: userData?.inGameName || lootData.currentBidderName || 'Unknown',
                                            value: currentBid,
                                            reason: 'Top bidder',
                                            isWinner: true
                                        }
                                    }));
                                } catch (error) {
                                    console.error(`Error fetching user data for bid winner: ${error}`);
                                }
                            }
                        }
                    }, (error) => {
                        console.error(`Error in bid subscription: ${error}`);
                    });

                    subscriptions.push(unsubscribe);
                }
            });
        }

        return () => subscriptions.forEach(unsubscribe => unsubscribe());
    }, [lootDrops, guildId, sessionId]);

    useEffect(() => {
        if (
            page * itemsPerPage > lootDrops.length &&
            lootDrops.length < totalLootDropsCount
        ) {
            fetchMoreLootDrops(page);
        }
    }, [page, lootDrops.length, totalLootDropsCount, fetchMoreLootDrops]);

    const openReasonModal = async (lootId: string, lootMethod: string) => {
        const reasons: ReasonEntry[] = [];

        if (lootMethod === 'roll') {
            const rollsRef = collection(
                db,
                "sessions",
                guildId,
                "sessions",
                sessionId,
                "lootDrops",
                lootId,
                "rolls"
            );
            const q = query(rollsRef, orderBy("roll", "desc"));
            const rollsSnapshot = await getDocs(q);

            for (const docSnap of rollsSnapshot.docs) {
                const rollData = docSnap.data();
                const userId = rollData.rollerId;
                const userGuildDocRef = doc(db, 'userGuilds', `${userId}_${guildId}`);
                const userGuildDocSnap = await getDoc(userGuildDocRef);
                const userData = userGuildDocSnap.exists() ? userGuildDocSnap.data() : null;

                reasons.push({
                    inGameName: userData?.inGameName || rollData.rollerName || 'Unknown',
                    value: rollData.roll,
                    reason: rollData.reason || 'No reason provided',
                    isWinner: reasons.length === 0,
                });
            }
        } else if (lootMethod === 'dkp') {
            const bidsRef = collection(
                db,
                "sessions",
                guildId,
                "sessions",
                sessionId,
                "lootDrops",
                lootId,
                "bids"
            );
            const q = query(bidsRef, orderBy("amount", "desc"));
            const bidsSnapshot = await getDocs(q);

            for (const docSnap of bidsSnapshot.docs) {
                const bidData = docSnap.data();
                const userId = bidData.bidderId;
                const userGuildDocRef = doc(db, 'userGuilds', `${userId}_${guildId}`);
                const userGuildDocSnap = await getDoc(userGuildDocRef);
                const userData = userGuildDocSnap.exists() ? userGuildDocSnap.data() : null;

                reasons.push({
                    inGameName: userData?.inGameName || bidData.bidderName || 'Unknown',
                    value: bidData.amount,
                    reason: bidData.reason || 'No reason provided',
                    isWinner: reasons.length === 0,
                });
            }
        }

        setSelectedReasons(reasons);
        setSelectedLootMethod(lootMethod);
        setReasonModalOpen(true);
    };

    const closeReasonModal = () => {
        setSelectedReasons([]);
        setSelectedLootMethod('');
        setReasonModalOpen(false);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (lootDrops.length === 0) {
        return (
            <Typography sx={{ textAlign: 'center', mt: 2 }}>
                No loot items added yet.
            </Typography>
        );
    }

    // Sort lootDrops alphabetically by itemName
    const sortedLootDrops = [...lootDrops].sort((a, b) => {
        if (a.itemName < b.itemName) return -1;
        if (a.itemName > b.itemName) return 1;
        return 0;
    });

    // Calculate total pages based on the sorted loot drops
    const totalPages = Math.ceil(sortedLootDrops.length / itemsPerPage);

    // Get the loot drops for the current page
    const displayedLootDrops = sortedLootDrops.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    return (
        <Box
            sx={{
                bgcolor: theme.palette.background.paper,
                borderRadius: 2,
                p: 4,
                boxShadow: 3,
            }}
        >
            <Typography variant="h4" sx={{ mb: 3 }}>
                Loot Drops
            </Typography>

            {/* Desktop View */}
            <Box
                sx={{
                    display: { xs: 'none', md: 'block' },
                    height: 'calc(100vh - 200px)',
                    overflowY: 'auto',
                }}
            >
                <Stack spacing={2}>
                    {displayedLootDrops.map((loot, index) => (
                        <Card key={index} sx={{ p: 2, mx: 2 }}>
                            <Stack spacing={2}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Avatar
                                        src={loot.imageUrl || '/images/placeholder.png'}
                                        alt={loot.itemName}
                                        sx={{ width: 40, height: 40 }}
                                    />
                                    <a href={itemsDataNew.find(item => item.name === loot.itemName)?.url || '#'}>
                                        {loot.itemName}
                                    </a>
                                </Box>

                                <Stack spacing={1}>
                                    <Typography sx={{
                                        color: loot.status === 'distributed' ? theme.palette.success.main :
                                            loot.status === 'open' ? theme.palette.info.main :
                                                theme.palette.text.primary,
                                        fontWeight: 'bold'
                                    }}>
                                        <strong>Status:</strong> {loot.status}
                                    </Typography>

                                    <Typography><strong>Traits:</strong> {loot.traits?.join(', ') || 'N/A'}</Typography>
                                    {(loot.lootMethod === 'roll' || isLootmasterOrAdmin || !loot.isPrivateBidding) && (
                                        <Typography>
                                            <strong>Current Winner:</strong> {
                                                currentWinners[loot.id] ? (
                                                    `${currentWinners[loot.id].inGameName} (${currentWinners[loot.id].value}${loot.lootMethod === 'dkp' ? ' DKP' : ''})`
                                                ) : (
                                                    loot.lootMethod === 'roll' ? 'No rolls yet' : 'No bids yet'
                                                )
                                            }
                                        </Typography>
                                    )}
                                </Stack>

                                <Stack direction="row" flexWrap="wrap" gap={1}>
                                    {loot.lootMethod === "roll" ? (
                                        <>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => onToggleHistory(loot.id)} // Correct function for roll history
                                            >
                                                {historyVisible[loot.id] ? 'Hide History' : 'Show History'}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                    setDisabledRollButtons((prev) => ({ ...prev, [loot.id]: true }));
                                                    onRoll({
                                                        lootId: loot.id,
                                                        user,
                                                        sessionId,
                                                        setConfirmationTitle: () => { },
                                                        setConfirmationMessage: () => { },
                                                        setIsError: () => { },
                                                        setConfirmationModalVisible: () => { },
                                                        setSuccess: () => { },
                                                        setUserRolls: () => { },
                                                        selectedGuild: guildId,
                                                        session
                                                    }).catch(() => {
                                                        setDisabledRollButtons((prev) => ({ ...prev, [loot.id]: false }));
                                                    });
                                                }}
                                                disabled={disabledRollButtons[loot.id]}
                                            >
                                                Roll
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => onToggleBidHistory(loot.id)}
                                            >
                                                {bidHistoryVisible[loot.id] ? 'Hide History' : 'Show History'}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => onBid(loot.id)}
                                            >
                                                Bid
                                            </Button>
                                        </>
                                    )}
                                    {isLootmasterOrAdmin && (
                                        <>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => onDistribute(loot.id)}
                                            >
                                                Distribute
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                                onClick={() => onRemove(loot.id)}
                                            >
                                                Remove
                                            </Button>
                                        </>
                                    )}
                                    {(isLootmasterOrAdmin || !loot.isPrivateBidding) && (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => openReasonModal(loot.id, loot.lootMethod)}
                                        >
                                            View Reasons
                                        </Button>
                                    )}
                                </Stack>

                                {/* History Sections */}
                                {loot.lootMethod === "roll" && historyVisible[loot.id] && (
                                    <Box sx={{
                                        mt: 2,
                                        bgcolor: 'background.default',
                                        p: 2,
                                        borderRadius: 1,
                                        maxHeight: '300px',
                                        overflowY: 'auto'
                                    }}>
                                        <Suspense fallback={<CircularProgress />}>
                                            <LazyHistory
                                                sessionId={sessionId}
                                                lootId={loot.id}
                                                selectedGuild={guildId}
                                            />
                                        </Suspense>
                                    </Box>
                                )}

                                {loot.lootMethod === "dkp" && bidHistoryVisible[loot.id] && (
                                    <Box sx={{
                                        mt: 2,
                                        bgcolor: 'background.default',
                                        p: 2,
                                        borderRadius: 1,
                                        maxHeight: '300px',
                                        overflowY: 'auto'
                                    }}>
                                        <Suspense fallback={<CircularProgress />}>
                                            <LazyBiddingHistory
                                                sessionId={sessionId}
                                                lootId={loot.id}
                                                isPrivate={loot.isPrivateBidding ?? false}
                                                isLootmasterOrAdmin={isLootmasterOrAdmin}
                                                user={user}
                                                aggregateData={aggregateData}
                                                selectedGuild={guildId} // Pass the guildId as selectedGuild
                                            />
                                        </Suspense>
                                    </Box>
                                )}
                            </Stack>
                        </Card>
                    ))}
                </Stack>

                {/* Pagination controls */}
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                />
            </Box>


            {/* Mobile View */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Stack spacing={2}>
                    {displayedLootDrops.map((loot, index) => (
                        <Card key={index} sx={{ p: 2 }}>
                            <Stack spacing={2}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Avatar
                                        src={loot.imageUrl || '/images/placeholder.png'}
                                        alt={loot.itemName}
                                        sx={{ width: 40, height: 40 }}
                                    />
                                    <a href={itemsDataNew.find(item => item.name === loot.itemName)?.url || '#'}>
                                        {loot.itemName}
                                    </a>
                                </Box>

                                <Stack spacing={1}>
                                    <Typography
                                        sx={{
                                            color: loot.status === 'distributed' ? theme.palette.success.main :
                                                loot.status === 'open' ? theme.palette.info.main :
                                                    theme.palette.text.primary,
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <strong>Status:</strong> {loot.status}
                                    </Typography>

                                    <Typography><strong>Traits:</strong> {loot.traits?.join(', ') || 'N/A'}</Typography>
                                    {(loot.lootMethod === 'roll' || isLootmasterOrAdmin || !loot.isPrivateBidding) && (
                                        <Typography>
                                            <strong>Current Winner:</strong> {
                                                currentWinners[loot.id] ? (
                                                    `${currentWinners[loot.id].inGameName} (${currentWinners[loot.id].value}${loot.lootMethod === 'dkp' ? ' DKP' : ''})`
                                                ) : (
                                                    loot.lootMethod === 'roll' ? 'No rolls yet' : 'No bids yet'
                                                )
                                            }
                                        </Typography>
                                    )}
                                </Stack>

                                <Stack direction="row" flexWrap="wrap" gap={1}>
                                    {loot.lootMethod === "roll" ? (
                                        <>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => onToggleHistory(loot.id)}
                                            >
                                                {historyVisible[loot.id] ? 'Hide History' : 'Show History'}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                    setDisabledRollButtons((prev) => ({ ...prev, [loot.id]: true }));
                                                    onRoll({
                                                        lootId: loot.id,
                                                        user,
                                                        sessionId,
                                                        setConfirmationTitle: () => { },
                                                        setConfirmationMessage: () => { },
                                                        setIsError: () => { },
                                                        setConfirmationModalVisible: () => { },
                                                        setSuccess: () => { },
                                                        setUserRolls: () => { },
                                                        selectedGuild: guildId,
                                                        session
                                                    }).catch(() => {
                                                        setDisabledRollButtons((prev) => ({ ...prev, [loot.id]: false }));
                                                    });
                                                }}
                                                disabled={disabledRollButtons[loot.id]}
                                            >
                                                Roll
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => onToggleBidHistory(loot.id)}
                                            >
                                                {bidHistoryVisible[loot.id] ? 'Hide History' : 'Show History'}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => onBid(loot.id)}
                                            >
                                                Bid
                                            </Button>
                                        </>
                                    )}
                                    {isLootmasterOrAdmin && (
                                        <>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => onDistribute(loot.id)}
                                            >
                                                Distribute
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                                onClick={() => onRemove(loot.id)}
                                            >
                                                Remove
                                            </Button>
                                        </>
                                    )}
                                    {(isLootmasterOrAdmin || !loot.isPrivateBidding) && (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => openReasonModal(loot.id, loot.lootMethod)}
                                        >
                                            View Reasons
                                        </Button>
                                    )}
                                </Stack>

                                {/* History Sections */}
                                {loot.lootMethod === "roll" && historyVisible[loot.id] && (
                                    <Box sx={{
                                        mt: 2,
                                        bgcolor: 'background.default',
                                        p: 2,
                                        borderRadius: 1,
                                        maxHeight: '300px',
                                        overflowY: 'auto'
                                    }}>
                                        <Suspense fallback={<CircularProgress />}>
                                            <LazyHistory
                                                sessionId={sessionId}
                                                lootId={loot.id}
                                                selectedGuild={guildId}
                                            />
                                        </Suspense>
                                    </Box>
                                )}

                                {loot.lootMethod === "dkp" && bidHistoryVisible[loot.id] && (
                                    <Box sx={{
                                        mt: 2,
                                        bgcolor: 'background.default',
                                        p: 2,
                                        borderRadius: 1,
                                        maxHeight: '300px',
                                        overflowY: 'auto'
                                    }}>
                                        <Suspense fallback={<CircularProgress />}>
                                            <LazyBiddingHistory
                                                sessionId={sessionId}
                                                lootId={loot.id}
                                                isPrivate={loot.isPrivateBidding ?? false}
                                                isLootmasterOrAdmin={isLootmasterOrAdmin}
                                                user={user}
                                                aggregateData={aggregateData}
                                                selectedGuild={guildId} // Pass the guildId as selectedGuild
                                            />
                                        </Suspense>
                                    </Box>
                                )}
                            </Stack>
                        </Card>
                    ))}
                </Stack>

                {/* Pagination controls */}
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                />
            </Box>

            {/* Reason Modal */}
            <Modal open={reasonModalOpen} onClose={closeReasonModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxWidth: 500,
                    width: '90%',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Reasons</Typography>
                    {selectedReasons.slice((page - 1) * reasonsPerPage, page * reasonsPerPage).map((entry, index) => (
                        <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid', borderColor: theme.palette.divider, borderRadius: 1, backgroundColor: entry.isWinner ? theme.palette.action.hover : 'transparent' }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: entry.isWinner ? 'bold' : 'normal' }}>
                                {entry.inGameName} {entry.isWinner ? '(Winner)' : ''}
                            </Typography>
                            <Typography variant="body2">
                                {selectedLootMethod === 'dkp' ? `Bid: ${entry.value} DKP` : `Roll: ${entry.value}`}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                {entry.reason}
                            </Typography>
                        </Box>
                    ))}
                    <Pagination
                        count={Math.ceil(selectedReasons.length / reasonsPerPage)}
                        page={page}
                        onChange={handlePageChange}
                        sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                    />
                    <Button variant="contained" onClick={closeReasonModal} sx={{ mt: 2 }}>Close</Button>
                </Box>
            </Modal>
        </Box>
    );
};
