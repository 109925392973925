import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';

export default function CardAlert() {
  const handleKofiClick = () => {
    window.open('https://ko-fi.com/lootmanager', '_blank');
  };

  return (
    <Card variant="outlined" sx={{ m: 1.5, p: 1.5 }}>
      <CardContent>
        <AutoAwesomeRoundedIcon fontSize="small" />
        <Typography gutterBottom sx={{ fontWeight: 600 }}>
          Unlock Unlimited Features
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
          Get access to unlimited feature and Discord integration with Premium.
        </Typography>
        <Button 
          variant="contained" 
          size="small" 
          fullWidth 
          onClick={handleKofiClick}
        >
          Upgrade to Premium
        </Button>
      </CardContent>
    </Card>
  );
}
