import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import useUser from '../../hooks/useUser';
import { collection, getDocs, query, where, doc, setDoc, updateDoc, arrayUnion, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import TeamForm from './TeamForm';
import TeamList from './TeamList';
import MemberSearch from './MemberSearch';
import AttendanceMarking from './AttendanceMarking';

interface Team {
    name: string;
    members: string[];
    leader: string;
    secondLeader?: string;
}

interface GuildMember {
    uid: string;
    username: string;
    inGameName: string;
    dkp: number;
    role: string;
    gearScore: number;
    primaryWeapon: string;
    secondaryWeapon: string;
    classTypes: ('Healer' | 'Tank' | 'DPS')[]; // Updated 'Heal' to 'Healer'
}

const TeamAttendanceDashboard: React.FC<{ teamId?: string }> = ({ teamId }) => {
    const { selectedGuild, user } = useUser();
    const [teams, setTeams] = useState<Team[]>([]);
    const [guildMembers, setGuildMembers] = useState<GuildMember[]>([]);

    useEffect(() => {
        const fetchGuildMembers = async () => {
            if (!selectedGuild) return;
            const q = query(collection(db, 'userGuilds'), where('guildId', '==', selectedGuild), where('status', '==', 'approved'));
            const querySnapshot = await getDocs(q);
            const members = querySnapshot.docs.map(doc => ({
                uid: doc.id,
                username: doc.data().username || 'Unknown',
                inGameName: doc.data().inGameName || 'Unknown',
                dkp: doc.data().dkp || 0,
                role: doc.data().role || 'Member',
                gearScore: doc.data().gearScore || 0,
                primaryWeapon: doc.data().primaryWeapon || 'None',
                secondaryWeapon: doc.data().secondaryWeapon || 'None',
                classTypes: doc.data().inGameRole || ['DPS'], // Ensure this matches 'Healer' instead of 'Heal'
            }));
            setGuildMembers(members);
        };
    
        const fetchTeams = async () => {
            if (!selectedGuild) return;
            const q = query(collection(db, 'teams', selectedGuild, 'guildTeams'));
            const querySnapshot = await getDocs(q);
            const fetchedTeams = querySnapshot.docs.map(doc => ({
                name: doc.id,
                members: doc.data().members || [],
                leader: doc.data().leader || '',
                secondLeader: doc.data().secondLeader
            }));
            setTeams(fetchedTeams);
        };
    
        fetchGuildMembers();
        fetchTeams();
    }, [selectedGuild]);
    

    const handleCreateTeam = async (newTeamName: string, leader: string, secondLeader?: string) => {
        if (newTeamName.trim() && selectedGuild) {
            const newTeam = {
                name: newTeamName,
                members: [],
                leader,
                ...(secondLeader && { secondLeader })
            };
            setTeams([...teams, newTeam]);

            try {
                const teamDocRef = doc(db, 'teams', selectedGuild, 'guildTeams', newTeamName);
                await setDoc(teamDocRef, newTeam);
                console.log('Team created successfully in Firestore');
            } catch (error) {
                console.error('Error creating team in Firestore:', error);
            }
        }
    };

    const handleAddMemberToTeam = async (teamName: string, member: GuildMember): Promise<void> => {
        if (!selectedGuild) {
            console.error('Selected guild is null');
            return;
        }

        const updatedTeams = teams.map(team => {
            if (team.name === teamName && !team.members.includes(member.inGameName)) {
                const updatedMembers = [...team.members, member.inGameName];

                const teamDocRef = doc(db, 'teams', selectedGuild, 'guildTeams', teamName);
                updateDoc(teamDocRef, {
                    members: arrayUnion(member.inGameName)
                }).then(() => {
                    console.log('Member added successfully to team in Firestore');
                }).catch(error => {
                    console.error('Error adding member to team in Firestore:', error);
                });

                return { ...team, members: updatedMembers };
            }
            return team;
        });

        setTeams(updatedTeams);
    };

    const handleDeleteTeam = async (teamName: string) => {
        if (!selectedGuild) {
            console.error('Selected guild is null');
            return;
        }

        try {
            const teamDocRef = doc(db, 'teams', selectedGuild, 'guildTeams', teamName);
            await deleteDoc(teamDocRef);
            setTeams((prevTeams) => prevTeams.filter((team) => team.name !== teamName));
            console.log('Team deleted successfully from Firestore');
        } catch (error) {
            console.error('Error deleting team from Firestore:', error);
        }
    };

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: 'background.default' }}>
            <Box sx={{
                position: 'relative',
                height: '200px',
                overflow: 'hidden',
            }}>
                <Box sx={{
                    backgroundImage: 'url(/images/newsite/bkg.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    filter: 'blur(1px)',
                    zIndex: 1,
                }} />
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    zIndex: 2,
                }} />
                <Container maxWidth="lg" sx={{ height: '100%', position: 'relative', zIndex: 3 }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                        color: 'white'
                    }}>
                        <Typography variant="h4" sx={{ fontWeight: 700 }}>
                            Team & Attendance Dashboard
                        </Typography>
                    </Box>
                </Container>
            </Box>

            <Container maxWidth="lg" sx={{ mt: -5, position: 'relative', zIndex: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <TeamForm onCreateTeam={handleCreateTeam} guildMembers={guildMembers} />
                        <AttendanceMarking teams={teams} user={user} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <MemberSearch guildMembers={guildMembers} teams={teams} onAddMember={handleAddMemberToTeam} />
                        <TeamList teams={teams} onDeleteTeam={handleDeleteTeam} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default TeamAttendanceDashboard;
