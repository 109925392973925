import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useUser from '../hooks/useUser';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Stack,
} from '@mui/material';
import {
  Dashboard,
  Group,
  Article,
  Info,
  Settings,
  Star,
  Chat as DiscordIcon,
} from '@mui/icons-material';
import AppTheme from '../shared-theme/AppTheme';
import AppNavbar from '../newNavBar/AppNavbar';
import SideMenu from '../newNavBar/SideMenu';
import SideMenuMobile from '../newNavBar/SideMenuMobile';
import Header from '../newNavBar/Header';
import { UserProfile } from '../interfaces';
import Campaign from '@mui/icons-material/Campaign';

const Navigation: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user, loading, selectedGuild } = useUser();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  const isAdmin = user?.role === 'admin';
  const isApproved = user?.status === 'approved';

  const navigationGroups = {
    info: [
      { text: 'Home', icon: <Campaign />, path: '/home' },
      ...(user ? [] : [{ text: 'Login', icon: <Article />, path: '/login' }]),
    ],
    guild:
      isApproved && selectedGuild
        ? [
          { text: 'Dashboard', icon: <Dashboard />, path: '/dashboard' },
          { text: 'Sessions', icon: <Group />, path: '/sessions' },
          { text: 'Events', icon: <Group />, path: '/events' },
          { text: 'Wishlist', icon: <Star />, path: '/wishlist' },
        ]
        : [],
    admin: isAdmin
      ? [
        { text: 'DKP Management', icon: <Dashboard />, path: '/dkp-management' },
        { text: 'Admin Logs', icon: <Dashboard />, path: '/admin-logs' },
        { text: 'User Management', icon: <Dashboard />, path: '/user-management' },
        { text: 'Teams', icon: <Group />, path: '/teams' },
      ]
      : [],
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setMobileOpen(newOpen);
  };

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/login');
  };

  if (loading) return null;

  return (
    <AppTheme>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        {/* Mobile Navigation */}
        <AppNavbar
          onMenuClick={toggleDrawer(true)}
          selectedGuild={selectedGuild}
        />
        <SideMenuMobile
          open={mobileOpen}
          toggleDrawer={toggleDrawer}
          navigationGroups={navigationGroups}
          user={user}
          onLogout={handleLogout}
        />

        {/* Desktop Navigation */}
        <SideMenu
          navigationGroups={navigationGroups}
          user={user as unknown as UserProfile | null}
          selectedGuild={selectedGuild}
          onLogout={handleLogout}
        />
      </Box>
    </AppTheme>
  );
};

export default Navigation;
