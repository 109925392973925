import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { logAction, LogActions } from '../services/logginServices';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  FormControl,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  IconButton,
  Popover
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { setDoc, doc } from 'firebase/firestore';

const weapons = [
  "Longbow",
  "Dagger",
  "Crossbow",
  "Greatsword",
  "Staff",
  "Wand",
  "Sword and Shield",
  "Spear"
];

const inGameRoles = [
  "Tank",
  "Healer",
  "DPS",
];

interface CreateUserModalProps {
  open: boolean;
  onClose: () => void;
  guildId: string;
  currentUserId: string;
  onUserCreated: () => void;
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({
  open,
  onClose,
  guildId,
  currentUserId,
  onUserCreated
}) => {
  const [error, setError] = useState<string | null>(null);
  const [userData, setUserData] = useState({
    username: '',
    uid: '',
    inGameName: '',
    primaryWeapon: '',
    secondaryWeapon: '',
    gearScore: 0,
    role: 'member' as 'admin' | 'member' | 'officer',
    inGameRole: [] as string[],
    dkp: 0
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCreate = async () => {
    if (!guildId) return;
    
    // UID validation
    const uidLength = userData.uid.length;
    if (uidLength < 17 || uidLength > 19 || !/^\d+$/.test(userData.uid)) {
        setError("Discord UID must be 17-19 digits long and contain only numbers");
        return;
    }

    if (!userData.username || !userData.uid || !userData.inGameName) {
        setError("Please fill in all required fields");
        return;
    }

    if (userData.inGameRole.length === 0) {
        setError("Please select at least one in-game role");
        return;
    }

    try {
        // Create document with specific ID format
        const userGuildDocId = `${userData.uid}_${guildId}`;
        const userGuildRef = doc(db, "userGuilds", userGuildDocId);
        
        await setDoc(userGuildRef, {
            ...userData,
            guildId,
            userGuildId: userGuildDocId,
            status: "approved",
            timestamp: new Date(),
            wishlist: [],
            wishlistLocked: false,
            wishlistTokens: 3,
            wonItems: []
        });

        await logAction({
            action: LogActions.USER_CREATED,
            actor: currentUserId,
            details: `Created new user ${userData.username}`,
            sessionId: "user-management",
            guild: guildId
        });

        onUserCreated();
        onClose();
        setUserData({
            username: '',
            uid: '',
            inGameName: '',
            primaryWeapon: '',
            secondaryWeapon: '',
            gearScore: 0,
            role: 'member',
            inGameRole: [],
            dkp: 0
        });
    } catch (error: any) {
        console.error("Error creating user:", error);
        setError("Failed to create user");
    }
};

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New User</DialogTitle>
      <DialogContent>
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <TextField
            required
            fullWidth
            label="Username"
            value={userData.username}
            onChange={(e) => setUserData(prev => ({ ...prev, username: e.target.value }))}
          />
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TextField
              required
              fullWidth
              label="Discord UID"
              value={userData.uid}
              onChange={(e) => setUserData(prev => ({ ...prev, uid: e.target.value }))}
            />
            <IconButton
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              size="small"
            >
              <HelpOutlineIcon />
            </IconButton>
            <Popover
              sx={{
                pointerEvents: 'none',
              }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Box sx={{ p: 2, maxWidth: 400 }}>
                <Typography variant="body2" gutterBottom>
                  To find a Discord User ID:
                </Typography>
                <Typography variant="body2" gutterBottom>
                  1. Enable Developer Mode in Discord Settings
                </Typography>
                <Typography variant="body2" gutterBottom>
                  2. Right-click on any user
                </Typography>
                <Typography variant="body2" gutterBottom>
                  3. Click "Copy User ID"
                </Typography>
                <img 
                  src="/images/discorduid.png" 
                  alt="How to find Discord UID" 
                  style={{ width: '100%', marginTop: 8 }} 
                />
              </Box>
            </Popover>
          </Box>

          <TextField
            required
            fullWidth
            label="In-Game Name"
            value={userData.inGameName}
            onChange={(e) => setUserData(prev => ({ ...prev, inGameName: e.target.value }))}
          />
         
          <FormControl fullWidth>
            <InputLabel>Primary Weapon</InputLabel>
            <Select
              value={userData.primaryWeapon}
              onChange={(e) => setUserData(prev => ({ ...prev, primaryWeapon: e.target.value }))}
              label="Primary Weapon"
            >
              {weapons.map((weapon) => (
                <MenuItem key={weapon} value={weapon}>{weapon}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Secondary Weapon</InputLabel>
            <Select
              value={userData.secondaryWeapon}
              onChange={(e) => setUserData(prev => ({ ...prev, secondaryWeapon: e.target.value }))}
              label="Secondary Weapon"
            >
              {weapons.map((weapon) => (
                <MenuItem key={weapon} value={weapon}>{weapon}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            type="number"
            label="Gear Score"
            value={userData.gearScore}
            onChange={(e) => setUserData(prev => ({ ...prev, gearScore: parseInt(e.target.value) || 0 }))}
            inputProps={{ min: "0" }}
          />

          <FormControl>
            <Typography variant="subtitle1">In-Game Roles</Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {inGameRoles.map((role) => (
                <FormControlLabel
                  key={role}
                  control={
                    <Checkbox
                      checked={userData.inGameRole.includes(role)}
                      onChange={(e) => {
                        const updatedRoles = e.target.checked
                          ? [...userData.inGameRole, role]
                          : userData.inGameRole.filter(r => r !== role);
                        setUserData(prev => ({ ...prev, inGameRole: updatedRoles }));
                      }}
                    />
                  }
                  label={role}
                />
              ))}
            </Box>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              value={userData.role}
              onChange={(e) => setUserData(prev => ({ ...prev, role: e.target.value as 'admin' | 'member' | 'officer' }))}
              label="Role"
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="officer">Officer</MenuItem>
              <MenuItem value="member">Member</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCreate} variant="contained" color="primary">
          Create User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserModal;
