import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Stack,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { BidType, BID_PRIORITIES } from '../interfaces';
import TutorialPopover from './common/TutorialPopover';

interface BidModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (bidAmount: number, bidType: BidType, reason?: string) => Promise<void>;
  requireReason?: boolean;
  allowedBidTypes: BidType[];
}

const BidModal: React.FC<BidModalProps> = ({
  open,
  onClose,
  onConfirm,
  requireReason = false,
  allowedBidTypes
}) => {
  const [bidAmount, setBidAmount] = useState<number>(0);
  const [bidReason, setBidReason] = useState<string>('');
  const [selectedBidType, setSelectedBidType] = useState<BidType>(allowedBidTypes[0]);

  const handleConfirm = () => {
    if (bidAmount > 0 && (!requireReason || bidReason.trim())) {
      onConfirm(bidAmount, selectedBidType, bidReason);
      setBidAmount(0);
      setBidReason('');
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        maxWidth: 500,
        width: '90%'
      }}>
        <Stack spacing={3}>
          <Typography variant="h6">
            Enter Bid Amount
          </Typography>

          <Divider />

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TextField
              fullWidth
              type="number"
              label="Bid Amount"
              value={bidAmount}
              onChange={(e) => setBidAmount(Number(e.target.value))}
              InputProps={{ inputProps: { min: 1 } }}
            />
            <TutorialPopover
              title="Bid Amount"
              steps={[{
                title: "Enter the amount of DKP you want to bid. Higher bids have better chances of winning, but the DKP will be deducted from your total if you win"
              }]}
            />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FormControl fullWidth>
              <InputLabel>Bid Type</InputLabel>
              <Select
                value={selectedBidType}
                onChange={(e) => setSelectedBidType(e.target.value as BidType)}
                label="Bid Type"
              >
                {allowedBidTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)} (Priority: {BID_PRIORITIES[type]})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TutorialPopover
              title="Bid Types"
              steps={[{
                title: "Select your bid type. Different types have different priorities: Wishlist (Highest), Trait (Medium), Auction House (Lowest). Higher priority bids take precedence regardless of bid amount"
              }]}
            />
          </Box>

          {requireReason && (
            <Box sx={{ display: 'flex', alignItems: 'start', gap: 1 }}>
              <TextField
                required
                fullWidth
                label="Bid Reason"
                value={bidReason}
                onChange={(e) => setBidReason(e.target.value)}
                placeholder="Enter your reason for bidding..."
                multiline
                rows={2}
              />
              <TutorialPopover
                title="Bid Reason"
                steps={[{
                  title: "Explain why you want this item. This helps loot masters make informed decisions and maintains transparency in loot distribution"
                }]}
              />
            </Box>
          )}

          <Button
            variant="contained"
            fullWidth
            disabled={bidAmount <= 0 || (requireReason && !bidReason.trim())}
            onClick={handleConfirm}
          >
            Confirm Bid
          </Button>

          <Button
            variant="outlined"
            fullWidth
            onClick={onClose}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default BidModal;
