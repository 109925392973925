// LootMethodModal.tsx
import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Switch,
  FormControlLabel,
  Stack,
  Divider,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stepper,
  Step,
  StepLabel,
  FormGroup
} from '@mui/material';
import { BidType, BID_PRIORITIES } from '../interfaces';

interface LootMethodModalProps {
  itemName: string;
  itemImage: string;
  isCustom: boolean;
  onConfirm: (
    method: 'dkp' | 'roll',
    isPrivateBidding: boolean,
    minIncrement: number,
    selectedTrait: string,
    itemName?: string,
    wishlistOnly?: boolean,
    wishlistVerification?: {
      method: 'none' | 'session' | 'custom';
      hours?: number;
    },
    requireBidReason?: boolean,
    startingBid?: number,
    allowedBidTypes?: BidType[]
  ) => void;
  onCancel: () => void;
}

const steps = ['Select Method', 'Configure Settings', 'Review & Confirm'];

const LootMethodModal: React.FC<LootMethodModalProps> = ({
  itemName,
  itemImage,
  isCustom,
  onConfirm,
  onCancel,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [method, setMethod] = useState<'dkp' | 'roll' | ''>('');
  const [isPrivateBidding, setIsPrivateBidding] = useState(false);
  const [minIncrement, setMinIncrement] = useState(1);
  const [selectedTrait, setSelectedTrait] = useState('');
  const [customItemName, setCustomItemName] = useState(itemName);
  const [wishlistOnly, setWishlistOnly] = useState(false);
  const [wishlistTimeCheck, setWishlistTimeCheck] = useState<'none' | 'session' | 'custom'>('none');
  const [customWishlistTime, setCustomWishlistTime] = useState(24);
  const [requireBidReason, setRequireBidReason] = useState(false);
  const [startingBid, setStartingBid] = useState(0);
  const [allowedBidTypes, setAllowedBidTypes] = useState<BidType[]>([
    BidType.WISHLIST,
    BidType.TRAIT,
    BidType.AUCTIONHOUSE,
  ]);

  const handleNext = () => {
    if (activeStep === 0 && method === '') return;
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const canConfirm =
    selectedTrait.trim() &&
    (!isCustom || customItemName.trim()) &&
    (method === 'roll' || allowedBidTypes.length > 0);

  return (
    <Modal open={true} onClose={onCancel}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxWidth: 600,
          width: '90%',
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        <Stack spacing={3}>
          {/* Header */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              component="img"
              src={itemImage}
              alt={itemName}
              sx={{ width: 64, height: 64, borderRadius: 1 }}
            />
            <Typography variant="h6">
              {isCustom ? 'Add Custom Item to Loot Pool' : `Add "${itemName}" to Loot Pool`}
            </Typography>
          </Box>

          {/* Stepper */}
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* Step Content */}
          {activeStep === 0 && (
            <Box>
              <Typography variant="subtitle1">Select Loot Method</Typography>
              <Stack spacing={2}>
                <Button
                  variant={method === 'dkp' ? 'contained' : 'outlined'}
                  onClick={() => setMethod('dkp')}
                >
                  DKP Bidding
                </Button>
                <Button
                  variant={method === 'roll' ? 'contained' : 'outlined'}
                  onClick={() => setMethod('roll')}
                >
                  Random Roll
                </Button>
              </Stack>
            </Box>
          )}

          {activeStep === 1 && (
            <Box>
              <Typography variant="subtitle1">Configure Settings</Typography>
              <Stack spacing={2}>
                {isCustom && (
                  <TextField
                    fullWidth
                    label="Item Name"
                    value={customItemName}
                    onChange={(e) => setCustomItemName(e.target.value)}
                    placeholder="Enter item name..."
                  />
                )}
                <TextField
                  required
                  fullWidth
                  label="Trait"
                  value={selectedTrait}
                  onChange={(e) => setSelectedTrait(e.target.value)}
                  placeholder="Enter item trait..."
                  helperText="Trait is required (e.g., 'Crit Damage')"
                />
                {method === 'dkp' && (
                  <>
                    <TextField
                      type="number"
                      label="Starting Bid"
                      value={startingBid}
                      onChange={(e) => setStartingBid(parseInt(e.target.value))}
                      InputProps={{ inputProps: { min: 0 } }}
                      helperText="Set the starting bid amount"
                      fullWidth
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isPrivateBidding}
                          onChange={(e) => setIsPrivateBidding(e.target.checked)}
                        />
                      }
                      label="Private Bidding"
                    />
                    <TextField
                      type="number"
                      label="Minimum Bid Increment"
                      value={minIncrement}
                      onChange={(e) => setMinIncrement(parseInt(e.target.value))}
                      InputProps={{ inputProps: { min: 1 } }}
                      helperText="Set the minimum increment for bids"
                      fullWidth
                    />
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle1">Allowed Bid Types</Typography>
                      <FormGroup>
                        {Object.values(BidType).map((bidType) => (
                          <FormControlLabel
                            key={bidType}
                            control={
                              <Checkbox
                                checked={allowedBidTypes.includes(bidType)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setAllowedBidTypes([...allowedBidTypes, bidType]);
                                  } else {
                                    setAllowedBidTypes(
                                      allowedBidTypes.filter((type) => type !== bidType)
                                    );
                                  }
                                }}
                              />
                            }
                            label={bidType.charAt(0).toUpperCase() + bidType.slice(1)}
                          />
                        ))}
                      </FormGroup>
                    </Box>
                  </>
                )}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1">Additional Settings</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={wishlistOnly}
                        onChange={(e) => setWishlistOnly(e.target.checked)}
                      />
                    }
                    label="Wishlist Only"
                  />
                  {wishlistOnly && (
                    <Box sx={{ pl: 3 }}>
                      <FormControl component="fieldset">
                        <FormLabel>Wishlist Verification</FormLabel>
                        <RadioGroup
                          value={wishlistTimeCheck}
                          onChange={(e) =>
                            setWishlistTimeCheck(e.target.value as 'none' | 'session' | 'custom')
                          }
                        >
                          <FormControlLabel
                            value="session"
                            control={<Radio />}
                            label="Session Start Check"
                          />
                          <FormControlLabel
                            value="custom"
                            control={<Radio />}
                            label="Time-based Check"
                          />
                        </RadioGroup>
                      </FormControl>
                      {wishlistTimeCheck === 'custom' && (
                        <TextField
                          type="number"
                          label="Hours Before Now"
                          value={customWishlistTime}
                          onChange={(e) => setCustomWishlistTime(parseInt(e.target.value))}
                          InputProps={{ inputProps: { min: 1 } }}
                        />
                      )}
                    </Box>
                  )}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={requireBidReason}
                        onChange={(e) => setRequireBidReason(e.target.checked)}
                      />
                    }
                    label="Require Reason"
                  />
                </Box>
              </Stack>
            </Box>
          )}

          {activeStep === 2 && (
            <Box>
              <Typography variant="subtitle1">Review & Confirm</Typography>
              <Stack spacing={2}>
                <Typography>
                  <strong>Method:</strong> {method === 'dkp' ? 'DKP Bidding' : 'Random Roll'}
                </Typography>
                {isCustom && (
                  <Typography>
                    <strong>Item Name:</strong> {customItemName}
                  </Typography>
                )}
                <Typography>
                  <strong>Trait:</strong> {selectedTrait}
                </Typography>
                {method === 'dkp' && (
                  <>
                    <Typography>
                      <strong>Starting Bid:</strong> {startingBid}
                    </Typography>
                    <Typography>
                      <strong>Private Bidding:</strong> {isPrivateBidding ? 'Yes' : 'No'}
                    </Typography>
                    <Typography>
                      <strong>Minimum Increment:</strong> {minIncrement}
                    </Typography>
                    <Typography>
                      <strong>Allowed Bid Types:</strong> {allowedBidTypes.join(', ')}
                    </Typography>
                  </>
                )}
                <Typography>
                  <strong>Wishlist Only:</strong> {wishlistOnly ? 'Yes' : 'No'}
                </Typography>
                {wishlistOnly && (
                  <Typography>
                    <strong>Wishlist Verification:</strong> {wishlistTimeCheck}
                    {wishlistTimeCheck === 'custom' && ` (${customWishlistTime} hours)`}
                  </Typography>
                )}
                <Typography>
                  <strong>Require Reason:</strong> {requireBidReason ? 'Yes' : 'No'}
                </Typography>
              </Stack>
            </Box>
          )}

          {/* Navigation Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            {activeStep < steps.length - 1 && (
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            )}
            {activeStep === steps.length - 1 && (
              <Button
                variant="contained"
                disabled={!canConfirm}
                onClick={() =>
                  onConfirm(
                    method as 'dkp' | 'roll',
                    isPrivateBidding,
                    minIncrement,
                    selectedTrait,
                    isCustom ? customItemName : undefined,
                    wishlistOnly,
                    wishlistOnly
                      ? {
                        method: wishlistTimeCheck,
                        hours: wishlistTimeCheck === 'custom' ? customWishlistTime : undefined,
                      }
                      : undefined,
                    requireBidReason,
                    startingBid,
                    allowedBidTypes
                  )
                }
              >
                Confirm
              </Button>
            )}
          </Box>
          {activeStep === 0 && (
            <Button onClick={onCancel} sx={{ mt: 2 }}>
              Cancel
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default LootMethodModal;
