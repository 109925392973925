import React from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SessionTimer from '../SessionTimer';
import TutorialPopover from '../../common/TutorialPopover';

interface SessionInfoProps {
  session: any;
  selectedGuild: string;
  linkedEvent: any;
  isLootmasterOrAdmin: boolean;
  availableEvents: any[];
  onLinkEvent: (eventId: string) => void;
  onExpire?: () => void;
}

export const SessionInfo: React.FC<SessionInfoProps> = ({
  session,
  selectedGuild,
  linkedEvent,
  isLootmasterOrAdmin,
  availableEvents,
  onLinkEvent,
  onExpire
}) => {
  const theme = useTheme();

  return (
    <Box sx={{
      bgcolor: theme.palette.background.paper,
      borderRadius: 2,
      p: { xs: 2, sm: 3, md: 4 },
      boxShadow: 3,
      mb: 3
    }}>
      {session && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              Session: {session.name}
            </Typography>
          </Box>

          <Typography
            variant="body1"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              mb: 2
            }}
          >
            Guild: {selectedGuild}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <SessionTimer
              expiresAt={session?.expiresAt || null}
              onExpire={onExpire}
            />
            <TutorialPopover
              title="Session Timer"
              steps={[{
                title: "Shows the remaining time for this loot session. When the timer expires, no new bids or rolls will be accepted"
              }]}
            />
          </Box>

          {isLootmasterOrAdmin && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Link Event</InputLabel>
                <Select
                  value={linkedEvent?.id || ''}
                  label="Link Event"
                  onChange={(event) => onLinkEvent(event.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {availableEvents.map((event) => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TutorialPopover
                title="Event Linking"
                steps={[{
                  title: "Connect this loot session to a specific event to track loot distribution and DKP awards for that event's participants, people cannot bid/roll if they are not a participant of the linked event"
                }]}
              />
            </Box>
          )}

          {linkedEvent && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
              <Box sx={{ p: 2, bgcolor: theme.palette.grey[100], borderRadius: 1, flexGrow: 1 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                  Linked Event: {linkedEvent.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Participants: {linkedEvent.registeredUsers?.length || 0}/{linkedEvent.maxParticipants}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  DKP Value: {linkedEvent.dkpValue || 0}
                </Typography>
              </Box>
              <TutorialPopover
                title="Event Details"
                steps={[{
                  title: "Shows key information about the linked event including participant count and DKP value. Only registered participants can participate in the loot session"
                }]}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
