import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

export const isSessionExpired = async (
  guildId: string,
  sessionId: string
): Promise<boolean> => {
  try {
    // Update the path to include the guildId
    const sessionRef = doc(db, "sessions", guildId, "sessions", sessionId);
    const sessionDoc = await getDoc(sessionRef);

    if (!sessionDoc.exists()) {
      console.error("Session document does not exist.");
      return true;
    }

    const sessionData = sessionDoc.data();
    const expiresAt = sessionData?.expiresAt?.toDate();
    const now = new Date();

    return expiresAt ? now > expiresAt : false;
  } catch (error) {
    console.error("Error checking session expiration:", error);
    return true;
  }
};
