import { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  doc,
  onSnapshot,
  query,
  orderBy,
  getCountFromServer,
} from "firebase/firestore";
import { LootDrop, Session } from "../interfaces";

interface UseSessionFirestoreResult {
  session: Session | null;
  participants: string[];
  lootDrops: LootDrop[];
  loading: {
    session: boolean;
    loot: boolean;
  };
  fetchMoreLootDrops: (page: number) => void;
  totalLootDropsCount: number;
}

const useSessionFirestore = (
  sessionId?: string,
  guildId?: string
): UseSessionFirestoreResult => {
  const [session, setSession] = useState<Session | null>(null);
  const [participants, setParticipants] = useState<string[]>([]);
  const [lootDrops, setLootDrops] = useState<LootDrop[]>([]);
  const [loading, setLoading] = useState({
    session: true,
    loot: true,
  });

  const itemsPerPage = 10;
  const [totalLootDropsCount, setTotalLootDropsCount] = useState(0);

  useEffect(() => {
    if (!sessionId || !guildId) {
      setLoading({ session: false, loot: false });
      return;
    }

    const sessionRef = doc(db, "sessions", guildId, "sessions", sessionId);
    const unsubscribeSession = onSnapshot(sessionRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data() as Session;
        setSession({
          ...data,
          id: docSnap.id,
        });
      } else {
        setSession(null);
      }
      setLoading((prev) => ({ ...prev, session: false }));
    });

    const lootRef = collection(
      db,
      "sessions",
      guildId,
      "sessions",
      sessionId,
      "lootDrops"
    );

    const lootQuery = query(lootRef, orderBy("itemName", "asc"));

    const unsubscribeLoot = onSnapshot(lootQuery, (snapshot) => {
      const newLootDrops = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt?.toDate(),
      })) as LootDrop[];

      setLootDrops(newLootDrops);
      setLoading((prev) => ({ ...prev, loot: false }));
    });

    const getTotalLootDropsCount = async () => {
      const snapshot = await getCountFromServer(lootRef);
      setTotalLootDropsCount(snapshot.data().count);
    };
    getTotalLootDropsCount();

    return () => {
      unsubscribeSession();
      unsubscribeLoot();
    };
  }, [sessionId, guildId]);

  useEffect(() => {
    if (!sessionId || !guildId) return;

    const participantsRef = collection(
      db,
      "sessions",
      guildId,
      "sessions",
      sessionId,
      "participants"
    );
    const unsubscribeParticipants = onSnapshot(participantsRef, (snapshot) => {
      const participantsList = snapshot.docs.map((doc) => doc.id);
      setParticipants(participantsList);
    });

    return () => unsubscribeParticipants();
  }, [sessionId, guildId]);

  return {
    session,
    participants,
    lootDrops,
    loading,
    fetchMoreLootDrops: () => {}, // Adjust as needed
    totalLootDropsCount,
  };
};

export default useSessionFirestore;
