// handleBid.ts
import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  writeBatch,
  serverTimestamp,
  increment,
  runTransaction,
} from "firebase/firestore";
import { UserGuild, LootDrop, Session, BidType, BID_PRIORITIES } from "../interfaces";
import { HandleBidProps } from "../interfaces/handlers";
import { logAction, LogActions } from "../services/logginServices";
import { verifyWishlistStatus } from "../components/Wishlist/WishListServices";
import { checkEventRegistration } from "../services/eventServices";
import { isSessionExpired } from "../components/sessions/checkExpire";
import { shardLogger } from "../services/shardLogger";

// Adjusted sharded counter helper function
const incrementShardedCounter = (
  guildId: string,
  sessionId: string,
  lootId: string,
  amount: number
) => {
  const NUM_SHARDS = 10;
  const shardId = Math.floor(Math.random() * NUM_SHARDS);

  const counterRef = doc(
    db,
    "sessions",
    guildId,
    "sessions",
    sessionId,
    "lootDrops",
    lootId,
    "_counters",
    `shard_${shardId}`
  );

  return {
    ref: counterRef,
    update: { total: increment(amount) },
  };
};

interface WishlistVerification {
  method: "session" | "none" | "custom";
  hours?: number;
}

export const handleBid = async ({
  lootId,
  bidAmount,
  bidType,
  user,
  sessionId,
  selectedGuild,
  session,
  reason,
  setConfirmationTitle,
  setConfirmationMessage,
  setIsError,
  setConfirmationModalVisible,
  setSuccess,
}: HandleBidProps) => {
  if (!user || !sessionId || !selectedGuild) return;

  // Check if the session has expired
  const expired = await isSessionExpired(selectedGuild, sessionId);

  if (expired) {
    setConfirmationTitle("Session Expired");
    setConfirmationMessage("This session has expired. No more bids can be made.");
    setIsError(true);
    setConfirmationModalVisible(true);
    return;
  }

  try {
    console.log("Starting handleBid function");

    // Fetch session and loot data outside the transaction
    console.log("Fetching session data");
    const sessionRef = doc(db, "sessions", selectedGuild, "sessions", sessionId);
    const sessionDoc = await getDoc(sessionRef);
    if (!sessionDoc.exists()) {
      throw new Error("Session not found.");
    }
    const sessionData = sessionDoc.data() as Session;
    const guildId = sessionData.guild;

    console.log("Fetching loot data");
    const lootRef = doc(
      db,
      "sessions",
      selectedGuild,
      "sessions",
      sessionId,
      "lootDrops",
      lootId
    );
    const lootDoc = await getDoc(lootRef);
    if (!lootDoc.exists()) {
      throw new Error("Loot item not found.");
    }
    const lootData = lootDoc.data() as LootDrop;

    // Verify wishlist status if required
    if (lootData.wishlistOnly === true) {
      console.log("Verifying wishlist status");
      const verification: WishlistVerification =
        typeof lootData.wishlistVerification === "string"
          ? { method: "none" }
          : (lootData.wishlistVerification as WishlistVerification);
      const wishlistStatus = await verifyWishlistStatus(
        user.uid,
        lootData.itemName,
        session,
        verification,
        false
      );

      if (!wishlistStatus.isEligible) {
        const hours = verification.hours || 0;
        const message = hours > 0
          ? `This item requires you to have it in your wishlist for at least ${hours} hours before bidding.`
          : wishlistStatus.message || "This item requires you to have it in your wishlist to bid.";
        throw new Error(message);
      }
    }

    // Check event registration if applicable
    console.log("Checking event registration");
    const canParticipate = await checkEventRegistration(
      sessionId,
      user.uid,
      selectedGuild
    );

    if (!canParticipate) {
      throw new Error("You must be registered for the linked event to participate.");
    }

    // Fetch user guild data
    console.log("Fetching user guild data");
    const userGuildRef = doc(db, "userGuilds", `${user.uid}_${guildId}`);
    const userGuildDoc = await getDoc(userGuildRef);

    if (!userGuildDoc.exists()) {
      throw new Error("User guild data not found.");
    }
    const userGuildData = userGuildDoc.data() as UserGuild;

    // Check DKP balance
    console.log("Checking DKP balance");
    if ((userGuildData.dkp || 0) < bidAmount) {
      throw new Error("You do not have enough DKP to place this bid.");
    }

    // Compute sharded counter update outside the transaction
    console.log("Computing sharded counter update");
    const shardedCounterUpdate = incrementShardedCounter(
      selectedGuild,
      sessionId,
      lootId,
      1
    );

    let canSeeBids = false; // Define canSeeBids here
    let previousBidderId: string | null = null;
    let previousBidAmount: number = 0;
    let previousBidderName: string = "";
    const lootItemName = lootData.itemName || "Unknown Item";
    const userGuildUsername = userGuildData.username || user.displayName || "Unknown";
    let previousUserGuildData: UserGuild | null = null;

    console.log("Starting transaction");
    await runTransaction(db, async (transaction) => {
      // Re-fetch loot data inside the transaction
      console.log("Transaction: Fetching loot data");
      const lootDocTxn = await transaction.get(lootRef);
      const lootDataTxn = lootDocTxn.data() as LootDrop;

      // Check if the item is already distributed
      if (lootDataTxn.status === "distributed") {
        throw new Error(
          "This item has already been distributed and cannot be bid on."
        );
      }

      // Check bid priorities
      if (
        lootDataTxn.currentBidType &&
        BID_PRIORITIES[lootDataTxn.currentBidType] > BID_PRIORITIES[bidType]
      ) {
        throw new Error(
          `Cannot outbid a higher priority bid type (${lootDataTxn.currentBidType}).`
        );
      }

      // Check if user is already the highest bidder
      const isLootmasterOrAdmin = user.uid === sessionData.lootmaster;
      canSeeBids =
        !lootDataTxn.isPrivateBidding ||
        isLootmasterOrAdmin ||
        user?.role === "admin";

      if (lootDataTxn.currentBidder === user.uid) {
        throw new Error(
          canSeeBids
            ? "You are currently winning this item!"
            : `You are currently winning with a bid of ${lootDataTxn.currentBid} DKP!`
        );
      }

      // Validate bid amount
      const nextMinimumBidTxn =
        lootDataTxn.currentBid + (lootDataTxn.minIncrement || 1);
      const minimumBidTxn = Math.max(
        lootDataTxn.startingBid || 1,
        nextMinimumBidTxn
      );

      if (bidAmount < minimumBidTxn) {
        throw new Error(
          `Your bid must be at least ${minimumBidTxn} DKP (current bid + minimum increment, or starting bid).`
        );
      }

      // Refund previous bidder if applicable
      if (lootDataTxn.currentBidder && lootDataTxn.currentBid > 0) {
        console.log("Transaction: Fetching previous bidder data");
        previousBidderId = lootDataTxn.currentBidder;
        previousBidAmount = lootDataTxn.currentBid;

        const previousUserGuildRef = doc(
          db,
          "userGuilds",
          `${previousBidderId}_${guildId}`
        );
        const previousUserGuildDoc = await transaction.get(previousUserGuildRef);

        if (previousUserGuildDoc.exists()) {
          previousUserGuildData = previousUserGuildDoc.data() as UserGuild;
          previousBidderName = previousUserGuildData.username || "Unknown";
        } else {
          previousUserGuildData = null;
          previousBidderName = "Unknown";
        }
      }

      // All reads are done before writes now

      console.log("Transaction: Creating new bid");
      // Create new bid
      const bidRef = doc(
        collection(
          db,
          "sessions",
          selectedGuild,
          "sessions",
          sessionId,
          "lootDrops",
          lootId,
          "bids"
        )
      );
      transaction.set(bidRef, {
        bidderId: user.uid,
        bidderName: userGuildUsername,
        amount: bidAmount,
        bidType: bidType,
        timestamp: serverTimestamp(),
        reason: reason || "No reason provided",
      });

      console.log("Transaction: Updating aggregate data");
      // Update aggregate data
      const aggregateRef = doc(
        db,
        "sessions",
        selectedGuild,
        "sessions",
        sessionId,
        "aggregates",
        "lootData"
      );

      transaction.set(
        aggregateRef,
        {
          currentBids: {},
          bidCounts: {},
          highestRolls: {},
          rollCounts: {},
        },
        { merge: true }
      );

      transaction.set(
        shardedCounterUpdate.ref,
        shardedCounterUpdate.update,
        { merge: true }
      );
      transaction.update(aggregateRef, {
        [`currentBids.${lootId}`]: bidAmount,
        [`bidCounts.${lootId}`]: increment(1),
      });

      console.log("Transaction: Refunding previous bidder if applicable");
      // Refund previous bidder if applicable
      if (previousBidderId && previousBidAmount > 0 && previousUserGuildData) {
        // Refund DKP
        const previousUserGuildRef = doc(
          db,
          "userGuilds",
          `${previousBidderId}_${guildId}`
        );
        transaction.update(previousUserGuildRef, {
          dkp: (previousUserGuildData.dkp || 0) + previousBidAmount,
        });

        // Do not call logAction inside transaction
      }

      console.log("Transaction: Deducting DKP from current bidder");
      // Deduct DKP from current bidder
      transaction.update(userGuildRef, {
        dkp: (userGuildData.dkp || 0) - bidAmount,
      });

      console.log("Transaction: Updating loot data");
      // Update loot data
      transaction.update(lootRef, {
        currentBid: bidAmount,
        currentBidder: user.uid,
        currentBidderName: userGuildUsername,
        currentBidType: bidType,
      });
    });

    console.log("Transaction completed successfully");

    console.log("Logging actions");
    // Perform logging outside the transaction
    if (previousBidderId && previousBidAmount > 0) {
      await logAction({
        action: LogActions.BID_REFUNDED,
        actor: "system",
        details: `Refunded ${previousBidAmount} DKP to ${previousBidderName} for item ${lootItemName} (Outbid)`,
        sessionId,
        guild: selectedGuild,
      });
    }

    await logAction({
      action: LogActions.BID_PLACED,
      actor: user.uid,
      details: `Placed bid of ${bidAmount} DKP (type: ${bidType}) on item ${lootItemName}`,
      sessionId,
      guild: selectedGuild,
    });

    console.log("Setting success messages");
    setSuccess("Bid placed successfully!");
    setConfirmationTitle("Bid Successful");
    setConfirmationMessage(
      `Your bid of ${bidAmount} DKP has been placed${canSeeBids ? "." : " and you are now the highest bidder!"
      }`
    );
    setIsError(false);
    setConfirmationModalVisible(true);
  } catch (error: any) {
    console.error("Error in handleBid:", error);
    setConfirmationTitle("Error");
    setConfirmationMessage(error.message);
    setIsError(true);
    setConfirmationModalVisible(true);
  }
};
