// src/components/teams/AttendanceMarking.tsx

import React, { useState, useEffect } from 'react';
import { Paper, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { collection, query, where, getDocs, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';

interface AttendanceMarkingProps {
    teams: {
        name: string;
        members: string[];
        leader: string;
        secondLeader?: string;
    }[];
    user: any;
}

const AttendanceMarking: React.FC<AttendanceMarkingProps> = ({ teams, user }) => {
    const { selectedGuild } = useUser();
    const [team, setTeam] = useState<any>(null);
    const [member, setMember] = useState('');
    const [event, setEvent] = useState('');
    const [events, setEvents] = useState<any[]>([]);

    useEffect(() => {
        const fetchEvents = async () => {
            if (!selectedGuild) return;
            const q = query(collection(db, 'events'), where('guildId', '==', selectedGuild), where('mandatory', '==', true));
            const querySnapshot = await getDocs(q);
            const fetchedEvents = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setEvents(fetchedEvents);
        };

        fetchEvents();
    }, [selectedGuild]);

    useEffect(() => {
        const userTeam = teams.find(t => t.leader === user.username || t.secondLeader === user.username);
        if (userTeam) setTeam(userTeam);
    }, [teams, user]);

    const handleMarkAbsent = async () => {
        if (!selectedGuild || !team || !member || !event) return;
        const attendanceDocRef = doc(db, 'attendance', selectedGuild, 'events', event);
        await updateDoc(attendanceDocRef, {
            absentees: arrayUnion(member)
        });
        alert('Marked as absent');
    };

    if (!team) {
        return (
            <Paper sx={{ padding: 2, marginTop: 2 }}>
                <Typography variant="h6">You are not a Team Leader</Typography>
            </Paper>
        );
    }

    return (
        <Paper sx={{ padding: 2, marginTop: 2 }}>
            <Typography variant="h6">Mark Absence for Mandatory Event</Typography>
            <FormControl fullWidth sx={{ marginBottom: 2, marginTop: 2 }}>
                <InputLabel>Select Member</InputLabel>
                <Select
                    value={member}
                    onChange={(e) => setMember(e.target.value)}
                >
                    {team.members.map((memberName: string, index: number) => (
                        <MenuItem key={index} value={memberName}>{memberName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Select Mandatory Event</InputLabel>
                <Select
                    value={event}
                    onChange={(e) => setEvent(e.target.value)}
                >
                    {events.map((evt) => (
                        <MenuItem key={evt.id} value={evt.id}>{evt.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button variant="contained" onClick={handleMarkAbsent} fullWidth>
                Mark Absent
            </Button>
        </Paper>
    );
};

export default AttendanceMarking;
