import { collection, query, orderBy, getDocs } from 'firebase/firestore';

export interface LastDKPData {
    timestamp: Date;
    inGameName: string;
}

export const checkInactivity = (lastDKPDate: Date | null, threshold: number): boolean => {
    if (!lastDKPDate) {
        console.log('No last DKP date found, marking as inactive.');
        return true;
    }

    const currentDate = new Date();
    const differenceInDays = (currentDate.getTime() - lastDKPDate.getTime()) / (1000 * 3600 * 24);
    console.log(`Checking inactivity: Last DKP Date = ${lastDKPDate}, Current Date = ${currentDate}, Difference in Days = ${differenceInDays}, Threshold = ${threshold}`);

    return differenceInDays > threshold;
};

export const fetchLastDKPDates = async (db: any, selectedGuild: string, usernameMap: { [key: string]: string }) => {
    console.log('Starting fetchLastDKPDates for guild:', selectedGuild);
    console.log('Username Map:', usernameMap); // Debug log
    
    const adminLogsCollection = collection(db, 'guilds', selectedGuild, 'adminLogs', 'DKP_AWARDED', 'logs');
    const subscribedLogsCollection = collection(db, 'subscribedLogs', selectedGuild, 'DKP_AWARDED');

    const adminLogsQuery = query(adminLogsCollection, orderBy('timestamp', 'desc'));
    const subscribedLogsQuery = query(subscribedLogsCollection, orderBy('timestamp', 'desc'));

    const [adminLogsSnapshot, subscribedLogsSnapshot] = await Promise.all([
        getDocs(adminLogsQuery),
        getDocs(subscribedLogsQuery)
    ]);

    const dkpMap: { [key: string]: Date } = {};

    
    const processLogs = (logsSnapshot: any) => {
        logsSnapshot.forEach((logDoc: any) => {
            const logData = logDoc.data();
            const details = logData.details as string;
            const timestamp = logData.timestamp.toDate();
    
            console.log('Processing log details:', details);
    
            let usernames: string[] = [];
    
            // Handle multiple users case first
            const multipleUsersMatch = details.match(/Users affected: \((.*?)\)/);
            if (multipleUsersMatch) {
                usernames = multipleUsersMatch[1].split('), (').map(name =>
                    name.replace(/[()]/g, '').trim()
                );
            } else {
                // Handle split DKP case
                const splitMatch = details.match(/Split \d+ DKP between users: \((.*?)\)/);
                if (splitMatch) {
                    usernames = splitMatch[1].split('), (').map(name =>
                        name.replace(/[()]/g, '').trim()
                    );
                } else {
                    // Handle "Added DKP to users" case
                    const addedToUsersMatch = details.match(/Added \d+ DKP to users: \((.*?)\)/);
                    if (addedToUsersMatch) {
                        usernames = addedToUsersMatch[1].split('), (').map(name =>
                            name.replace(/[()]/g, '').trim()
                        );
                    } else {
                        // Handle single user case
                        const singleUserMatch = details.match(/User (.+?) received/);
                        if (singleUserMatch) {
                            usernames = [singleUserMatch[1].trim()];
                        }
                    }
                }
            }
    
            // Process each username
            usernames.forEach(username => {
                const inGameName = usernameMap[username.toLowerCase()];
                if (inGameName && (!dkpMap[inGameName] || timestamp > dkpMap[inGameName])) {
                    dkpMap[inGameName] = timestamp;
                    console.log(`Updated DKP timestamp for ${inGameName}: ${timestamp}`);
                }
            });
        });
    };
    

    processLogs(adminLogsSnapshot);
    processLogs(subscribedLogsSnapshot);

    console.log('Final DKP Map:', dkpMap);
    return dkpMap;
};
