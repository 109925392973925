import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';


const TermsOfService: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        py: 4,
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Terms of Service
        </Typography>
        <Typography paragraph>Last Updated: 03/12/2024</Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          1. Acceptance of Terms
        </Typography>
        <Typography paragraph>
          By accessing LootManager through Discord authentication and using the LootManager application ("App"), you acknowledge that you have read, understood, and agree to be bound by these Terms, our Privacy Policy, and Discord's Terms of Service. If you do not agree with these Terms, you must not use the Service.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          2. Description of Service
        </Typography>
        <Typography paragraph>
          LootManager is a Discord-integrated application designed to help users manage and organize virtual loot and inventory across various gaming platforms. The Service provides tools for tracking items, optimizing inventory, and enhancing your gaming experience through seamless Discord integration.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          3. Eligibility
        </Typography>
        <Typography paragraph>
          To use LootManager, you must meet the following criteria:
        </Typography>
        <Typography component="ul">
          <li>Be at least 13 years old</li>
          <li>Have an active Discord account</li>
          <li>Comply with Discord's Terms of Service and Community Guidelines</li>
        </Typography>
        <Typography paragraph>
          If you are under 18, you represent that you have your parent or guardian's permission to use the Service. By using the Service, you confirm that your use complies with applicable local laws, including Canada's Personal Information Protection and Electronic Documents Act (PIPEDA) and other privacy regulations.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          4. Discord Authentication and Account Management
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          4.1 Discord Authentication
        </Typography>
        <Typography paragraph>
          Users must authenticate and access LootManager exclusively through their Discord account. By connecting your Discord account, you:
        </Typography>
        <Typography component="ul">
          <li>Grant LootManager permission to access basic Discord profile information</li>
          <li>Agree to maintain the confidentiality of your Discord account</li>
          <li>Understand that disconnecting your Discord account may limit or terminate access to LootManager</li>
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          4.2 Account Security
        </Typography>
        <Typography paragraph>
          You are responsible for maintaining the security of your Discord account. We recommend:
        </Typography>
        <Typography component="ul">
          <li>Using Discord's two-factor authentication</li>
          <li>Not sharing your credentials with third parties</li>
          <li>Regularly monitoring your account for unauthorized access</li>
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          4.3 Account Termination
        </Typography>
        <Typography paragraph>
          We reserve the right to suspend or terminate your account access if:
        </Typography>
        <Typography component="ul">
          <li>You violate these Terms or Discord's Terms of Service</li>
          <li>Your Discord account is suspended or terminated</li>
          <li>We detect fraudulent or unauthorized activity</li>
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          5. User Conduct
        </Typography>
        <Typography paragraph>
          You agree not to engage in any prohibited activities, including but not limited to:
        </Typography>
        <Typography component="ul">
          <li>Violating any applicable laws or regulations</li>
          <li>Attempting to interfere with or compromise the integrity or security of the Service</li>
          <li>Engaging in harassing, threatening, or defamatory conduct</li>
          <li>Using automated systems to extract data without our written permission</li>
          <li>Misusing Discord's authentication or platform features</li>
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          6. Data Collection and Privacy
        </Typography>
        <Typography paragraph>
          We collect and use your Discord account information in accordance with our Privacy Policy and Discord's Developer Terms of Service. This may include:
        </Typography>
        <Typography component="ul">
          <li>Basic profile information</li>
          <li>Server membership details necessary for service functionality</li>
          <li>Authentication tokens for secure access</li>
        </Typography>
        <Typography paragraph>
          We commit to protecting your data and will only use Discord-provided information for the intended purposes of providing and improving our Service.
        </Typography>
{/* These sections remain consistent with the original document */}
<Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
  7. Intellectual Property Rights
</Typography>
<Typography paragraph>
  All content and materials on the Service are owned by us or our licensors and are protected by intellectual property laws. You are granted a limited, non-exclusive license to use the Service for personal, non-commercial purposes.
</Typography>
<Typography paragraph>
  You may not reproduce, distribute, or create derivative works without our written consent, except as expressly permitted by law. Creating backups for personal use is permitted.
</Typography>

<Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
  8. User-Generated Content
</Typography>
<Typography paragraph>
  By submitting content through the Service, you grant us a worldwide, royalty-free, non-exclusive, and revocable license to use, modify, and display such content solely for the purpose of providing the Service.
</Typography>
<Typography paragraph>
  You retain ownership of the content you upload, subject to this license. You agree not to upload content that violates applicable laws or infringes on the intellectual property or privacy rights of others.
</Typography>

<Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
  9. Privacy Policy
</Typography>
<Typography paragraph>
  Your use of the Service is governed by our Privacy Policy, which explains how we collect, use, and protect your personal information in accordance with PIPEDA and GDPR.
</Typography>

<Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
  10. Termination
</Typography>
<Typography variant="subtitle1" gutterBottom>
  10.1 By You
</Typography>
<Typography paragraph>
  You may stop using the Service at any time.
</Typography>
<Typography variant="subtitle1" gutterBottom>
  10.2 By Us
</Typography>
<Typography paragraph>
  We may suspend or terminate your access to the Service if you violate these Terms or for other valid reasons, provided that termination complies with applicable Canadian laws.
</Typography>
<Typography variant="subtitle1" gutterBottom>
  10.3 Effect of Termination
</Typography>
<Typography paragraph>
  Upon termination, all licenses and rights granted to you under these Terms will cease immediately.
</Typography>

<Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
  11. Force Majeure
</Typography>
<Typography paragraph>
  We are not responsible for delays or failure to provide the Service due to events beyond our reasonable control, including but not limited to acts of God, natural disasters, technical failures, or government actions.
</Typography>

<Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
  12. Disclaimers
</Typography>
<Typography paragraph>
  The Service is provided "AS IS" without warranties of any kind. We do not guarantee uninterrupted or error-free operation of the Service. Where laws imply warranties that cannot be excluded, our liability is limited to the extent permitted by law.
</Typography>

<Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
  13. Limitation of Liability
</Typography>
<Typography paragraph>
  To the fullest extent permitted by Canadian law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of the Service. This limitation does not exclude liability for gross negligence, willful misconduct, or other liabilities that cannot be limited under Canadian law.
</Typography>

<Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
  14. Modifications to Terms
</Typography>
<Typography paragraph>
  We may update these Terms from time to time. You will be notified of any material changes via email or an in-app notice. Continued use of the Service after changes are made constitutes acceptance of the revised Terms.
</Typography>

<Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
  15. Governing Law and Dispute Resolution
</Typography>
<Typography paragraph>
  These Terms are governed by the laws of Quebec, Canada. Any disputes shall be resolved through binding arbitration in accordance with the rules of the Canadian Arbitration Association. Arbitration will take place in Quebec City, Quebec, Canada.
</Typography>
        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          16. Discord Integration Disclaimer
        </Typography>
        <Typography paragraph>
          LootManager is not endorsed by, directly affiliated with, or sponsored by Discord Inc. We are a third-party service utilizing Discord's OAuth2 authentication.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          17. Contact Information
        </Typography>
        <Typography paragraph>
          For questions about these Terms or our Discord integration, contact us at:
        </Typography>
        <Typography paragraph>
        Email: <Link href="mailto:support@lootmanager.net">support@lootmanager.net</Link>
        <br />
        <Link href="https://discord.gg/5WNNGkwFKk" target="_blank" rel="noopener noreferrer">    Discord Support Server</Link>
        </Typography>
        <Typography paragraph>
          Address: 533 rue Ste. Anne, Saguenay, Québec, Canada, G7J 2N6
        </Typography>
      </Container>
    </Box>
  );
};

export default TermsOfService;