import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Typography
} from '@mui/material';
import { LogActions } from '../services/logginServices';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import TutorialPopover from './common/TutorialPopover';

interface WebhookModalProps {
  open: boolean;
  onClose: () => void;
  guildID: string;
}

const WebhookModal: React.FC<WebhookModalProps> = ({ open, onClose, guildID }) => {
  const [webhooks, setWebhooks] = useState<Record<string, string[]>>({});
  const [logTypeInput, setLogTypeInput] = useState('');
  const [urlInput, setUrlInput] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const steps = ['Select Action', 'Configure Webhook'];
  const [activeStep, setActiveStep] = useState(0);
  const [action, setAction] = useState<'add' | 'remove' | ''>('');
  const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean | null>(null);

  // Fetch webhooks and subscription status when the modal opens
  useEffect(() => {
    if (open) {
      const fetchGuildData = async () => {
        try {
          // Fetch subscription status
          const guildDocRef = doc(db, 'guilds', guildID);
          const guildDocSnap = await getDoc(guildDocRef);
          if (guildDocSnap.exists()) {
            const guildData = guildDocSnap.data();
            setIsSubscriptionActive(guildData.isSubscribed);
          } else {
            setIsSubscriptionActive(false);
          }

          // Fetch webhooks
          const docRef = doc(db, 'subscribedLogs', guildID);
          const docSnap = await getDoc(docRef);

          if (!docSnap.exists()) {
            // Initialize the document with empty webhooks object
            await setDoc(docRef, { webhooks: {} });
            setWebhooks({});
          } else {
            const data = docSnap.data();
            setWebhooks(data.webhooks || {});
          }
        } catch (error) {
          console.error('Error fetching guild data:', error);
          setSnackbarMessage('Failed to load guild data');
        }
      };

      fetchGuildData();
    }
  }, [open, guildID]);

  // Reset logTypeInput if it's no longer valid
  useEffect(() => {
    const availableLogTypes = action === 'remove' ? Object.keys(webhooks) : Object.keys(LogActions);
    if (logTypeInput && !availableLogTypes.includes(logTypeInput)) {
      setLogTypeInput('');
    }
  }, [webhooks, action, logTypeInput]);

  const handleAddWebhook = async () => {
    if (!logTypeInput || !urlInput) {
      setSnackbarMessage('Log type and URL are required.');
      return;
    }

    const updatedWebhooks = { ...webhooks };

    // Initialize the array if it doesn't exist
    if (!updatedWebhooks[logTypeInput]) {
      updatedWebhooks[logTypeInput] = [];
    }

    // Check for duplicate webhook
    if (!updatedWebhooks[logTypeInput].includes(urlInput)) {
      updatedWebhooks[logTypeInput] = [...updatedWebhooks[logTypeInput], urlInput];
      setWebhooks(updatedWebhooks);
      setUrlInput('');

      // Save to Firestore
      try {
        const docRef = doc(db, 'subscribedLogs', guildID);
        await updateDoc(docRef, { webhooks: updatedWebhooks });
        setSnackbarMessage('Webhook added successfully');
      } catch (error) {
        console.error('Error saving webhook:', error);
        setSnackbarMessage('Failed to add webhook');
      }

    } else {
      setSnackbarMessage('This webhook URL is already added for the log type.');
    }
  };

  const handleDeleteWebhook = async (logType: string, urlIndex: number) => {
    const updatedWebhooks = { ...webhooks };
    updatedWebhooks[logType] = updatedWebhooks[logType].filter((_, index) => index !== urlIndex);

    // Remove the log type if no webhooks remain
    if (updatedWebhooks[logType].length === 0) {
      delete updatedWebhooks[logType];
    }

    setWebhooks(updatedWebhooks);

    // Save to Firestore
    try {
      const docRef = doc(db, 'subscribedLogs', guildID);
      await updateDoc(docRef, { webhooks: updatedWebhooks });
      setSnackbarMessage('Webhook deleted successfully');
    } catch (error) {
      console.error('Error deleting webhook:', error);
      setSnackbarMessage('Failed to delete webhook');
    }

    // Reset logTypeInput if the selected log type no longer exists
    if (!updatedWebhooks[logType]) {
      setLogTypeInput('');
    }
  };

  if (isSubscriptionActive === false && open) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Subscription Required</DialogTitle>
        <DialogContent>
          <Typography>
            Your guild does not have an active subscription. Please subscribe to manage webhooks.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (isSubscriptionActive === null) {
    // Still loading
    return null;
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Configure Discord Webhooks</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {activeStep === 0 && (
              <Box>
                <Typography variant="subtitle1">Select Action</Typography>
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <Button
                    variant={action === 'add' ? 'contained' : 'outlined'}
                    onClick={() => {
                      setAction('add');
                      setLogTypeInput('');
                      setUrlInput('');
                    }}
                  >
                    Add New Webhook
                  </Button>
                  <Button
                    variant={action === 'remove' ? 'contained' : 'outlined'}
                    onClick={() => {
                      setAction('remove');
                      setLogTypeInput('');
                    }}
                    disabled={Object.keys(webhooks).length === 0}
                  >
                    Remove Existing Webhook
                  </Button>
                </Stack>
              </Box>
            )}

            {activeStep === 1 && (
              <Box>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Log Type</InputLabel>
                  <Select
                    value={logTypeInput}
                    onChange={(e) => setLogTypeInput(e.target.value as string)}
                  >
                    {(action === 'remove'
                      ? Object.keys(webhooks)
                      : Object.keys(LogActions)
                    ).map((logType) => (
                      <MenuItem key={logType} value={logType}>
                        {logType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {action === 'add' && (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <TextField
                        fullWidth
                        label="Discord Webhook URL"
                        value={urlInput}
                        onChange={(e) => setUrlInput(e.target.value)}
                      />
                      <TutorialPopover
                        title="How to Set Up a Discord Webhook"
                        steps={[
                          {
                            title: "Right-click on the channel and select 'Edit Channel'",
                            image: '/images/tutorial/webhook/webhook_step1.png'
                          },
                          {
                            title: "Click on 'Integrations' then 'Create Webhook'",
                            image: '/images/tutorial/webhook/webhook_step2.png'
                          },
                          {
                            title: "Configure webhook name and channel, then click 'Save Changes'",
                            image: '/images/tutorial/webhook/webhook_step3.png'
                          },
                          {
                            title: "Click 'Copy Webhook URL'",
                            image: '/images/tutorial/webhook/webhook_step4.png'
                          },
                          {
                            title: 'Select log type and paste the webhook URL',
                            image: '/images/tutorial/webhook/webhook_step5.png'
                          }
                        ]}
                      />
                    </Box>
                    <Button
                      variant="contained"
                      onClick={handleAddWebhook}
                      disabled={!logTypeInput || !urlInput}
                      sx={{ mt: 2 }}
                    >
                      Add Webhook
                    </Button>

                    {/* Display existing webhooks for this log type */}
                    {webhooks[logTypeInput]?.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2">
                          Existing Webhooks for {logTypeInput}:
                        </Typography>
                        {webhooks[logTypeInput].map((url, index) => (
                          <Box
                            key={index}
                            sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                          >
                            <TextField
                              value={url}
                              InputProps={{ readOnly: true }}
                              fullWidth
                              sx={{ mr: 2 }}
                            />
                            <Button
                              color="error"
                              onClick={() => handleDeleteWebhook(logTypeInput, index)}
                              variant="outlined"
                              size="small"
                            >
                              Delete
                            </Button>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </>
                )}

                {action === 'remove' && logTypeInput && (
                  <>
                    {webhooks[logTypeInput]?.length > 0 ? (
                      webhooks[logTypeInput].map((url, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <TextField
                            value={url}
                            InputProps={{ readOnly: true }}
                            fullWidth
                            sx={{ mr: 2 }}
                          />
                          <Button
                            color="error"
                            onClick={() => handleDeleteWebhook(logTypeInput, index)}
                            variant="outlined"
                            size="small"
                          >
                            Delete
                          </Button>
                        </Box>
                      ))
                    ) : (
                      <Typography>No webhooks available for this log type.</Typography>
                    )}
                  </>
                )}
              </Box>
            )}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          {activeStep > 0 && (
            <Button onClick={() => setActiveStep((prev) => prev - 1)}>Back</Button>
          )}
          {activeStep < steps.length - 1 && (
            <Button
              variant="contained"
              onClick={() => setActiveStep((prev) => prev + 1)}
              disabled={!action}
            >
              Next
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default WebhookModal;
