import React from 'react';
import { Typography, Box, Card, CardContent, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Team {
    name: string;
    members: string[];
    leader: string;
    secondLeader?: string;
}

interface TeamListProps {
    teams: Team[];
    onDeleteTeam: (teamName: string) => void;
}

const TeamList: React.FC<TeamListProps> = ({ teams, onDeleteTeam }) => {
    const navigate = useNavigate();

    const handleTeamClick = (teamName: string) => {
        navigate(`/teams/${teamName}`); 
    };

    const handleDeleteClick = (teamName: string) => {
        if (window.confirm(`Are you sure you want to delete the team "${teamName}"?`)) {
            onDeleteTeam(teamName); 
        }
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                Manage Teams
            </Typography>
            {teams.map((team, index) => (
                <Card key={index} sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                            {team.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
                            Leader: {team.leader} {team.secondLeader && `, Second Leader: ${team.secondLeader}`}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Members: {team.members.length}
                        </Typography>
                        <Button variant="outlined" color="primary" sx={{ marginTop: 1, marginRight: 1 }} onClick={() => handleTeamClick(team.name)}>
                            View Details
                        </Button>
                        <Button variant="outlined" color="secondary" sx={{ marginTop: 1 }} onClick={() => handleDeleteClick(team.name)}>
                            Delete
                        </Button>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
};

export default TeamList;
