import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import { collection, query, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';

interface CreateEventModalProps {
  open: boolean;
  onClose: () => void;
  onProceed: () => void;
  selectedGuild: string;
}

const MAX_FREE_EVENTS = 2;

const CreateEventModal: React.FC<CreateEventModalProps> = ({
  open,
  onClose,
  onProceed,
  selectedGuild,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [eventCount, setEventCount] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const checkGuildStatus = async () => {
      if (!selectedGuild) return;

      setIsLoading(true);
      try {
        const guildRef = doc(db, 'guilds', selectedGuild);
        const guildDoc = await getDoc(guildRef);
        const guildData = guildDoc.data();
        setIsSubscribed(guildData?.isSubscribed || false);

        const eventsRef = collection(db, 'events', selectedGuild, 'guildEvents');
        const eventsSnap = await getDocs(query(eventsRef));
        setEventCount(eventsSnap.size);
      } catch (error) {
        console.error('Error checking guild status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (open) {
      checkGuildStatus();
    }
  }, [selectedGuild, open]);

  const canCreateEvent = isSubscribed || eventCount < MAX_FREE_EVENTS;

  // Auto-confirm when conditions are met
  useEffect(() => {
    if (open && !isLoading && canCreateEvent) {
      onProceed();
    }
  }, [open, isLoading, canCreateEvent, onProceed]);

  if (!selectedGuild) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {isLoading
          ? "Checking Eligibility..."
          : canCreateEvent
          ? "Confirm Event Creation"
          : "Event Limit Reached"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ py: 2 }}>
          {isLoading ? (
            <Typography variant="body1" paragraph>
              Please wait while we check your eligibility to create a new event.
            </Typography>
          ) : !canCreateEvent ? (
            <>
              <Typography variant="h6" color="error" gutterBottom>
                Unable to Create New Event
              </Typography>
              <Typography variant="body1" paragraph>
                Free guilds are limited to {MAX_FREE_EVENTS} active events. You currently have {eventCount} events.
              </Typography>
              <Typography variant="body1" paragraph>
                To create more events, either:
                <ul>
                  <li>Delete some existing events, or</li>
                  <li>Upgrade to Premium for unlimited events</li>
                </ul>
              </Typography>
              <Button
                component={Link}
                to="/discord-bot"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Upgrade to Premium
              </Button>
            </>
          ) : (
            <Typography variant="body1" paragraph>
              You have the capacity to create a new event. Would you like to proceed?
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        {!isLoading && canCreateEvent && (
          <Button onClick={onProceed} color="primary" variant="contained">
            Proceed
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventModal;
