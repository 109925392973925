//src\store\tutorialSlice.ts

import { createSlice } from '@reduxjs/toolkit';

const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState: {
    isEnabled: true
  },
  reducers: {
    disableTutorials: (state) => {
      state.isEnabled = false;
    },
    enableTutorials: (state) => {
      state.isEnabled = true;
    }
  }
});

export const { disableTutorials, enableTutorials } = tutorialSlice.actions;
export default tutorialSlice.reducer;
