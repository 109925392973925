import { setDoc, doc, collection, serverTimestamp, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

interface LogEntry {
  action: string;
  actor: string;
  details: string;
  sessionId: string;
  guild: string;
}

// Cache for subscription status
const subscriptionCache = new Map<string, {
  status: boolean;
  timestamp: number;
}>();

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const getGuildSubscriptionStatus = async (guildId: string): Promise<boolean> => {
  const now = Date.now();
  const cached = subscriptionCache.get(guildId);

  if (cached && (now - cached.timestamp) < CACHE_DURATION) {
    return cached.status;
  }

  const guildRef = doc(db, 'guilds', guildId);
  const guildSnap = await getDoc(guildRef);
  const isSubscribed = guildSnap.data()?.isSubscribed || false;

  subscriptionCache.set(guildId, {
    status: isSubscribed,
    timestamp: now
  });

  return isSubscribed;
};

export const logAction = async (logEntry: LogEntry) => {
  if (!logEntry.guild) {
    console.error('Guild ID is required for logging');
    return;
  }

  const logId = crypto.randomUUID();
  const isSubscribed = await getGuildSubscriptionStatus(logEntry.guild);

  if (isSubscribed) {
    const subscribedLogRef = doc(db, 'subscribedLogs', logEntry.guild, logEntry.action, logId);
    await setDoc(subscribedLogRef, {
      ...logEntry,
      timestamp: serverTimestamp(),
    });
  } else {
    const actionCollectionRef = collection(db, 'guilds', logEntry.guild, 'adminLogs');
    const actionDocRef = doc(actionCollectionRef, logEntry.action);
    const logsCollectionRef = collection(actionDocRef, 'logs');
    const logDocRef = doc(logsCollectionRef, logId);

    await setDoc(logDocRef, {
      ...logEntry,
      timestamp: serverTimestamp(),
    });
  }
};

export const LogActions = {
  ITEM_ADDED: 'ITEM_ADDED',
  ITEM_REMOVED: 'ITEM_REMOVED',
  BID_PLACED: 'BID_PLACED',
  ITEM_ROLLED: 'ITEM_ROLLED',
  ITEM_DISTRIBUTED: 'ITEM_DISTRIBUTED',
  PARTICIPANT_REGISTERED: 'PARTICIPANT_REGISTERED',
  DKP_AWARDED: 'DKP_AWARDED',
  BID_REFUNDED: 'BID_REFUNDED',
  USER_ROLE_UPDATED: 'USER_ROLE_UPDATED',
  USER_REMOVED: 'USER_REMOVED',
  USER_APPROVED: 'USER_APPROVED',
  USER_DETAILS_UPDATED: 'USER_DETAILS_UPDATED',
  DKP_REMOVED: "DKP_REMOVED",
  USER_DECLINED: "USER_DECLINED",
  SESSION_EXTENDED: "SESSION_EXTENDED",
  USER_CREATED: "USER_CREATED",
} as const;
