// handleRemove.ts
import { collection, doc, getDoc, getDocs, query, where, writeBatch, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { HandleRemoveProps } from "../interfaces/handlers";
import { Bid, UserGuild, LootDrop } from "../interfaces";
import { LogActions, logAction } from "../services/logginServices";

export const handleRemove = async ({
  lootId,
  sessionId,
  selectedGuild,
  setConfirmationTitle,
  setConfirmationMessage,
  setIsError,
  setConfirmationModalVisible,
  setSuccess,
}: HandleRemoveProps): Promise<void> => {
  if (!sessionId || !selectedGuild) return;

  try {
    const batch = writeBatch(db);
    const lootRef = doc(
      db, 
      "sessions", 
      selectedGuild,  
      "sessions",   
      sessionId, 
      "lootDrops", 
      lootId
    );

    // Fetch lootData to get itemName and winner info
    const lootDoc = await getDoc(lootRef);
    if (!lootDoc.exists()) {
      setConfirmationTitle("Error");
      setConfirmationMessage("Loot item not found.");
      setIsError(true);
      setConfirmationModalVisible(true);
      return;
    }

    const lootData = lootDoc.data() as LootDrop;

    // Initialize a refund map
    const userRefunds: Record<string, number> = {};

    // Refund DKP to the current winning bidder if using DKP method
    if (lootData.lootMethod === 'dkp' && lootData.currentBidder && lootData.currentBid > 0) {
      if (!userRefunds[lootData.currentBidder]) {
        userRefunds[lootData.currentBidder] = 0;
      }
      userRefunds[lootData.currentBidder] += lootData.currentBid;

      // Log refund action for the current winner
      await logAction({
        action: LogActions.BID_REFUNDED,
        actor: "system",
        details: `Refunded ${lootData.currentBid} DKP to ${lootData.currentBidderName} (winning bid) due to removal of item ${lootData.itemName}`,
        sessionId: sessionId,
        guild: selectedGuild
      });
    }

    // Handle refund for other unrefunded bids
    const bidsRef = collection(
      db, 
      "sessions", 
      selectedGuild,
      "sessions", 
      sessionId, 
      "lootDrops", 
      lootId, 
      "bids"
    );

    const bidsQuery = query(bidsRef, where("refunded", "==", false));
    const bidsSnapshot = await getDocs(bidsQuery);

    for (const bidDoc of bidsSnapshot.docs) {
      const bid = bidDoc.data() as Bid;

      // Avoid duplicating the refund for the current winner
      if (bid.bidderId === lootData.currentBidder) continue;

      if (!userRefunds[bid.bidderId]) {
        userRefunds[bid.bidderId] = 0;
      }
      userRefunds[bid.bidderId] += bid.bidAmount;

      // Mark bid as refunded
      batch.update(bidDoc.ref, { refunded: true });

      // Log refund action for each bidder
      await logAction({
        action: LogActions.BID_REFUNDED,
        actor: "system",
        details: `Refunded ${bid.bidAmount} DKP to bidder ${bid.bidderId} for removed item ${lootData.itemName}`,
        sessionId: sessionId,
        guild: selectedGuild
      });
    }

    // Update DKP for all affected users
    for (const [userId, totalRefund] of Object.entries(userRefunds)) {
      const userGuildsRef = collection(db, "userGuilds");
      const userGuildQuery = query(
        userGuildsRef,
        where("uid", "==", userId),
        where("guildId", "==", selectedGuild)
      );
      const userGuildSnapshot = await getDocs(userGuildQuery);

      if (!userGuildSnapshot.empty) {
        const userGuildDoc = userGuildSnapshot.docs[0];
        const userGuildData = userGuildDoc.data() as UserGuild;
        const userGuildRef = doc(db, "userGuilds", userGuildDoc.id);

        // Refund DKP to the user
        batch.update(userGuildRef, {
          dkp: userGuildData.dkp + totalRefund,
        });

        // Log refund action
        const refundLogRef = doc(collection(db, "dkpLogs"));
        batch.set(refundLogRef, {
          userId: userId,
          username: userGuildData.username,
          changeAmount: totalRefund,
          reason: `Refund for bids on deleted item ${lootData.itemName} (ID: ${lootId})`,
          timestamp: serverTimestamp(),
          adminId: "system",
          adminName: "System",
          guild: selectedGuild,
        });
      }
    }

    // Delete the loot item
    batch.delete(lootRef);
    await batch.commit();

    setSuccess("Loot item removed and all DKP refunded successfully!");
    setConfirmationTitle("Success");
    setConfirmationMessage("Loot item removed and all DKP refunded successfully!");
    setIsError(false);
    setConfirmationModalVisible(true);
  } catch (error: any) {
    setConfirmationTitle("Error");
    setConfirmationMessage(error.message);
    setIsError(true);
    setConfirmationModalVisible(true);
  }
};
