import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Paper,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
  TextField,
  Alert,
  FormControlLabel,
  Switch,
  Snackbar,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Grid2
} from '@mui/material';
import {
  collection,
  doc,
  getDoc,
  query,
  getDocs,
  where,
  updateDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
  writeBatch,
  increment,
  Timestamp,
  QuerySnapshot,
  QueryDocumentSnapshot
} from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';
import { DateTime } from 'luxon';
import { DistributeModal, SplitModal } from './DKPModals';
import EventParticipationLink from './EventParticipationLink';
import { logAction, LogActions } from '../../services/logginServices';
import AddParticipantModal from './AddParticipantModal';
import AppTheme from '../../shared-theme/AppTheme';

interface EventDetailsData {
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  description: string;
  createdBy: string;
  maxParticipants: number;
  registeredUsers: string[];
  dkpValue: number;
  isCompleted: boolean;
  dkpDistributed: boolean;
  activeVerification?: boolean;
  participationCode?: string;
  participationCodeExpiry?: any;
  verifiedParticipants?: string[];
  participationAttempts?: { [key: string]: number };
  requireParticipationLink?: boolean;
  allowLateRegistration?: boolean;
  assignedTeams?: string[];
}

interface ParticipantData {
  uid: string;
  inGameName: string;
  dkp: number;
  primaryWeapon: string;
  secondaryWeapon: string;
  gearScore: number;
  userGuildId: string;
  docRef: any;
  teamName?: string;
}

interface WeaponStats {
  [key: string]: number;
}

const EventDetails: React.FC = () => {
  const theme = useTheme();
  const { eventId } = useParams();
  const { selectedGuild, user } = useUser();
  const [eventDetails, setEventDetails] = React.useState<EventDetailsData | null>(null);
  const [tempDkpValue, setTempDkpValue] = React.useState(0);
  const [participants, setParticipants] = React.useState<ParticipantData[]>([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [weaponStats, setWeaponStats] = React.useState<WeaponStats>({});
  const [averageGearScore, setAverageGearScore] = React.useState(0);
  const [updatedDkpValue, setUpdatedDkpValue] = React.useState(0);
  const [openDistributeModal, setOpenDistributeModal] = React.useState(false);
  const [openSplitModal, setOpenSplitModal] = React.useState(false);
  const [openParticipationLink, setOpenParticipationLink] = React.useState(false);
  const [availableTeams, setAvailableTeams] = React.useState<any[]>([]);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [userTeamName, setUserTeamName] = React.useState<string | null>(null);
  const [openTeamModal, setOpenTeamModal] = React.useState(false);
  const [selectedTeams, setSelectedTeams] = React.useState<string[]>([]);
  const [openAddParticipantModal, setOpenAddParticipantModal] = React.useState(false);
  const [guildMembers, setGuildMembers] = React.useState<{
    uid: string;
    inGameName: string;
    gearScore: number;
    dkp: number;
}[]>([]);


  const userRole = user?.memberships.find((m) => m.guildId === selectedGuild)?.role;
  const isAdminOrOfficer = userRole === 'admin' || userRole === 'officer';


  React.useEffect(() => {
    if (!selectedGuild) return;

    const userGuildsRef = collection(db, 'userGuilds');
    const q = query(userGuildsRef, where('guildId', '==', selectedGuild));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const members = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          uid: data.uid,
          inGameName: data.inGameName,
          gearScore: data.gearScore || 0,
          dkp: data.dkp || 0
        };
      });
      setGuildMembers(members);
    });

    return () => unsubscribe();
  }, [selectedGuild]);

  React.useEffect(() => {
    if (!selectedGuild) return;
    const fetchTeams = async () => {
      const teamsRef = collection(db, 'teams', selectedGuild, 'guildTeams');
      const snapshot = await getDocs(teamsRef);
      const teams = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const data = doc.data();
          const members = data.members || [];
          if (data.leader && !members.includes(data.leader)) {
            members.push(data.leader);
          }
          return {
            name: doc.id,
            members,
          };
        }),
      );
      setAvailableTeams(teams);
    };
    fetchTeams();
  }, [selectedGuild]);

  React.useEffect(() => {
    if (eventDetails) {
      setTempDkpValue(eventDetails.dkpValue);
      setSelectedTeams(eventDetails.assignedTeams || []);
    }
  }, [eventDetails]);

  const handleUpdateDKPValue = async (value: number) => {
    if (!selectedGuild || !eventId) return;
    const eventDocRef = doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId);
    await updateDoc(eventDocRef, { dkpValue: value });
    setUpdatedDkpValue(value);

    await logAction({
      action: LogActions.DKP_AWARDED,
      actor: user?.uid || 'unknown',
      details: `DKP value updated to ${value} for event ${eventDetails?.title}`,
      sessionId: eventId,
      guild: selectedGuild,
    });
  };

  const handleDistributeDKP = async () => {
    if (!selectedGuild || !eventId || !eventDetails) return;
    try {
      const batch = writeBatch(db);
      const verifiedUsers = eventDetails.verifiedParticipants || [];

      participants
        .filter((p) => verifiedUsers.includes(p.uid))
        .forEach((participant) => {
          batch.update(participant.docRef, {
            dkp: increment(eventDetails.dkpValue),
          });

          const dkpLogsRef = collection(db, 'dkpLogs');
          const newLogRef = doc(dkpLogsRef);
          batch.set(newLogRef, {
            userId: participant.uid,
            username: participant.inGameName,
            changeAmount: eventDetails.dkpValue,
            reason: `Event completion: ${eventDetails.title}`,
            timestamp: Timestamp.now(),
            adminId: user?.uid,
            guild: selectedGuild,
          });
        });

      const eventDocRef = doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId);
      batch.update(eventDocRef, {
        isCompleted: true,
        dkpDistributed: true,
      });

      await batch.commit();

      await logAction({
        action: LogActions.DKP_AWARDED,
        actor: user?.uid || 'unknown',
        details: `DKP distributed for event ${eventDetails.title}`,
        sessionId: eventId,
        guild: selectedGuild,
      });

    } catch (error) {
      console.error('Error distributing DKP:', error);
    }
  };

  const handleSplitDKP = async () => {
    if (!selectedGuild || !eventId || !eventDetails) return;
    try {
      const batch = writeBatch(db);
      const verifiedUsers = eventDetails.verifiedParticipants || [];
      const verifiedParticipants = participants.filter((p) => verifiedUsers.includes(p.uid));
      const splitAmount = Math.floor(eventDetails.dkpValue / verifiedParticipants.length);

      verifiedParticipants.forEach((participant) => {
        batch.update(participant.docRef, {
          dkp: increment(splitAmount),
        });

        const dkpLogsRef = collection(db, 'dkpLogs');
        const newLogRef = doc(dkpLogsRef);
        batch.set(newLogRef, {
          userId: participant.uid,
          username: participant.inGameName,
          changeAmount: splitAmount,
          reason: `Split DKP from event: ${eventDetails.title}`,
          timestamp: Timestamp.now(),
          adminId: user?.uid,
          guild: selectedGuild,
        });
      });

      const eventDocRef = doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId);
      batch.update(eventDocRef, {
        isCompleted: true,
        dkpDistributed: true,
      });

      await batch.commit();

      await logAction({
        action: LogActions.DKP_AWARDED,
        actor: user?.uid || 'unknown',
        details: `DKP distributed for event ${eventDetails.title}`,
        sessionId: eventId,
        guild: selectedGuild,
      });

    } catch (error) {
      console.error('Error splitting DKP:', error);
    }
  };

  const handleRegistration = async () => {
    if (!user || !selectedGuild || !eventId || !eventDetails) return;

    // Check if user is already registered
    if (eventDetails.registeredUsers.includes(user.uid)) {
      setSnackbarMessage('You are already registered for this event.');
      setSnackbarOpen(true);
      return;
    }

    await updateDoc(doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId), {
      registeredUsers: arrayUnion(user.uid),
    });
  };

  const handleUnregister = async () => {
    if (!user || !selectedGuild || !eventId || !eventDetails) return;

    await updateDoc(doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId), {
      registeredUsers: arrayRemove(user.uid),
    });
  };

  const handleRemoveParticipant = async (participantUid: string) => {
    if (!selectedGuild || !eventId || !eventDetails) return;

    await updateDoc(doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId), {
      registeredUsers: arrayRemove(participantUid),
    });
  };

  // Fetch current user's team name
  React.useEffect(() => {
    if (!user || !selectedGuild) return;

    const fetchUserTeamName = async () => {
      const userGuildRef = doc(db, 'userGuilds', `${user.uid}_${selectedGuild}`);
      const userGuildDoc = await getDoc(userGuildRef);
      const userGuildData = userGuildDoc.data();

      if (userGuildData && userGuildData.teamName) {
        setUserTeamName(userGuildData.teamName);
      } else {
        setUserTeamName(null);
      }
    };

    fetchUserTeamName();
  }, [user, selectedGuild]);

  // Fetch participants
  React.useEffect(() => {
    if (!selectedGuild || !eventId || !eventDetails) return;

    const userGuildsRef = collection(db, 'userGuilds');
    const q = query(userGuildsRef, where('guildId', '==', selectedGuild));

    const unsubscribe = onSnapshot(q, (querySnapshot: QuerySnapshot) => {
      const participantsList: ParticipantData[] = [];
      const weaponCounts: WeaponStats = {};
      let totalGearScore = 0;

      const registeredUserIdsSet = new Set(eventDetails.registeredUsers);

      querySnapshot.forEach((doc: QueryDocumentSnapshot) => {
        const data = doc.data();
        if (registeredUserIdsSet.has(data.uid)) {
          const participantData = {
            uid: data.uid,
            inGameName: data.inGameName || 'N/A',
            dkp: data.dkp || 0,
            primaryWeapon: data.primaryWeapon || 'N/A',
            secondaryWeapon: data.secondaryWeapon || 'N/A',
            gearScore: data.gearScore || 0,
            userGuildId: doc.id,
            docRef: doc.ref,
            teamName: data.teamName || '-',
          };

          // Check if participant was assigned via a team
          if (eventDetails.assignedTeams && eventDetails.assignedTeams.length > 0) {
            if (eventDetails.assignedTeams.includes(data.teamName)) {
              participantData.teamName = data.teamName;
            }
          }

          participantsList.push(participantData);

          if (data.primaryWeapon) {
            weaponCounts[data.primaryWeapon] = (weaponCounts[data.primaryWeapon] || 0) + 1;
          }
          if (data.secondaryWeapon) {
            weaponCounts[data.secondaryWeapon] = (weaponCounts[data.secondaryWeapon] || 0) + 1;
          }

          totalGearScore += data.gearScore || 0;
        }
      });

      setParticipants(participantsList.sort((a, b) => b.gearScore - a.gearScore));
      setWeaponStats(weaponCounts);
      setAverageGearScore(
        participantsList.length > 0 ? Math.round(totalGearScore / participantsList.length) : 0,
      );
    });

    return () => unsubscribe();
  }, [selectedGuild, eventId, eventDetails]);

  React.useEffect(() => {
    if (!selectedGuild || !eventId) return;

    // Listen to event details
    const eventsCollectionRef = collection(db, 'events');
    const guildDocRef = doc(eventsCollectionRef, selectedGuild);
    const guildEventsRef = collection(guildDocRef, 'guildEvents');
    const eventDocRef = doc(guildEventsRef, eventId);

    const unsubscribeEvent = onSnapshot(eventDocRef, async (eventDoc) => {
      if (eventDoc.exists()) {
        const eventData = { id: eventDoc.id, ...eventDoc.data() } as EventDetailsData;

        setEventDetails(eventData);
      }
    });

    return () => unsubscribeEvent();
  }, [selectedGuild, eventId]);

  const handleOpenTeamModal = () => {
    setOpenTeamModal(true);
  };

  const handleCloseTeamModal = () => {
    setOpenTeamModal(false);
  };

  const handleToggleTeam = (teamName: string) => {
    setSelectedTeams((prev) =>
      prev.includes(teamName) ? prev.filter((t) => t !== teamName) : [...prev, teamName],
    );
  };

  const handleSaveTeams = async () => {
    if (!selectedGuild || !eventId || !eventDetails) {
      handleCloseTeamModal();
      return;
    }

    const eventDocRef = doc(collection(db, 'events', selectedGuild, 'guildEvents'), eventId);
    try {
      await updateDoc(eventDocRef, {
        assignedTeams: selectedTeams,
      });

      // Fetch team members and update registeredUsers
      if (selectedTeams.length > 0) {
        const teamMembersSet = new Set<string>();

        for (const teamName of selectedTeams) {
          const teamRef = doc(db, 'teams', selectedGuild, 'guildTeams', teamName);
          const teamDoc = await getDoc(teamRef);
          const teamData = teamDoc.data();

          if (teamData) {
            const members = teamData.members || [];
            if (teamData.leader && !members.includes(teamData.leader)) {
              members.push(teamData.leader);
            }

            if (members.length > 0) {
              const userGuildsRef = collection(db, 'userGuilds');
              const q = query(
                userGuildsRef,
                where('guildId', '==', selectedGuild),
                where('inGameName', 'in', members),
              );

              const querySnapshot = await getDocs(q);
              const userIds = querySnapshot.docs.map((doc) => doc.data().uid);

              userIds.forEach((uid) => teamMembersSet.add(uid));
            }
          }
        }

        const newRegisteredUsers = Array.from(
          new Set([...(eventDetails.registeredUsers || []), ...teamMembersSet]),
        );

        await updateDoc(eventDocRef, {
          registeredUsers: newRegisteredUsers,
        });
      }

      handleCloseTeamModal();
    } catch (error) {
      console.error('Error in team assignment:', error);
    }
  };

  const renderParticipantList = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>Player</TableCell>
            <TableCell>Weapons</TableCell>
            <TableCell align="right">Gear Score</TableCell>
            <TableCell align="right">DKP</TableCell>
            <TableCell align="center">Attendance</TableCell>
            {isAdminOrOfficer && <TableCell align="center">Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {participants.map((participant, index) => {
            const isVerified =
              eventDetails?.verifiedParticipants?.includes(participant.uid) || false;

            const toggleAttendance = async () => {
              if (!selectedGuild || !eventId || !eventDetails) return;

              const eventDocRef = doc(
                collection(db, 'events', selectedGuild, 'guildEvents'),
                eventId,
              );

              const updatedVerifiedParticipants = isVerified
                ? eventDetails.verifiedParticipants?.filter((uid) => uid !== participant.uid)
                : [...(eventDetails.verifiedParticipants || []), participant.uid];

              try {
                await updateDoc(eventDocRef, {
                  verifiedParticipants: updatedVerifiedParticipants,
                });
                setSnackbarMessage(
                  `Attendance for ${participant.inGameName} has been ${isVerified ? 'removed' : 'verified'
                  }.`,
                );
                setSnackbarOpen(true);
              } catch (error) {
                console.error('Error toggling attendance:', error);
              }
            };

            return (
              <TableRow key={`${participant.uid}-${index}`}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{participant.inGameName}</TableCell>
                <TableCell>
                  {participant.primaryWeapon}{' '}
                  {participant.secondaryWeapon && `+ ${participant.secondaryWeapon}`}
                </TableCell>
                <TableCell align="right">{participant.gearScore}</TableCell>
                <TableCell align="right">{participant.dkp}</TableCell>
                <TableCell align="center">
                  {isVerified ? (
                    <Typography color="success.main">✓</Typography>
                  ) : (
                    <Typography color="error.main">✗</Typography>
                  )}
                </TableCell>
                {isAdminOrOfficer && (
                  <TableCell align="center">
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color={isVerified ? 'secondary' : 'primary'}
                          size="small"
                          onClick={toggleAttendance}
                        >
                          {isVerified ? 'Unverify' : 'Verify'}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          color="error"
                          size="small"
                          onClick={() => handleRemoveParticipant(participant.uid)}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );


  const renderEventStats = () => (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Event Statistics
      </Typography>
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Weapon Distribution
        </Typography>
        {Object.entries(weaponStats)
          .sort(([, a], [, b]) => b - a)
          .map(([weapon, count]) => (
            <Box key={weapon} sx={{ mb: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2">{weapon}</Typography>
                <Typography variant="body2">{count} players</Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '4px',
                  bgcolor: theme.palette.grey[200],
                  borderRadius: '2px',
                }}
              >
                <Box
                  sx={{
                    width: `${(count / participants.length) * 100}%`,
                    height: '100%',
                    bgcolor: theme.palette.primary.main,
                    borderRadius: '2px',
                  }}
                />
              </Box>
            </Box>
          ))}
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Party Composition
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography>Average Gear Score:</Typography>
          <Typography fontWeight="bold">{averageGearScore}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography>Total Participants:</Typography>
          <Typography fontWeight="bold">
            {participants.length}/{eventDetails?.maxParticipants}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography>Verified Attendees:</Typography>
          <Typography fontWeight="bold">
            {eventDetails?.verifiedParticipants?.length || 0}/{participants.length}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  if (!eventDetails) return <CircularProgress />;

  const userIsRegistered = eventDetails?.registeredUsers.includes(user?.uid || '');

  return (
    <AppTheme>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          minHeight: '100vh',
          pt: 4,
        }}
      >
        <Grid2
          container
          spacing={3}
          sx={{
            backgroundColor: '#121212',
            color: '#E1E1E6',
            pt: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 3, md: 4 },
            mb: { xs: 2, sm: 4 },
            width: '100%',
            maxWidth: { xs: '100%', lg: 'lg' },
          }}
        >
          {/* Event Info Card */}
          <Grid2 size={{ xs: 12, md: 4 }}>
            <Card
              sx={{
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
              }}
            >
              <CardContent>
                <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                  Event Details
                </Typography>
                <Typography
                  color="primary"
                  sx={{ fontSize: '1.5rem', fontWeight: 700, textAlign: 'center', mb: 3 }}
                >
                  {eventDetails?.dkpValue} DKP
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
                >
                  <span>Participants</span>
                  <strong>
                    {participants.length}/{eventDetails?.maxParticipants}
                  </strong>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
                >
                  <span>Average GS</span>
                  <strong>{averageGearScore}</strong>
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color={userIsRegistered ? 'error' : 'primary'}
                  onClick={userIsRegistered ? handleUnregister : handleRegistration}
                  disabled={
                    !!(
                      (participants.length >= (eventDetails?.maxParticipants || 0) &&
                        !userIsRegistered) ||
                      (eventDetails?.requireParticipationLink &&
                        !eventDetails?.verifiedParticipants?.includes(user?.uid || ''))
                    )
                  }
                  sx={{ mt: 2 }}
                >
                  {userIsRegistered
                    ? 'Unregister'
                    : eventDetails?.requireParticipationLink &&
                      !eventDetails?.verifiedParticipants?.includes(user?.uid || '')
                    ? 'Registration Link Required'
                    : 'Register for Event'}
                </Button>
              </CardContent>
            </Card>
          </Grid2>
  
          {/* Participants and Stats */}
          <Grid2 size={{ xs: 12, md: 8 }}>
            <Card
              sx={{
                backgroundColor: '#121212',
                color: '#E1E1E6',
              }}
            >
              <CardContent>
                {eventDetails?.activeVerification && (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    Attendance verification is active until{' '}
                    {eventDetails?.participationCodeExpiry?.toDate().toLocaleTimeString()}
                  </Alert>
                )}
                <Tabs
                  value={tabValue}
                  onChange={(_, newValue) => setTabValue(newValue)}
                  sx={{ mb: 2 }}
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab label="Participants" />
                  <Tab label="Event Statistics" />
                </Tabs>
                {tabValue === 0 ? renderParticipantList() : renderEventStats()}
              </CardContent>
            </Card>
  
            {/* Admin Controls */}
            {isAdminOrOfficer && !eventDetails?.dkpDistributed && (
              <Card sx={{ mt: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Admin Controls
                  </Typography>
                  <Grid2 container spacing={4}>
                    {/* Attendance and Verification */}
                    <Grid2 size={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Attendance & Verification
                      </Typography>
                      <Grid2 container spacing={2}>
                        <Grid2 size={12}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => setOpenParticipationLink(true)}
                            color={eventDetails?.activeVerification ? 'secondary' : 'primary'}
                          >
                            {eventDetails?.activeVerification
                              ? 'Generate New Verification Code'
                              : 'Start Attendance Check'}
                          </Button>
                        </Grid2>
                        <Grid2 size={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={eventDetails?.allowLateRegistration || false}
                                onChange={async (e) => {
                                  if (!selectedGuild || !eventId) return;
                                  const eventDocRef = doc(
                                    collection(db, 'events', selectedGuild, 'guildEvents'),
                                    eventId
                                  );
                                  await updateDoc(eventDocRef, {
                                    allowLateRegistration: e.target.checked,
                                  });
                                }}
                              />
                            }
                            label="Allow Late Registration with Verification"
                          />
                        </Grid2>
                      </Grid2>
                    </Grid2>
                    <Grid2 size={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setOpenAddParticipantModal(true)}
                      >
                        Add Participant
                      </Button>
                    </Grid2>
  
                    {/* Team Management */}
                    <Grid2 size={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Team Management
                      </Typography>
                      <Grid2 container spacing={2}>
                        <Grid2 size={12}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={handleOpenTeamModal}
                          >
                            Assign Teams
                          </Button>
                        </Grid2>
                      </Grid2>
                    </Grid2>
  
                    {/* DKP Management */}
                    <Grid2 size={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        DKP Management
                      </Typography>
                      <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 12, sm: 6 }}>
                          <TextField
                            fullWidth
                            type="number"
                            label="DKP Value"
                            value={tempDkpValue}
                            onChange={(e) => setTempDkpValue(parseInt(e.target.value))}
                          />
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6 }}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleUpdateDKPValue(tempDkpValue)}
                            sx={{ height: '56px' }}
                          >
                            Set DKP
                          </Button>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6 }}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => setOpenDistributeModal(true)}
                            color="primary"
                          >
                            Distribute DKP
                          </Button>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6 }}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => setOpenSplitModal(true)}
                            color="secondary"
                          >
                            Split DKP
                          </Button>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Grid2>
                </CardContent>
              </Card>
            )}
          </Grid2>
        </Grid2>
  
        {/* Modals */}
        <DistributeModal
          open={openDistributeModal}
          onClose={() => setOpenDistributeModal(false)}
          onConfirm={() => {
            handleDistributeDKP();
            setOpenDistributeModal(false);
          }}
          dkpValue={eventDetails?.dkpValue}
          participants={participants.filter((p) =>
            eventDetails?.verifiedParticipants?.includes(p.uid)
          )}
        />
  
        <SplitModal
          open={openSplitModal}
          onClose={() => setOpenSplitModal(false)}
          onConfirm={() => {
            handleSplitDKP();
            setOpenSplitModal(false);
          }}
          dkpValue={eventDetails?.dkpValue}
          participants={participants.filter((p) =>
            eventDetails?.verifiedParticipants?.includes(p.uid)
          )}
        />
  
        {eventId && selectedGuild && (
          <EventParticipationLink
            open={openParticipationLink}
            onClose={() => setOpenParticipationLink(false)}
            eventId={eventId}
            guildId={selectedGuild}
            registeredUsers={eventDetails?.registeredUsers}
          />
        )}
  
        {/* Team Assignment Modal */}
        <Dialog open={openTeamModal} onClose={handleCloseTeamModal} fullWidth maxWidth="sm">
          <DialogTitle>Select Teams to Assign</DialogTitle>
          <DialogContent>
            <List>
              {availableTeams.map((team) => (
                <ListItem key={team.name} disablePadding>
                  <div
                    onClick={() => handleToggleTeam(team.name)}
                    style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                  >
                    <Checkbox checked={selectedTeams.includes(team.name)} />
                    <ListItemText
                      primary={team.name}
                      secondary={`Members: ${team.members.join(', ')}`}
                    />
                  </div>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseTeamModal}>Cancel</Button>
            <Button onClick={handleSaveTeams} variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
  
        {/* Snackbar for feedback messages */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />
  
        {eventId && selectedGuild && eventDetails && (
          <AddParticipantModal
            open={openAddParticipantModal}
            onClose={() => setOpenAddParticipantModal(false)}
            guildMembers={guildMembers}
            eventId={eventId}
            guildId={selectedGuild}
            currentParticipants={eventDetails.registeredUsers}
          />
        )}
      </Container>
    </AppTheme>
  );
  
};


export default EventDetails;
