import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Stack,
  Card as MuiCard,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Select, { StylesConfig } from 'react-select';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust the import path as needed
import { useNavigate } from 'react-router-dom';
import AppTheme from '../../shared-theme/AppTheme';
import ColorModeSelect from '../../shared-theme/ColorModeSelect';

// Styled components
const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...(theme.palette.mode === 'dark' && {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage: 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    backgroundRepeat: 'no-repeat',
  },
}));


// Component
export default function SignIn(props: { disableCustomTheme?: boolean }) {
  const [guilds, setGuilds] = useState<string[]>([]);
  const [selectedGuild, setSelectedGuild] = useState<{ value: string; label: string } | null>(null);
  const [newGuildName, setNewGuildName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(false);
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchGuilds = async () => {
      try {
        const guildsCollection = collection(db, 'guilds');
        const guildsSnapshot = await getDocs(guildsCollection);
        let guildList = guildsSnapshot.docs.map((doc) => doc.id);
        guildList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        setGuilds(guildList);
      } catch (error) {
        console.error('SignIn - Error fetching guilds:', error);
        setError('Failed to load guilds. Please try again later.');
      }
    };

    fetchGuilds();
  }, []);

  const handleDiscordLogin = () => {
    setError('');
    const guildInput = newGuildName.trim();
    const guild = guildInput ? guildInput : selectedGuild?.value;

    if (!guild) {
      setError('Please select a guild or enter a new guild name.');
      return;
    }

    const isNewGuild = !!guildInput;
    if (
      isNewGuild &&
      guilds.some((existingGuild) => existingGuild.toLowerCase() === guild.toLowerCase())
    ) {
      setError(
        'Guild already exists. Please select an existing guild or choose a different name.'
      );
      return;
    }

    setIsSubmitting(true);
    const state = encodeURIComponent(JSON.stringify({ guild, isNewGuild }));
    const clientId = '1297526766604124203'; // Replace with your actual client ID
    const redirectUri = process.env.REACT_APP_DISCORD_REDIRECT_URL;
    const scope = 'identify email';

    if (!redirectUri) {
      console.error('REACT_APP_DISCORD_REDIRECT_URL is not defined');
      setError('An error occurred. Please try again later.');
      setIsSubmitting(false);
      return;
    }

    const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_type=code&scope=${encodeURIComponent(scope)}&state=${state}`;

    window.location.href = discordAuthUrl;
  };

  // Guild options for react-select
  const guildOptions = guilds.map((guild) => ({
    value: guild,
    label: guild,
  }));

  // Custom styles for react-select
  const customSelectStyles: StylesConfig<{ value: string; label: string }, false> = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      borderColor: state.isFocused ? '#E1E1E6' : 'rgba(255, 255, 255, 0.1)',
      boxShadow: state.isFocused ? `0 0 0 1px #E1E1E6` : 'none',
      '&:hover': {
        borderColor: '#E1E1E6',
      },
      color: '#E1E1E6',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#121212',
      color: '#E1E1E6',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'rgba(255, 255, 255, 0.1)' : '#121212',
      color: '#E1E1E6',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#E1E1E6',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgba(225, 225, 230, 0.5)',
    }),
  };

  return (
    <AppTheme {...props}>
      <SignInContainer direction="column" justifyContent="center">
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Sign in
          </Typography>
          {/* Guild selection and Discord login */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <Typography variant="body2" sx={{ color: 'rgba(225, 225, 230, 0.8)' }}>
              Choose from existing guilds or enter a new guild name.
            </Typography>

            <Select
              options={guildOptions}
              value={selectedGuild}
              onChange={(option) => {
                setSelectedGuild(option);
                setNewGuildName('');
                setError('');
                setIsInputDisabled(!!option);
                setIsSelectDisabled(false);
              }}
              placeholder="Select a guild..."
              isClearable
              isDisabled={isSelectDisabled}
              styles={customSelectStyles}
            />
            <Box sx={{ my: 2, color: 'rgba(225, 225, 230, 0.8)' }}>
              Or create a new guild:
            </Box>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Enter new guild name"
              value={newGuildName}
              onChange={(e) => {
                setNewGuildName(e.target.value);
                setSelectedGuild(null);
                setError('');
                setIsSelectDisabled(!!e.target.value.trim());
                setIsInputDisabled(false);
              }}
              disabled={isInputDisabled}
              InputProps={{ style: { color: '#E1E1E6' } }}
              sx={{
                mb: 2,
                input: { color: '#E1E1E6' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(225, 225, 230, 0.3)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(225, 225, 230, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#E1E1E6',
                  },
                },
              }}
            />
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleDiscordLogin}
              disabled={isSubmitting}
              fullWidth
              sx={{
                mt: 2,
                fontWeight: 'bold',
                fontSize: '1rem',
                padding: '10px 20px',
                backgroundColor: '#5865F2', // Discord's brand color
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#4852C1',
                },
              }}
            >
              {isSubmitting ? 'Processing...' : 'Continue with Discord'}
            </Button>
          </Box>
        </Card>
      </SignInContainer>
    </AppTheme>
  );
}
