// src/components/AdminLogs.tsx
import React, { useEffect, useState } from 'react';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  Timestamp,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../firebase';
import { LogActions } from '../services/logginServices';
import useUser from '../hooks/useUser';
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  useTheme
} from '@mui/material';
import WebhookModal from './WebhookModal';

interface LogEntry {
  id: string;
  timestamp: Date;
  action: string;
  actor: string;
  details: string;
  sessionId: string;
  itemName?: string;
}

interface EnhancedLogEntry extends LogEntry {
  sessionName: string;
  actorName: string;
}

interface UserData {
  ingameName?: string;
  username?: string;
}

interface SessionData {
  name: string;
}

interface SortConfig {
  field: keyof EnhancedLogEntry;
  direction: 'asc' | 'desc';
}

interface Filters {
  dateRange: { start: Date | null; end: Date | null };
  actors: string[];
  sessions: string[];
  searchTerm: string;
}

const AdminLogs: React.FC = () => {
  const { selectedGuild } = useUser();
  const [logs, setLogs] = useState<EnhancedLogEntry[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedActions, setSelectedActions] = useState<string[]>([]);
  const entriesPerPage = 50;
  const theme = useTheme();
  const [isWebhookModalOpen, setWebhookModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: 'timestamp',
    direction: 'desc'
  });

  const [filters, setFilters] = useState<Filters>({
    dateRange: { start: null, end: null },
    actors: [],
    sessions: [],
    searchTerm: ''
  });

  const [visibleColumns, setVisibleColumns] = useState<(keyof EnhancedLogEntry)[]>([
    'timestamp',
    'action',
    'actorName', // We'll keep this as the data field
    'details',
    'sessionName',
    'sessionId'
  ]);

  const handleOpenWebhookModal = () => {
    setWebhookModalOpen(true);
  };

  const handleCloseWebhookModal = () => {
    setWebhookModalOpen(false);
  };

  useEffect(() => {
    if (!selectedGuild) return;
  
    const actionTypes = Object.values(LogActions);
    const unsubscribes = actionTypes.map(action => {
      // Legacy logs
      const oldLogsRef = collection(db, 'adminLogs', selectedGuild, action);
      
      // Non-subscribed guild logs
      const actionDocRef = doc(db, 'guilds', selectedGuild, 'adminLogs', action);
      const nonSubLogsRef = collection(actionDocRef, 'logs');
      
      // Subscribed guild logs
      const subscribedLogsRef = collection(db, 'subscribedLogs', selectedGuild, action);
  
      // Set up queries
      const queries = [
        query(oldLogsRef, orderBy('timestamp', 'desc')),
        query(nonSubLogsRef, orderBy('timestamp', 'desc')),
        query(subscribedLogsRef, orderBy('timestamp', 'desc'))
      ];
  
      // Create listeners for all locations
      const unsubscribes = queries.map(q => 
        onSnapshot(q, async (snapshot) => {
          const logData = snapshot.docs.map(doc => ({
            id: doc.id,
            timestamp: doc.data().timestamp instanceof Timestamp ? doc.data().timestamp.toDate() : new Date(),
            action: action,
            actor: doc.data().actor || '',
            details: doc.data().details || '',
            sessionId: doc.data().sessionId || '',
            itemName: doc.data().itemName
          } as LogEntry));
  
          const enhancedLogs = await enhanceLogs(logData);
          setLogs(prevLogs => mergeLogs(prevLogs, enhancedLogs, action));
        })
      );
  
      return () => unsubscribes.forEach(unsub => unsub());
    });
  
    return () => {
      unsubscribes.forEach(unsub => unsub());
    };
  }, [selectedGuild]);
  


  const enhanceLogs = async (logData: LogEntry[]) => {
    return await Promise.all(
      logData.map(async (logData) => {
        let sessionName = '';
        if (logData.sessionId) {
          const sessionDocRef = doc(db, 'sessions', logData.sessionId);
          const sessionDoc = await getDoc(sessionDocRef);
          if (sessionDoc.exists()) {
            const sessionData = sessionDoc.data() as SessionData;
            sessionName = sessionData.name;
          }
        }

        let actorName = logData.actor;
        if (logData.actor) {
          const userDocRef = doc(db, 'users', logData.actor);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data() as UserData;
            actorName = userData.ingameName || userData.username || logData.actor;
          }
        }

        return {
          ...logData,
          sessionName,
          actorName,
        } as EnhancedLogEntry;
      })
    );
  };

  const mergeLogs = (prevLogs: EnhancedLogEntry[], newLogs: EnhancedLogEntry[], action: string) => {
    // Keep all logs that aren't from this specific action and path
    const filteredLogs = prevLogs.filter(log => !(log.action === action && newLogs.some(newLog => 
      newLog.id === log.id
    )));
    return [...filteredLogs, ...newLogs];
  };

  const handleSort = (field: keyof EnhancedLogEntry) => {
    setSortConfig(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  const toggleColumnVisibility = (column: keyof EnhancedLogEntry) => {
    setVisibleColumns(prev => {
      if (prev.includes(column)) {
        return prev.filter(col => col !== column);
      } else {
        const targetIndex = columnOrder.indexOf(column);
        const newColumns = [...prev];

        let insertIndex = 0;
        for (let i = 0; i < targetIndex; i++) {
          if (newColumns.includes(columnOrder[i] as keyof EnhancedLogEntry)) {
            insertIndex = newColumns.indexOf(columnOrder[i] as keyof EnhancedLogEntry) + 1;
          }
        }

        newColumns.splice(insertIndex, 0, column);
        return newColumns;
      }
    });
  };

  const exportLogs = (format: 'csv' | 'json') => {
    const data = filteredLogs.map(log => ({
      timestamp: log.timestamp.toISOString(),
      action: log.action,
      actor: log.actorName,
      details: log.details,
      session: log.sessionName
    }));

    const blob = new Blob(
      [format === 'csv' ? convertToCSV(data) : JSON.stringify(data, null, 2)],
      { type: format === 'csv' ? 'text/csv' : 'application/json' }
    );

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `admin-logs.${format}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const convertToCSV = (data: any[]) => {
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(obj => Object.values(obj).join(','));
    return [headers, ...rows].join('\n');
  };

  const filteredLogs = logs
    .filter(log => {
      if (selectedActions.length > 0 && !selectedActions.includes(log.action)) return false;
      if (filters.searchTerm && !log.details.toLowerCase().includes(filters.searchTerm.toLowerCase())) return false;
      if (filters.actors.length > 0 && !filters.actors.includes(log.actorName)) return false;
      if (filters.sessions.length > 0 && !filters.sessions.includes(log.sessionName)) return false;
      if (filters.dateRange.start && log.timestamp < filters.dateRange.start) return false;
      if (filters.dateRange.end && log.timestamp > filters.dateRange.end) return false;
      return true;
    })
    .sort((a, b) => {
      const aValue = a[sortConfig.field];
      const bValue = b[sortConfig.field];
      const direction = sortConfig.direction === 'asc' ? 1 : -1;

      if (aValue instanceof Date) {
        return direction * (aValue.getTime() - (bValue as Date).getTime());
      }
      return direction * String(aValue).localeCompare(String(bValue));
    });

  const currentLogs = filteredLogs.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const totalPages = Math.ceil(filteredLogs.length / entriesPerPage);
  const columnDisplayNames: Record<string, string> = {
    timestamp: 'Timestamp',
    action: 'Action',
    actorName: 'Actor',
    details: 'Details',
    sessionName: 'Session Name',
    sessionId: 'Session ID'
  };

  const columnOrder = [
    'timestamp',
    'action',
    'actorName',
    'details',
    'sessionName',
    'sessionId'
  ];

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ pt: 3, pb: 2, color: 'white' }}>
          Admin Logs
        </Typography>

        <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <TextField
            fullWidth
            placeholder="Search in details..."
            value={filters.searchTerm}
            onChange={e => setFilters(prev => ({ ...prev, searchTerm: e.target.value }))}
            variant="outlined"
            sx={{ flex: 1 }}
          />

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="contained" onClick={() => exportLogs('csv')}>
              Export CSV
            </Button>
            <Button variant="contained" onClick={() => exportLogs('json')}>
              Export JSON
            </Button>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button variant="contained" onClick={handleOpenWebhookModal}>
                Discord Webhook
              </Button>
            </Box>

          </Box>
        </Box>

        <Paper sx={{ mb: 3, p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Column Visibility</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {columnOrder.map(column => (
              <FormControlLabel
                key={column}
                control={
                  <Checkbox
                    checked={visibleColumns.includes(column as keyof EnhancedLogEntry)}
                    onChange={() => toggleColumnVisibility(column as keyof EnhancedLogEntry)}
                  />
                }
                label={columnDisplayNames[column]}
              />
            ))}
          </Box>
        </Paper>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {visibleColumns.map(column => (
                  <TableCell
                    key={column}
                    onClick={() => handleSort(column)}
                    sx={{
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' }
                    }}
                  >
                    {columnDisplayNames[column]}
                    {sortConfig.field === column && (
                      <span>{sortConfig.direction === 'desc' ? ' ↓' : ' ↑'}</span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {currentLogs.map((log, index) => (
                <TableRow key={index}>
                  {visibleColumns.map(column => (
                    <TableCell
                      key={column}
                      sx={{
                        minWidth: column === 'timestamp' ? 200 : 'auto' // Increase width for timestamp column
                      }}
                    >
                      {column === 'timestamp'
                        ? `${log[column].toLocaleDateString()} ${log[column].toLocaleTimeString()}`
                        : log[column]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mt: 2
        }}>
          <Button
            variant="contained"
            onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Typography sx={{ color: 'white' }}>
            Page {currentPage} of {totalPages}
          </Typography>
          <Button
            variant="contained"
            onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </Box>
      </Container>
      <WebhookModal
        open={isWebhookModalOpen}
        onClose={handleCloseWebhookModal}
        guildID={selectedGuild || ''}
      />
    </Box>
  );
};

export default AdminLogs;