// src/components/Wishlist/WishlistPanel.tsx

import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase";
import useUser from "../../hooks/useUser";
import { ItemType, WishlistItem } from "../../types/Wishlist";
import { addItemToWishlist, removeItemFromWishlist } from "./WishListServices";
import { Timestamp } from "firebase/firestore";
import TutorialPopover from '../common/TutorialPopover';

interface WishlistPanelProps {
  selectedGameMode: "Throne and Liberty" | "Custom";
  searchTerm: string;
  filteredItems: ItemType[];
  onGameModeChange: (mode: "Throne and Liberty" | "Custom") => void;
  onSearchChange: (term: string) => void;
}

export const WishlistPanel: React.FC<WishlistPanelProps> = ({
  selectedGameMode,
  searchTerm,
  filteredItems,
  onGameModeChange,
  onSearchChange,
}) => {
  const theme = useTheme();
  const { user, selectedGuild } = useUser();
  const [wishlistItems, setWishlistItems] = useState<WishlistItem[]>([]);
  const [isLocked, setIsLocked] = useState(false);
  const [tokenBalance, setTokenBalance] = useState<number>(0);

  useEffect(() => {
    if (!user || !selectedGuild) return;

    const userGuildRef = doc(
      db,
      "userGuilds",
      `${user.uid}_${selectedGuild}`
    );
    const unsubscribe = onSnapshot(userGuildRef, (doc) => {
      if (doc.exists()) {
        setWishlistItems(doc.data()?.wishlist || []);
        setIsLocked(doc.data()?.wishlistLocked || false);
        setTokenBalance(doc.data()?.wishlistTokens || 0);
      }
    });

    return () => unsubscribe();
  }, [user, selectedGuild]);

  const handleAddToWishlist = async (item: ItemType) => {
    if (!user || !selectedGuild) {
      alert("User or guild not found");
      return;
    }
    if (isLocked) {
      alert("Your wishlist is locked.");
      return;
    }
    try {
      await addItemToWishlist(user.uid, selectedGuild, item);
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error adding item to wishlist";
      alert(errorMessage);
      console.error("Error adding item to wishlist:", error);
    }
  };

  const handleRemoveFromWishlist = async (item: WishlistItem) => {
    if (!user || !selectedGuild) {
      alert("User or guild not found");
      return;
    }
    if (isLocked) {
      alert("Your wishlist is locked.");
      return;
    }
  
    const confirmRemoval = window.confirm(
      "Removing this item will not automatically refund your token. You'll need to contact an admin to get your token back. Do you want to proceed?"
    );
  
    if (!confirmRemoval) {
      return;
    }
  
    try {
      await removeItemFromWishlist(user.uid, selectedGuild, item);
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Error removing item from wishlist";
      alert(errorMessage);
      console.error("Error removing item from wishlist:", error);
    }
  };
  

  const filteredWishlistItems = wishlistItems.filter((item) =>
    item.itemName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ bgcolor: theme.palette.background.paper, borderRadius: 2, p: 4, boxShadow: 3, mb: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <Typography variant="h6">Token Balance: {tokenBalance}</Typography>
        <TutorialPopover
          title="Wishlist Tokens"
          steps={[{
            title: "Each token allows you to add one item to your wishlist. Tokens are awarded by guild administrators"
          }]}
        />
      </Box>

      {isLocked && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ bgcolor: "error.main", color: "white", p: 2, mb: 3, borderRadius: 1, textAlign: "center", flex: 1 }}>
            <Typography variant="body1">
              Your wishlist has been locked by an admin. Please contact your guild administrators to unlock it.
            </Typography>
          </Box>
          <TutorialPopover
            title="Locked Wishlist"
            steps={[{
              title: "Locked wishlists prevent changes during important events like raids or loot distribution"
            }]}
          />
        </Box>
      )}

      {/* Search Panel */}
      <Box sx={{ display: 'flex', alignItems: 'start', gap: 1 }}>
        <Box sx={{
          flex: 1,
          maxHeight: "300px",
          overflowY: "scroll",
          border: "1px solid",
          borderColor: theme.palette.divider,
          borderRadius: 1,
          p: 2,
          mb: 3,
          bgcolor: theme.palette.background.default,
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <input
              type="text"
              placeholder="Search for items..."
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </Box>

          {filteredItems.map((item, index) => (
            <Box
              key={`${item.name}-${index}`}
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                mb: 2,
                bgcolor: theme.palette.background.paper,
                borderRadius: 1,
                boxShadow: 1,
              }}
            >
              <Box
                component="img"
                src={`/images/new/${item.imageFilename}`}
                alt={item.name}
                sx={{ width: 50, height: 50, borderRadius: 1, mr: 2 }}
              />
              <Box sx={{ flex: 1, mr: 2 }}>
                <Typography
                  component="a"
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    color: "primary.main",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={() => handleAddToWishlist(item)}
                  disabled={isLocked}
                  sx={{
                    whiteSpace: "nowrap",
                    padding: "4px 8px",
                    fontSize: "0.875rem",
                    color: theme.palette.primary.main,
                  }}
                >
                  Add to Wishlist
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Wishlist Display Panel */}
      <Divider sx={{ my: 3 }} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <Typography variant="h6">Your Wishlist</Typography>
        <TutorialPopover
          title="Your Wishlist"
          steps={[{
            title: "Items in your wishlist have priority during loot distribution."
          }]}
        />
      </Box>

      <Box sx={{
        maxHeight: "300px",
        overflowY: "scroll",
        border: "1px solid",
        borderColor: theme.palette.divider,
        borderRadius: 1,
        p: 2,
        bgcolor: theme.palette.background.default,
      }}>
        {filteredWishlistItems.map((item, index) => (
          <Box
            key={`wishlist-${index}`}
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              mb: 2,
              bgcolor: theme.palette.background.paper,
              borderRadius: 1,
              boxShadow: 1,
            }}
          >
            <Box
              component="img"
              src={item.imageUrl}
              alt={item.itemName}
              sx={{ width: 50, height: 50, borderRadius: 1, mr: 2 }}
            />
            <Box sx={{ flex: 1, mr: 2 }}>
              <Typography
                component="a"
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  color: "primary.main",
                  textDecoration: "none",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {item.itemName}
              </Typography>
              <Typography variant="caption" display="block" color="text.secondary">
                Added: {item.addedAt instanceof Timestamp ? item.addedAt.toDate().toLocaleString() : "Just now"}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemoveFromWishlist(item)}
              disabled={isLocked}
              sx={{
                whiteSpace: "nowrap",
                padding: "4px 8px",
                fontSize: "0.875rem",
              }}
            >
              Remove
            </Button>
          </Box>
        ))}
        {wishlistItems.length === 0 && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: "center", py: 2 }}
          >
            No items in your wishlist yet
          </Typography>
        )}
      </Box>
    </Box>
  );

};
