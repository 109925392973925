// SessionList.tsx
import React, { useEffect, useState } from 'react';
import { db, auth } from '../../firebase';
import {
  collection,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  getDocs,
  Timestamp,
  serverTimestamp,
  writeBatch,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import { Session, LootDrop, UserGuild, Bid } from '../../interfaces';
import itemsData from '../../items.json';
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  useTheme,
  Card,
  CardContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  IconButton,
  FormControlLabel,
  Checkbox,
  Grid2
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { CircularProgress } from '@mui/material';
import { EventData } from '../events/Event';
import EventSessionLink from './EventSessionLink';
import SessionTimer from './SessionTimer';
import { logAction, LogActions } from '../../services/logginServices';
import TutorialPopover from '../common/TutorialPopover';
import CreateSessionModal from './CreateSessionModal';
import AppTheme from "../../shared-theme/AppTheme";

interface DurationConfig {
  value: number;
  unit: 'minutes' | 'hours' | 'days' | 'seconds' | 'n/a';
}

const SessionList: React.FC = () => {
  const theme = useTheme();
  const { user, loading, selectedGuild } = useUser();
  const [sessions, setSessions] = useState<Session[]>([]);
  const [sessionName, setSessionName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [isLoadingSessions, setIsLoadingSessions] = useState(true);
  const [sessionDuration, setSessionDuration] = useState<DurationConfig>({
    value: 24,
    unit: 'hours',
  });

  const itemIconMap = itemsData.reduce((map, item) => {
    map[item.name] = `/images/new/${item.imageFilename}`;
    return map;
  }, {} as Record<string, string>);

  const [events, setEvents] = useState<EventData[]>([]);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const [showEventLink, setShowEventLink] = useState(false);
  const [antiSnipeEnabled, setAntiSnipeEnabled] = useState(false);
  const [antiSnipeTime, setAntiSnipeTime] = useState(5);
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    if (!user || !selectedGuild) {
      setSessions([]);
      setIsLoadingSessions(false);
      return;
    }

    setIsLoadingSessions(true);

    const sessionsCollection = collection(db, 'sessions', selectedGuild, 'sessions');
    const q = query(
      sessionsCollection,
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, async (sessionSnapshot) => {
      try {
        const sessionsList = await Promise.all(
          sessionSnapshot.docs.map(async (sessionDoc) => {
            const sessionData = sessionDoc.data();

            // Fetch the user profile of the session creator
            const creatorRef = doc(db, 'users', sessionData.createdBy);
            const creatorSnap = await getDoc(creatorRef);
            const creatorData = creatorSnap.exists()
              ? creatorSnap.data()
              : { inGameName: 'Unknown User' };

            // Updated collection paths
            const participantsCollection = collection(
              db,
              'sessions',
              selectedGuild,
              'sessions',
              sessionDoc.id,
              'participants'
            );
            const participantsSnapshot = await getDocs(participantsCollection);
            const totalParticipants = participantsSnapshot.size;

            const lootDropsCollection = collection(
              db,
              'sessions',
              selectedGuild,
              'sessions',
              sessionDoc.id,
              'lootDrops'
            );
            const lootDropsSnapshot = await getDocs(lootDropsCollection);
            const lootDrops = lootDropsSnapshot.docs.map((itemDoc) =>
              itemDoc.data() as LootDrop
            );

            const remainingItems = lootDrops.filter(
              (item) => item.status !== 'distributed'
            ).length;

            // Fetch linked event details
            let linkedEventTitle = null;
            if (sessionData.eventId) {
              const eventDocRef = doc(
                db,
                'events',
                selectedGuild,
                'guildEvents',
                sessionData.eventId
              );
              const eventDoc = await getDoc(eventDocRef);
              if (eventDoc.exists()) {
                const eventData = eventDoc.data() as EventData;
                linkedEventTitle = eventData.title;
              }
            }

            return {
              id: sessionDoc.id,
              name: sessionData.name || 'Unnamed Session',
              createdBy: creatorData.inGameName || 'Unknown User',
              guild: selectedGuild,
              createdAt: sessionData.createdAt,
              lootmaster: sessionData.lootmaster,
              expiresAt: sessionData.expiresAt || null,
              totalParticipants,
              totalItems: lootDrops.length,
              remainingItems,
              itemNames: lootDrops
                .map((item) => item.itemName || 'Unnamed Item')
                .join(', '),
              items: lootDrops,
              eventId: sessionData.eventId || null,
              linkedEventTitle,
            } as Session;
          })
        );

        setSessions(
          sessionsList.filter((session) => session !== null) as Session[]
        );
        setIsLoadingSessions(false);
      } catch (error) {
        console.error('Error processing sessions:', error);
        setSessions([]);
        setIsLoadingSessions(false);
      }
    });

    return () => unsubscribe();
  }, [user, selectedGuild]);

  // Events fetching remains unchanged as it's already using the correct path
  useEffect(() => {
    const fetchEvents = async () => {
      if (!selectedGuild) return;
      const eventsCollectionRef = collection(
        db,
        'events',
        selectedGuild,
        'guildEvents'
      );
      const q = query(eventsCollectionRef);
      const querySnapshot = await getDocs(q);
      const eventsList = querySnapshot.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as EventData)
      );
      setEvents(eventsList);
    };
    fetchEvents();
  }, [selectedGuild]);

  const getDurationInSeconds = (value: number, unit: string) => {
    switch (unit) {
      case 'seconds':
        return value;
      case 'minutes':
        return value * 60;
      case 'hours':
        return value * 60 * 60;
      case 'days':
        return value * 60 * 60 * 24;
      default:
        return 0;
    }
  };

  const handleModalConfirm = () => {
    setShowCreateModal(false);
    handleCreateSession();
  };

  const handleCreateSession = async () => {
    setError(null);

    if (!canCreateSession) {
      setError('Only officers and admins can create sessions');
      return;
    }

    if (!selectedGuild) {
      setError('No guild selected');
      return;
    }

    if (sessionName.trim() === '') {
      setError('Session name cannot be empty');
      return;
    }

    try {
      const sessionsRef = collection(db, 'sessions', selectedGuild, 'sessions');
      const newSessionRef = doc(sessionsRef);

      let expiresAt: Timestamp | null = null;
      if (sessionDuration.unit !== 'n/a') {
        const durationInSeconds = getDurationInSeconds(sessionDuration.value, sessionDuration.unit);
        expiresAt = Timestamp.fromDate(new Date(Date.now() + durationInSeconds * 1000));
      }

      const baseSessionData = {
        name: sessionName,
        createdBy: user?.uid || 'Unknown User',
        createdAt: serverTimestamp(),
        isActive: true,
        lootmaster: user?.uid || 'Unknown',
        guild: selectedGuild,
        eventId: selectedEventId || null,
        expiresAt: expiresAt,
        antiSnipeEnabled,
        antiSnipeTime: antiSnipeEnabled ? antiSnipeTime : null,
      };

      await setDoc(newSessionRef, baseSessionData);

      const aggregatesRef = collection(newSessionRef, 'aggregates');

      await Promise.all([
        setDoc(doc(aggregatesRef, 'rolls'), {
          lootIds: {},
        }),
        setDoc(doc(aggregatesRef, 'bids'), {
          lootIds: {},
        }),
        setDoc(doc(aggregatesRef, 'lootData'), {
          currentBids: {},
          bidCounts: {},
          highestRolls: {},
          rollCounts: {},
        }),
      ]);

      setSessionName('');
      setSelectedEventId(null);
      setError(null);
      navigate(`/session/${newSessionRef.id}`);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleDeleteSession = async (sessionId: string) => {
    if (!auth.currentUser || !selectedGuild) {
      alert('You must be logged in and have a guild selected to delete a session.');
      return;
    }

    try {
      const sessionRef = doc(db, 'sessions', selectedGuild, 'sessions', sessionId);
      const sessionDoc = await getDoc(sessionRef);

      if (!sessionDoc.exists()) {
        alert('Session does not exist.');
        return;
      }

      const sessionData = sessionDoc.data() as Session;
      const isAdmin = user?.role === 'admin';

      if (!isAdmin && sessionData.createdBy !== user?.uid) {
        alert('You are not authorized to delete this session.');
        return;
      }

      // Get all loot drops for the session
      const lootDropsRef = collection(db, 'sessions', selectedGuild, 'sessions', sessionId, 'lootDrops');
      const lootDropsSnapshot = await getDocs(lootDropsRef);

      const batch = writeBatch(db);

      // Initialize a refund map
      const userRefunds: Record<string, number> = {};

      // Process refunds for each item
      for (const lootDoc of lootDropsSnapshot.docs) {
        const lootData = lootDoc.data() as LootDrop;
        const lootId = lootDoc.id;

        if (lootData.status !== 'distributed') {
          if (lootData.lootMethod === 'dkp' && lootData.currentBidder && lootData.currentBid > 0) {
            if (!userRefunds[lootData.currentBidder]) {
              userRefunds[lootData.currentBidder] = 0;
            }
            userRefunds[lootData.currentBidder] += lootData.currentBid;

            // Log refund action for the current winner
            await logAction({
              action: LogActions.BID_REFUNDED,
              actor: 'system',
              details: `Refunded ${lootData.currentBid} DKP to ${lootData.currentBidderName} (winning bid) due to removal of item ${lootData.itemName}`,
              sessionId,
              guild: selectedGuild,
            });
          }

          const bidsRef = collection(db, 'sessions', selectedGuild, 'sessions', sessionId, 'lootDrops', lootId, 'bids');
          const bidsQuery = query(bidsRef, where('refunded', '==', false));
          const bidsSnapshot = await getDocs(bidsQuery);

          for (const bidDoc of bidsSnapshot.docs) {
            const bid = bidDoc.data() as Bid;
            if (bid.bidderId === lootData.currentBidder) continue;

            if (!userRefunds[bid.bidderId]) {
              userRefunds[bid.bidderId] = 0;
            }
            userRefunds[bid.bidderId] += bid.bidAmount;

            // Mark bid as refunded
            batch.update(bidDoc.ref, { refunded: true });

            // Log refund action for each bidder
            await logAction({
              action: LogActions.BID_REFUNDED,
              actor: 'system',
              details: `Refunded ${bid.bidAmount} DKP to bidder ${bid.bidderId} for removed item ${lootData.itemName}`,
              sessionId,
              guild: selectedGuild,
            });
          }
        }
        batch.delete(lootDoc.ref);
      }

      // Update DKP for all affected users
      for (const [userId, totalRefund] of Object.entries(userRefunds)) {
        const userGuildRef = doc(db, 'userGuilds', `${userId}_${selectedGuild}`);
        const userGuildDoc = await getDoc(userGuildRef);

        if (userGuildDoc.exists()) {
          const userGuildData = userGuildDoc.data() as UserGuild;

          batch.update(userGuildRef, {
            dkp: (userGuildData.dkp || 0) + totalRefund,
          });

          const refundLogRef = doc(collection(db, 'dkpLogs'));
          batch.set(refundLogRef, {
            userId: userId,
            username: userGuildData.username || 'Unknown',
            changeAmount: totalRefund,
            reason: `Refund for bids on deleted session ${sessionData.name}`,
            timestamp: serverTimestamp(),
            adminId: 'system',
            adminName: 'System',
            guild: selectedGuild,
          });
        }
      }

      batch.delete(sessionRef);
      await batch.commit();

      setSessions(sessions.filter((session) => session.id !== sessionId));
      alert('Session deleted successfully and all DKP refunded to bidders.');
    } catch (error: any) {
      alert(`Error deleting session: ${error.message}`);
    }
  };

  if (loading || !user || !selectedGuild) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const isAdmin = user?.role === 'admin';
  const canCreateSession = user?.role === 'admin' || user?.role === 'officer';

  return (
    <AppTheme>
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          minHeight: '100vh',
          pt: 4,
        }}
      >
        <Grid2
          container
          spacing={3}
          sx={{
            backgroundColor: '#121212',
            color: '#E1E1E6',
            pt: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 3, md: 4 },
            mb: { xs: 2, sm: 4 },
            width: '100%',
            maxWidth: { xs: '100%', lg: 'lg' },
          }}
        >
          {/* Create Session Form */}
          {canCreateSession && (
            <Grid2 size={12}>
              <Card
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.08)',
                  borderRadius: 0,
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 3, color: 'white' }}>
                    Create New Session
                  </Typography>
                  <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <TextField
                          fullWidth
                          label="Session Name"
                          value={sessionName}
                          onChange={(e) => setSessionName(e.target.value)}
                          error={!!error}
                          helperText={error}
                          sx={{
                            input: { color: '#E1E1E6' },
                            label: { color: '#B2B2B8' },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'rgba(225, 225, 230, 0.3)',
                              },
                              '&:hover fieldset': {
                                borderColor: 'rgba(225, 225, 230, 0.5)',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#E1E1E6',
                              },
                            },
                          }}
                        />
                        <TutorialPopover
                          title="Session Name"
                          steps={[
                            {
                              title:
                                "Choose a name that identifies your loot session. Examples: 'Thursday Raid', 'MC Run 12/24', 'Guild Event'",
                            },
                          ]}
                        />
                      </Box>
                    </Grid2>
  
                    <Grid2 size={{ xs: 12, md: 4 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <FormControl fullWidth>
                          <InputLabel sx={{ color: '#B2B2B8' }}>Duration Type</InputLabel>
                          <Select
                            value={sessionDuration.unit}
                            label="Duration Type"
                            onChange={(e) =>
                              setSessionDuration({
                                ...sessionDuration,
                                unit: e.target.value as DurationConfig['unit'],
                              })
                            }
                            sx={{
                              color: '#E1E1E6',
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(225, 225, 230, 0.3)',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(225, 225, 230, 0.5)',
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#E1E1E6',
                              },
                              '.MuiSvgIcon-root': { color: '#E1E1E6' },
                            }}
                          >
                            <MenuItem value="n/a">No Expiration</MenuItem>
                            <MenuItem value="seconds">Seconds</MenuItem>
                            <MenuItem value="minutes">Minutes</MenuItem>
                            <MenuItem value="hours">Hours</MenuItem>
                            <MenuItem value="days">Days</MenuItem>
                          </Select>
                        </FormControl>
                        <TutorialPopover
                          title="Session Duration"
                          steps={[
                            {
                              title:
                                "Define how long your loot session will stay active. Select 'No Expiration' to keep it open indefinitely",
                            },
                          ]}
                        />
                      </Box>
                    </Grid2>
  
                    <Grid2 size={{ xs: 12, md: 2 }}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Duration Value"
                        disabled={sessionDuration.unit === 'n/a'}
                        value={sessionDuration.unit === 'n/a' ? '' : sessionDuration.value}
                        onChange={(e) =>
                          setSessionDuration({
                            ...sessionDuration,
                            value: Math.max(1, parseInt(e.target.value) || 1),
                          })
                        }
                        sx={{
                          input: { color: '#E1E1E6' },
                          label: { color: '#B2B2B8' },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'rgba(225, 225, 230, 0.3)',
                            },
                            '&:hover fieldset': {
                              borderColor: 'rgba(225, 225, 230, 0.5)',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#E1E1E6',
                            },
                          },
                        }}
                      />
                    </Grid2>
  
                    <Grid2 size={{ xs: 12, md: 6 }}>
                      <Box sx={{ display: 'flex', alignItems: 'start', gap: 1 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={antiSnipeEnabled}
                              onChange={(e) => setAntiSnipeEnabled(e.target.checked)}
                              sx={{
                                color: '#B2B2B8',
                                '&.Mui-checked': {
                                  color: '#E1E1E6',
                                },
                              }}
                            />
                          }
                          label={
                            <Box>
                              <Typography variant="body1" sx={{ color: '#E1E1E6' }}>
                                Enable Anti-Sniping Protection
                              </Typography>
                              <Typography variant="caption" sx={{ color: '#B2B2B8' }}>
                                Prevents last-second bidding by extending session time when
                                bids are placed near expiration
                              </Typography>
                            </Box>
                          }
                        />
                        <TutorialPopover
                          title="Anti-Sniping Protection"
                          steps={[
                            {
                              title:
                                'Prevents last-second bidding tactics by automatically extending the session when bids are placed close to expiration',
                            },
                            {
                              title:
                                'Example: A bid placed with 2 minutes remaining will extend the session by your set time window',
                            },
                          ]}
                        />
                      </Box>
                    </Grid2>
  
                    <Grid2 size={{ xs: 12, md: 6 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <TextField
                          fullWidth
                          type="number"
                          label="Anti-Snipe Window (minutes)"
                          disabled={!antiSnipeEnabled}
                          value={antiSnipeTime}
                          onChange={(e) =>
                            setAntiSnipeTime(
                              Math.max(1, Math.min(1000, parseInt(e.target.value) || 5))
                            )
                          }
                          inputProps={{ min: 1, max: 1000 }}
                          helperText={
                            antiSnipeEnabled
                              ? `If someone bids when less than ${antiSnipeTime} minutes remain, the session will automatically extend by ${antiSnipeTime} minutes (max 60 minutes)`
                              : 'Enable anti-sniping protection to set the time window'
                          }
                          sx={{
                            input: { color: '#E1E1E6' },
                            label: { color: '#B2B2B8' },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'rgba(225, 225, 230, 0.3)',
                              },
                              '&:hover fieldset': {
                                borderColor: 'rgba(225, 225, 230, 0.5)',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#E1E1E6',
                              },
                            },
                          }}
                        />
                        <TutorialPopover
                          title="Anti-Snipe Time Window"
                          steps={[
                            {
                              title:
                                'Set how many minutes before expiration the anti-snipe protection activates. When a bid is placed within this window, the session extends by the same duration',
                            },
                          ]}
                        />
                      </Box>
                    </Grid2>
  
                    <Grid2 size={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Button
                          variant="outlined"
                          onClick={() => setShowEventLink(true)}
                          fullWidth
                          startIcon={<PersonAddIcon />}
                          sx={{
                            color: '#FFA500',
                            borderColor: '#FFA500',
                            '&:hover': {
                              borderColor: '#FFA500',
                              backgroundColor: 'rgba(255, 165, 0, 0.1)',
                            },
                          }}
                        >
                          {selectedEventId ? 'Change Event' : 'Link to Event'}
                        </Button>
                        <TutorialPopover
                          title="Event Linking"
                          steps={[
                            {
                              title:
                                'Associate this loot session with a specific event to track loot distribution for particular raids or activities',
                            },
                          ]}
                        />
                      </Box>
                    </Grid2>
  
                    <Grid2 size={12}>
                      <Button
                        variant="contained"
                        onClick={() => setShowCreateModal(true)}
                        fullWidth
                        sx={{
                          mt: 2,
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          color: '#E1E1E6',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                          },
                        }}
                      >
                        Create Session
                      </Button>
                    </Grid2>
                  </Grid2>
                </CardContent>
              </Card>
            </Grid2>
          )}
  
          {/* Sessions List */}
          <Grid2 size={12}>
            {isLoadingSessions ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '200px',
                }}
              >
                <CircularProgress />
              </Box>
            ) : sessions.length === 0 ? (
              <Paper
                sx={{
                  p: 3,
                  textAlign: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  color: '#E1E1E6',
                }}
              >
                <Typography color="#E1E1E6">
                  No sessions available for your guild.
                </Typography>
              </Paper>
            ) : (
              <Grid2 container spacing={2}>
                {sessions.map((session) => {
                  const expiresAt =
                    session.expiresAt instanceof Timestamp
                      ? session.expiresAt.toDate()
                      : session.expiresAt;
                  const isExpired = expiresAt ? new Date() > expiresAt : false;
                  const hasUndistributedItems = session.remainingItems > 0;
                  const needsDistribution = isExpired && hasUndistributedItems;
  
                  return (
                    <Grid2 size={12} key={session.id}>
                      <Card
                        sx={{
                          position: 'relative',
                          backgroundColor: 'rgba(255, 255, 255, 0.05)',
                          border: (() => {
                            if (needsDistribution) return '2px solid #ff9800';
                            if (
                              session.items.length > 0 &&
                              session.items.every((item) => item.status === 'distributed')
                            ) {
                              return '2px solid #4caf50';
                            }
                            return '1px solid rgba(255, 255, 255, 0.1)';
                          })(),
                          borderRadius: 0,
                        }}
                      >
                        {needsDistribution && (
                          <Box
                            sx={{
                              backgroundColor: '#ff9800',
                              color: 'white',
                              padding: '4px 8px',
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              borderBottomLeftRadius: '4px',
                              zIndex: 1,
                            }}
                          >
                            {(() => {
                              const itemsWithoutParticipants = session.items.filter(
                                (item) =>
                                  item.status === 'open' &&
                                  !item.currentBidder &&
                                  !item.highestRoller
                              ).length;
                              const itemsWithParticipants =
                                session.remainingItems - itemsWithoutParticipants;
                              return itemsWithoutParticipants > 0
                                ? `${itemsWithParticipants} Items Ready for Distribution | ${itemsWithoutParticipants} Items Without Participants`
                                : `${session.remainingItems} Items Ready for Distribution`;
                            })()}
                          </Box>
                        )}
                        {session.items.length > 0 &&
                          session.items.every((item) => item.status === 'distributed') && (
                            <Box
                              sx={{
                                backgroundColor: '#4caf50',
                                color: 'white',
                                padding: '4px 8px',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                borderBottomLeftRadius: '4px',
                                zIndex: 1,
                              }}
                            >
                              All items distributed - Safe to delete
                            </Box>
                          )}
                        <CardContent>
                          <Grid2 container spacing={2}>
                            <Grid2 size={12}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ color: '#E1E1E6' }}
                              >
                                {session.name}
                              </Typography>
                            </Grid2>
  
                            <Grid2 size={{ xs: 12, md: 6 }}>
                              <Box sx={{ mb: 2 }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ color: '#B2B2B8' }}
                                  gutterBottom
                                >
                                  Session Status
                                </Typography>
                                <SessionTimer
                                  expiresAt={
                                    session.expiresAt instanceof Timestamp
                                      ? session.expiresAt
                                      : session.expiresAt
                                      ? Timestamp.fromDate(session.expiresAt)
                                      : null
                                  }
                                />
                              </Box>
                              {session.linkedEventTitle && (
                                <Typography variant="body2" sx={{ color: '#B2B2B8' }}>
                                  Linked Event: {session.linkedEventTitle}
                                </Typography>
                              )}
                            </Grid2>
  
                            <Grid2 size={{ xs: 12, md: 6 }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ color: '#B2B2B8' }}
                                gutterBottom
                              >
                                Items ({session.items.length})
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 1,
                                  flexWrap: 'wrap',
                                  alignItems: 'center',
                                }}
                              >
                                {session.items.map((item, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      opacity: item.status === 'distributed' ? 0.5 : 1,
                                      transition: 'opacity 0.2s',
                                      position: 'relative',
                                    }}
                                  >
                                    <img
                                      src={
                                        itemIconMap[item.itemName] ||
                                        '/images/legendarysword.png'
                                      }
                                      alt={item.itemName}
                                      style={{ width: 40, height: 40 }}
                                    />
                                    {item.status === 'open' &&
                                      !item.currentBidder &&
                                      !item.highestRoller && (
                                        <Box
                                          sx={{
                                            position: 'absolute',
                                            top: -8,
                                            right: -8,
                                            width: 16,
                                            height: 16,
                                            borderRadius: '50%',
                                            backgroundColor: 'error.main',
                                            border: '2px solid white',
                                          }}
                                        />
                                      )}
                                    {item.status === 'distributed' && (
                                      <Box
                                        sx={{
                                          position: 'absolute',
                                          top: -8,
                                          right: -8,
                                          width: 16,
                                          height: 16,
                                          borderRadius: '50%',
                                          backgroundColor: 'success.main',
                                          border: '2px solid white',
                                        }}
                                      />
                                    )}
                                  </Box>
                                ))}
                              </Box>
                            </Grid2>
  
                            <Grid2 size={12}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  gap: 1,
                                  mt: 2,
                                }}
                              >
                                <Button
                                  variant="contained"
                                  startIcon={<VisibilityIcon />}
                                  onClick={() => navigate(`/session/${session.id}`)}
                                  sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    color: '#E1E1E6',
                                    '&:hover': {
                                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                    },
                                  }}
                                >
                                  View Session
                                </Button>
                                {(isAdmin || session.createdBy === user?.uid) && (
                                  <IconButton
                                    color="error"
                                    onClick={() => handleDeleteSession(session.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </Box>
                            </Grid2>
                          </Grid2>
                        </CardContent>
                      </Card>
                    </Grid2>
                  );
                })}
              </Grid2>
            )}
          </Grid2>
        </Grid2>
  
        {/* Event Session Link */}
        <EventSessionLink
          open={showEventLink}
          onClose={() => setShowEventLink(false)}
          onSelectEvent={(eventId: string) => {
            setSelectedEventId(eventId);
            setShowEventLink(false);
          }}
          events={events}
        />
  
        {/* Create Session Modal */}
        <CreateSessionModal
          open={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          onProceed={handleModalConfirm}
          selectedGuild={selectedGuild}
        />
      </Container>
    </AppTheme>
  );
  
};

export default SessionList;
