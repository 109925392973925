// src/components/teams/TeamDetails.tsx

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Typography, Grid, Card, CardContent, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import useUser from '../../hooks/useUser';
import UserManagementTab from './UserManagementTab';

interface TeamAttendanceDashboardProps {
    teamId: string;
}

interface Team {
    name: string;
    members: string[];
    leader: string;
    secondLeader?: string;
}

interface GuildMember {
    uid: string;
    username: string;
    inGameName: string;
    dkp: number;
    role: string;
    gearScore: number;
    primaryWeapon: string;
    secondaryWeapon: string;
    classType: 'Heal' | 'Tank' | 'DPS';
}

const TeamDetails: React.FC = () => {
    const { teamName } = useParams<{ teamName: string }>();
    const { selectedGuild } = useUser();
    const [team, setTeam] = useState<Team | null>(null);
    const [members, setMembers] = useState<GuildMember[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTeamDetails = async () => {
            if (!teamName || !selectedGuild) return;
            try {
                const teamDocRef = doc(db, 'teams', selectedGuild, 'guildTeams', teamName);
                const teamDoc = await getDoc(teamDocRef);
                if (teamDoc.exists()) {
                    const teamData = teamDoc.data() as Team;
                    console.log('Team Data:', teamData); // Add this line
                    setTeam(teamData);
                } else {
                    console.error('No such team!');
                }
            } catch (error) {
                console.error('Error fetching team details:', error);
            }
        };


        const fetchTeamMembers = async () => {
            if (!teamName || !selectedGuild) return;
            try {
                const teamDocRef = doc(db, 'teams', selectedGuild, 'guildTeams', teamName);
                const teamDoc = await getDoc(teamDocRef);
                if (teamDoc.exists()) {
                    const teamData = teamDoc.data() as Team;
                    console.log('Fetched team data:', teamData); // Debugging log

                    // Fetch all user documents to map names to UIDs
                    const userGuildsRef = collection(db, 'userGuilds');
                    const userQuery = query(userGuildsRef, where('guildId', '==', selectedGuild));
                    const userDocs = await getDocs(userQuery);

                    // Create a map of in-game names to UIDs
                    const nameToUIDMap: Record<string, string> = {};
                    userDocs.forEach(doc => {
                        const data = doc.data();
                        if (data.inGameName) {
                            nameToUIDMap[data.inGameName] = data.uid;
                        }
                    });

                    // Use the map to fetch member data by UID
                    // Add this console log in fetchTeamMembers to verify the data
                    console.log('Team members from Firestore:', teamData.members);

                    // Modify the query to get member details
                    const memberPromises = teamData.members.map(async memberName => {
                        const userGuildsRef = collection(db, 'userGuilds');
                        const memberQuery = query(
                            userGuildsRef,
                            where('inGameName', '==', memberName),
                            where('guildId', '==', selectedGuild)
                        );

                        const memberDocs = await getDocs(memberQuery);
                        if (!memberDocs.empty) {
                            const memberData = memberDocs.docs[0].data();
                            return {
                                ...memberData,
                                inGameName: memberName
                            } as GuildMember;
                        }
                        return null;
                    });


                    const membersData = await Promise.all(memberPromises);
                    setMembers(membersData.filter(member => member !== null) as GuildMember[]);
                }
            } catch (error) {
                console.error('Error fetching team members:', error);
            } finally {
                setLoading(false);
            }
        };


        fetchTeamDetails();
        fetchTeamMembers();
    }, [teamName, selectedGuild]);

    if (loading) {
        return <CircularProgress />;
    }

    if (!team) {
        return <Typography variant="h6">Team not found</Typography>;
    }

    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: 'background.default' }}>
            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Typography variant="h4" sx={{ fontWeight: 700, mb: 3 }}>
                    {team.name} Details
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Team Leaders
                                </Typography>
                                <Typography variant="body1">
                                    Leader: {team.leader}
                                </Typography>
                                {team.secondLeader && (
                                    <Typography variant="body1">
                                        Second Leader: {team.secondLeader}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Team Members Activity
                                </Typography>
                                <UserManagementTab />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );

};

export default TeamDetails;
