import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  {
    id: 'panel1',
    question: 'How do I get started with LootManager?',
    answer: 'Getting started is simple - sign in with Discord, create your guild, and invite your members. Our intuitive interface guides you through setting up your first raid session and DKP system in minutes.'
  },
  {
    id: 'panel2',
    question: 'What features are included in the Premium tier?',
    answer: 'Premium members get unlimited sessions, teams and events, full Discord bot integration for bidding and DKP management and automated event hooks. The Discord bot enables direct bidding through commands and automated notifications.'
  },
  {
    id: 'panel3',
    question: 'How does the bidding system work?',
    answer: 'Our advanced bidding system features real-time updates, wishlist verification, private bidding options, and anti-snipe protection. The sharded architecture ensures smooth performance even during peak raid times.'
  },
  {
    id: 'panel4',
    question: 'What DKP management tools are available?',
    answer: 'LootManager offers preset DKP rewards, decay systems, flexible point distribution, and detailed tracking. Admins can easily split or distribute DKP across raid members and export comprehensive logs for analysis.'
  },
  {
    id: 'panel5',
    question: 'How can I get support?',
    answer: 'Join our active Discord community for immediate assistance and feature discussions. Our development team actively implements user feedback and provides quick responses to enhance your experience.'
  }
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(
        isExpanded
          ? [...expanded, panel]
          : expanded.filter((item) => item !== panel),
      );
    };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        {faqs.map((faq) => (
          <Accordion
            key={faq.id}
            expanded={expanded.includes(faq.id)}
            onChange={handleChange(faq.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${faq.id}-content`}
              id={`${faq.id}-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
